import { Directive, ElementRef, OnInit, HostListener, Input, Inject, PLATFORM_ID } from '@angular/core';
import { AnimationPlayer, AnimationBuilder, animate, style } from '@angular/animations';

import { animations } from '../data';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Directive({
	selector: '[contentAnim]'
})

export class ContentAnimDirective implements OnInit {

	@Input() aniName = 'fadeIn';
	@Input() duration = '.8s';
	@Input() offset = 0;

	first = true;
	player: AnimationPlayer;

	constructor(private el: ElementRef, private animationBuilder: AnimationBuilder,@Inject(PLATFORM_ID) private platformId: Object) { }

	ngOnInit() {
		this.el.nativeElement.classList.add('appear-animate');
		this.createAnimation();

		setTimeout(() => {
			this.handleAnimation();

			let slider = this.el.nativeElement.closest('owl-carousel-o');
			let self = this;

			if (slider) {
				$(slider).on('translate.owl.carousel', () => {
					if (self.el.nativeElement.closest('.owl-item:not(.active)')) {
						self.el.nativeElement.classList.add('appear-animate');
					}
				});

				$(slider).on('translated.owl.carousel', () => {
					if (self.el.nativeElement.closest('.owl-item.active')) {
						if (self.el.nativeElement.classList.contains('appear-animate')) {
							self.el.nativeElement.classList.remove('appear-animate');
							this.player.play();
							this.first = false;
						}
					} else {
						self.el.nativeElement.classList.add('appear-animate');
					}
				})
			}
		}, 300);
	}

	@HostListener('window: scroll', ['$event'])
	onscroll() {
		this.handleAnimation();
	}

	handleAnimation() {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		let rect = this.el.nativeElement.getBoundingClientRect();

		if (((window.innerHeight > rect.top && rect.top > 0) || (rect.bottom > 0 && rect.bottom < window.innerHeight)) && this.first) {
			if (this.el.nativeElement.closest('owl-carousel-o') && !this.el.nativeElement.closest('.owl-item.active')) return;

			this.player.play();
			this.first = false;
		}
	}

	createAnimation() {
		this.player = this.animationBuilder
			.build([
				style(
					animations[this.aniName]['from']
				),
				animate(
					this.duration,
					style(
						animations[this.aniName]['to']
					)
				)
			])
			.create(this.el.nativeElement);

		let self = this;

		this.player.onStart(function () {
			self.el.nativeElement.classList.remove('appear-animate');
		});
	}
}