import { Component, HostListener, Inject } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { DOCUMENT, ViewportScroller } from "@angular/common";
import {filter,first,map,pluck,tap} from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { StoreService } from "./core/store/store.service";
import { UtilsService } from "./shared/services/utils.service";
import { LanguageService } from "./shared/services/language.service";
import { MetaService } from "./shared/services/meta.service";
import { referrerEvent } from "./shared/interfaces/referrer-event";
import { AuthService } from "./shared/services/auth.service";
import { Observable, Observer, fromEvent, merge } from "rxjs";
// import { ToastrService } from "ngx-toastr";
import { SwUpdate } from "@angular/service-worker";
import { SeoService } from "hash/seo.service";

declare var $: any;
declare global {
  interface Window {
    dataLayer: any[];
  }
}
//   declare let fbq: Function; // fbq function declaration
@Component({
  selector: "molla-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  myScript: HTMLScriptElement;
  lang = "en";
  newUpdate = false;
  checkinterent: any;
  navigationEnd = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    first()
  );
  // navigationStart = this.router.events.pipe(
  //   filter((event) => event instanceof this.navigationStart),
  //   first()
  // );
  constructor(
    public store: Store<any>,
    public router: Router,
    public viewPort: ViewportScroller,
    public storeService: StoreService,
    public utilsService: UtilsService,
    public modalService: NgbModal,
    private languageService: LanguageService,
    private metaService: MetaService,
    private activateRoute: ActivatedRoute,
    private auth: AuthService,
    // private toastr: ToastrService,
    private readonly swUpdate:SwUpdate,
    private seo: SeoService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.lang = this.languageService.setInitialAppLanguage();
    this.document.documentElement.lang = this.lang === "ar" ? "ar-SA" : "en-SA";
    document.body.dir = this.lang === "ar" ? "rtl" : "ltr";

    // create canonical tags 
    this.seo.createCanonical(this.lang);
    

    // this.navigationStart.subscribe((event:any) => {
    //   console.log(event.url);
    // })
    this.navigationEnd.subscribe((event: any) => {
      this.seo.createHreflang(event.url,this.lang);
      // Language prfix redirect
      if(event.url === '/'){
        // this.router.navigate([this.lang]);
      }
      if (
        !event.url.includes("/en/") 
        && !event.url.includes("/ar/") 
        && event.url != '/'
        && event.url != '/en'
        && event.url != '/ar'
      ) {
        // console.log(this.lang + event.url);
        // this.router.navigateByUrl(this.lang + event.url);
      }
      // http://localhost:4200/shop?qry=%D8%B7%D9%82%D9%85%20%D9%85%D9%81%D8%AA%D8%A7%D8%AD%20%D8%B1%D8%A8%D8%B7

      if (
        !event.url.includes("/shop/sidebar") &&
        !event.url.includes("/shop/nosidebar")
      ) {
        this.viewPort.scrollToPosition([0, 0]);
      }
      this.modalService.dismissAll();
    });
    
    if(this.swUpdate.available){
      this.swUpdate.available.subscribe( ()=> {
        this.newUpdate = true;
      });
    }
    
    // this.swUpdate.versionUpdates.subscribe(evt => {
    //   switch (evt.type) {
    //     case 'VERSION_DETECTED':
    //       console.log(`Downloading new app version: ${evt.version.hash}`);
    //       break;
    //     case 'VERSION_READY':
    //       console.log(`Current app version: ${evt.currentVersion.hash}`);
    //       console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //       break;
    //     case 'VERSION_INSTALLATION_FAILED':
    //       console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //       break;
    //   }
    // });


    // const updatesAvailable = swUpdate.versionUpdates.pipe(
    //   filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    //   map(evt => ({
    //     type: 'UPDATE_AVAILABLE',
    //     current: evt.currentVersion,
    //     available: evt.latestVersion,
    //   })));
      // console.log(updatesAvailable);


    // this.swUpdate.checkForUpdate().then(()=>{
    //   this.swUpdate.activateUpdate().then(() => {
    //     this.newUpdate = true;
    //   })
    // })
     
  }

  @HostListener("window: scroll", ["$event"])
  onWindowScroll(e: Event) {
    this.utilsService.setStickyHeader();
  }

  hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
    document.querySelector("html").style.overflowX = "unset";
  }
  ngAfterViewInit() {
    this.navigationEnd.subscribe((event: any) => {
      window.addEventListener("DOMContentLoaded", (event) => {
        this.respondIO();
      });
    });
  }
  respondIO() {
    this.myScript = document.createElement("script");
    this.myScript.id = 'respondio__widget';
    if (this.lang === "ar") {
      this.myScript.src = "https://cdn.respond.io/webchat/widget/widget.js?cId=1782af4c0f3f5df1e8a55dda7fa1814eb3fac321344aedbde68cef9904f73960";
    } else {
      this.myScript.src = "https://cdn.respond.io/webchat/widget/widget.js?cId=8816a4f96d630910b1240f4900dc520b3d5f41f361dfc8560510103d0233c46c";
    }
    document.head.appendChild(this.myScript);
  }

  trengo() {
    this.myScript = document.createElement("script");
    if (this.lang === "ar") {
      this.myScript.src = "assets/trengo_ar.js";
    } else {
      this.myScript.src = "assets/trengo_en.js";
    }
    document.head.appendChild(this.myScript);
  }
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
  reloadPage(){
    window.location.reload();
  }
  ngOnInit(): void {
    this.createOnline$().subscribe(isOnline => {
      if(!isOnline){
        this.checkinterent = false;
        // if(this.languageService.selected === "ar"){
        //   this.toastr.error("لا يوجد اتصال بالانترنت");
        // }else{
        //   this.toastr.error("No Internet Connection 1");
        // }
      }else{
        this.checkinterent = true;
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        pluck("urlAfterRedirects"),
        tap((data: string) => {
			if(data === '/'){
				data = '/'+this.lang;
			  }
			this.metaService.updateMeta(data);
		})
      )
      .subscribe();
    this.activateRoute.queryParams.subscribe((params) => {
      if (params.referrer || params.gclid || params.fbclid) {
        let ev: referrerEvent = {};
        ev.code = params.referrer;
        ev.event_name = "home";
        ev.event_id = 0;
        ev.link = this.router.url;
        if (params.referrer) {
          // Referral Code
          ev.type = "referrer";
        } else if (params.gclid) {
          // Google
          ev.type = "google";
        } else if (params.fbclid) {
          // Facebook
          ev.type = "facebook";
        }
        this.auth.addReferrerEvent(ev).subscribe((res) => {
          if (localStorage.referrers_events) {
            localStorage.removeItem("referrers_events");
          } // delete referrer if available
          localStorage.setItem("referrers_events", JSON.stringify(res));
        });
      }
    });
  }
}
