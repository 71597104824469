import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { nationaldayForm } from 'src/app/shared/interfaces/contact-us';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
	selector: 'pages-nationalday',
	templateUrl: './nationalday.component.html',
	styleUrls: ['./nationalday.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class NationaldayPageComponent implements OnInit {
	selected;
	loading;
	contactUs: nationaldayForm = new nationaldayForm();
	constructor(
		private lang: LanguageService,
		public root: RootService,
		private auth: AuthService,
		private alertService: AlertService
	) {}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.contactUs.dept = null;
	}
	SendMessage() {
		this.loading = true;
		this.auth.SendContactusNationalday(this.contactUs).subscribe((result: any) => {
			this.loading = false;
			if (result.data == true) {
				this.contactUs = {name:"",email:"",mobile:"",subject:"",message:"",dept:""};
				this.alertService.showSuccess(result.msg_en);
				return;
			} else {
				this.alertService.showError(result.msg_en);
				return;
			}
		}
		,(error)=>{
			this.loading = false;
			return;
		},()=>{
			this.loading = false;
			return;
		});
	}
}