import { Component, OnInit, Input } from "@angular/core";
import { ModalService } from "src/app/shared/services/modal.service";
import { CartService } from "src/app/shared/services/cart.service";
import { WishlistService } from "src/app/shared/services/wishlist.service";
import { CompareService } from "src/app/shared/services/compare.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { Product } from "src/app/shared/interfaces/product";
import { RootService } from "src/app/shared/services/root.service";
import { SessionService } from "src/app/shared/services/session.service";

@Component({
  selector: "product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCard implements OnInit {
  @Input() product: Product;
  maxPrice = 0;
  minPrice = 99999;

  selected: string;
  saved;
  company;
  addingToCart = false;
  company_can_see_gifts = false;

  constructor(
    private modalService: ModalService,
    private cart: CartService,
    private wishlistService: WishlistService,
    private compareService: CompareService,
    private lang: LanguageService,
    public root: RootService,
    private session: SessionService
  ) {}

  ngOnInit(): void {
    this.selected = this.lang.selected;
    let min = this.minPrice;
    let max = this.maxPrice;
    this.minPrice = min;
    this.maxPrice = max;
    this.product.svat = Number(this.product.svat);
    this.product.vat = Number(this.product.vat);
    this.saved = Math.round(
      ((this.product.price - this.product.sale) / this.product.price) * 100
    );
    this.company = this.session.getCompany();
    this.company_can_see_gifts = !this.company || (this.company && this.company.allow_slaps == 0) ? true: false;
  }

  addToCart(event: Event) {
    event.preventDefault();
    if ((event.currentTarget as HTMLElement).classList.contains("btn-disabled"))
      return;
    if (!this.addingToCart) {
      this.addingToCart = true;
      this.cart.getStock(this.product, 1).subscribe((res) => {
        this.product.stock = res.valueOf();
      });
      this.cart.add(this.product, 1, [], 0).subscribe({
        complete: () => {
          this.addingToCart = false;
          if (this.product.bundle) {
            this.cart.add(this.product.bundle, 1).toPromise();
          }
        },
      });
    }
  }

  addToWishlist(event: Event) {
    event.preventDefault();

    if (this.isInWishlist()) {
      this.wishlistService.removeFromWishList(this.product);
    } else {
      this.wishlistService.addToWishList(this.product);
    }
  }

  addToCompare(event: Event) {
    event.preventDefault();
    if (this.isInCompare()) return;
    this.compareService.addToCompare(this.product);
  }

  quickView(event: Event) {
    event.preventDefault();
    this.modalService.showQuickView(this.product);
  }

  isInCompare() {
    return this.compareService.isInCompare(this.product);
  }

  isInWishlist() {
    return this.wishlistService.isInWishlist(this.product);
  }
}
