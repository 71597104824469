import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { SessionService } from 'src/app/shared/services/session.service';
export class ReturnItems{
	qty:number;
	oldQty:number;
	product:number;
	orderItemId:number
}
export class Return{
	items:ReturnItems[];
	reason:string;
	orderId:number;
}
@Component({
	selector: 'pages-return',
	templateUrl: './return.component.html',
	styleUrls: ['./return.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnPageComponent implements OnInit {
	shippingCost = 0;
	selected;
	loaded = false;
	user;
	order;
	items: string[] = [];
	selectedItems: { [key: string]: boolean } = {};
	reasonValue: string = '';
	
	returnData : Return = new Return();
	constructor(
		private lang: LanguageService,
		private session: SessionService,
		public root: RootService,
		private route: ActivatedRoute,
		private auth: AuthService,
		private cdr: ChangeDetectorRef,
		private alertService:AlertService
	) {
	}
	
	ngOnInit() {
		this.selected = this.lang.selected;
		this.user = this.session.getUser();
		this.route.params.subscribe(data => {
			this.auth.getOrderDetails({ 'id': data.orderId }).subscribe(res => {
				this.loaded = true;
				this.order = res;
				this.initReturnData(res);
				console.log(res);
				this.cdr.detectChanges();
			});
		});
	}

	initReturnData(order){
		// Extract items
		const extractedItems: ReturnItems[] = order.items.map(item => {
			return {
			qty: Number(item.qty),
			oldQty: Number(item.qty),
			product: Number(item.product),
			orderItemId: Number(item.id)
			};
		});
		// Create Return object
		this.returnData = {
			items: extractedItems,
			reason: '',
			orderId: Number(order.id)
		};
	}

	onChangeQty(event: number, item: any, i: number) {
		document.querySelector('.btn-cart-update.disabled') && document.querySelector('.btn-cart-update.disabled').classList.remove('disabled');
		this.returnData.items[i].qty = Number(event);
	}
	onSubmit(){
		this.returnData.reason = this.reasonValue;
		this.auth.returnRequest(this.returnData).subscribe(res=>{
			if(res.data){
				this.alertService.showSuccess(res['msg_'+this.selected]);
			}
		});
	}
}