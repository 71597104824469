import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DirectionService } from 'src/app/shared/services/direction.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'products-block-multi',
  templateUrl: './products-block-multi.component.html',
  styleUrls: ['./products-block-multi.component.scss']
})
export class ProductsBlockMultiComponent implements OnInit {

  @Input() products = [];
  @Input() categories = [];
  @Input() header = '';
  @Input() loaded = false;
  sliderOption: OwlOptions = {
    items: 2.5,
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    smartSpeed: 400,
    autoplay: false,
    autoplayTimeout: 15000,
    nav: true,
    dots: false,
    margin: 20,
    loop: true,
    responsive: {
      0: {
        items: 2.5
      },
      480: {
        items: 3.5
      },
      768: {
        items: 4.5
      },
      992: {
        items: 5.5
      },
      1200: {
        items: 6.5
      }
    },
    rtl: this.direction.isRTL()
  };
  selected;
  selectedBlock: number;

  constructor(
    private lang: LanguageService,
    private auth: AuthService,
    private direction: DirectionService
  ) { }

  ngOnInit(): void {
    this.selected = this.lang.selected;
    this.sliderOption.navText = this.selected === 'en' ? ['<i class="icon-angle-left">', '<i class="icon-angle-right">'] : ['<i class="icon-angle-right">', '<i class="icon-angle-left">']; 
  }
  select(id) {
    this.loaded = false;
    this.auth.getProductsByBlockId({ 'id': id }).subscribe(res => {
      this.products = res;
      this.selectedBlock = id;
      this.loaded = true;
    })
  }

}
