<div class="main">
    <molla-page-header title="{{'privacyPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'privacyPolicy'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <p>
                This privacy policy provides the method of collecting your data in the manner used by Arab Pro for Industrial Equipment. We recommend that you read the privacy policy carefully. By accessing the services provided by Arab Pro.com, you agree to the collection and usage of your data via Arab Pro.com and other, third-party, service providers in the manner stipulated in this Privacy Policy. If you do not agree to this privacy policy, please do not use Arab Pro.com
                <br><br>
                By accepting the privacy policy during registration, you must explicitly consent to the use and disclosure of your personal information in accordance with this privacy policy. It is included in this Privacy Policy is subject to the terms of the User Agreement.
                <br><br>
                Arab Pro for Industrial Equipment promises not to sell or rent your personal information to any third party (except as stipulated in this Privacy Policy) without your consent. We and/ or authorized third-party service providers are authorized to use the personal information you provided to provide you with our services.
            </p>

            <h6>Third-party Service Providers</h6>
            <p>
                We employ other companies and individuals to perform tasks on our behalf. Ex. Handling requests, packages delivery, sending regular mails/e-mails, data analysis, assistance and marketing, providing search results, and processing credit card payments. These third-parties have access to the personal information necessary to perform their tasks, but such information may not be used for other purposes.
            </p>                

            <h6>Information that may be collected from you</h6>
            <p>
                Arab Pro for Industrial Equipment collects the information you provided while registering in addition to information we have known about you by visiting our website and using our services. We may collect additional information regarding your participation in any offers or competitions that we provide. The information provided by you enables us to complete your profile. We use traffic information and your usage data to improve our service. We will only collect necessary and relevant information to provide you with the best services available on the website.
            </p>                

            <h6>We may collect the following personal information about you:</h6>
            <ol>
                <li>
                    Name including first name and family name
                </li>
                <li>
                    Mobile phone number and contact details
                </li>
                <li>
                    Demographic profile date (age, address)
                </li>
            </ol>

            <p>
                You can cancel your account at any time. However, your stored information our archive may remain even after deletion or cancelation of your account.
                <br><br>
We do not keep any credit card details in our database or server.
<br><br>
Usage of your information.
<br><br>
We use your personal and contact information to:
            </p>    
            
            <ol>
                <li>
                    Communicate with you whenever necessary
                </li>
                <li>
                    Provide the requests/ services required by you
                </li>
                <li>
                    Contact you to conduct a survey or referendum
                </li>
                <li>
                    Notify you if you win any competition
                </li>
                <li>
                    Send competitions or Ads from our sponsors
                </li>
            </ol>

            <p>
                When should your information be disclosed?
                <br><br>
We will not use your personal information for any other purpose than to complete the transaction with you. We do not rent or sell your personal information, and we will not disclose any personal information to any third party except:
            </p>    
            
            <ol>
                <li>
                    If we have your consent

                </li>
                <li>
                    To provide the products and services you requested

                </li>
                <li>
                    To assist in the investigation and prevention of actions related to unlawful activities, suspicion of fraud, a threat to the safety or security of anyone, violations of conditions of use or to defend against legal claims

                </li>
                <li>
                    Special circumstances such as compliance with subpoenas, court orders, requests/ orders from legal authority or law enforcement agencies that require such disclosure

                </li>
            </ol>   
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <p>
                توفر سياسة الخصوصية طريقة جمع البيانات الخاصة بك حسب ما هو متبع من قبل شركة المحترفون العرب للتجهيزات الصناعية. يرجى قراءة سياسة الخصوصية بعناية. إن أستعمالك الموقع وللخدمات المتوفرة به او قبول سياسة الخصوصية أثناء التسجيل هو موافقه منك على جمع واستخدام بياناتك الخاصه بالطريقة الموضحه. ان سياسة الخصوصية متضمنه وجزء من اتفاقيه استخدام الموقع. إذا كنت لا توافق على سياسة الخصوصية هذه، يرجى عدم استخدام هذا الموقع aptools.sa

                <br><br>
                شركة المحترفون العرب للتجهيزات الصناعية تعد بعدم بيع أو تأجير معلوماتك الشخصية لأي طرف ثالث (باستثناء ما هو منصوص عليه في سياسة الخصوصية) من دون موافقتك. نحن و / أو المخولين مزودي الخدمة طرف ثالث المخولين لاستخدام المعلومات الشخصية التي قدمتها لنوفر لك الخدمات التي نقدمها.

            </p>

            <h6>الطرف الثالث – مزودو الخدمة</h6>
            <p>
                نحن نوظف شركات أخرى وأفراد لأداء المهام نيابة عنا. ومن الأمثلة على ذلك مباشرة الطلبات، تسليم الطرود، إرسال البريد العادي والبريد الإلكتروني، تحليل البيانات، تقديم المساعدة والتسويق، تقديم نتائج البحث، تجهيز مدفوعات بطاقات الائتمان. لديهم إمكانية الوصول إلى المعلومات الشخصية اللازمة لأداء وظائفهم، ولكن لا تستخدم لأغراض أخرى
            </p>                

            <h6>المعلومات التي يمكن جمعها منك</h6>
            <p>
                تقوم شركة المحترفون العرب للتجهيزات الصناعية بجمع المعلومات التي قدمت من خلالكم في سجل جنباً إلى جنب مع المعلومات التي عرفناها عنك من خلال زيارة موقعنا واستخدامك لخدماتنا. قد نقوم بجمع معلومات إضافية فيما يتعلق بمشاركتكم في أي عروض أو المسابقات التي نوفرها والمعلومات التي تقدمها تعطينا تغذية لاستكمال الملف الشخصي الخاص بك. نحن نوظف معلومات حركة المرور واستخدامكم للموقع، لتحسين الخدمة التي نقدمها. وسوف نقوم بجمع المعلومات الضرورية فقط وذات الصلة لنا لنقدم لك الخدمات المتاحة على الموقع.
            </p>                

            <h6>قد نقوم بجمع المعلومات الشخصية التالية عنك مثل:</h6>
            <ol>
                <li>
                    الاسم بما في ذلك الاسم الأول واسم العائلة

                </li>
                <li>
                    رقم الهاتف الجوال وتفاصيل الاتصال

                </li>
                <li>
                    الملف الشخصي الديموغرافي (مثل العمر، العنوان)

                </li>
            </ol>

            <p>
                يمكنك إنهاء حسابك في أي وقت. ومع ذلك قد تبقى معلوماتك المخزنة في الأرشيف على خدماتنا حتى بعد الحذف أو إنهاء حسابك
نحن لا نبقي أي تفاصيل لبطاقة الائتمان في قاعدة البيانات المتوفرة لدينا أو السيرفر

<br><br>
استخداماتنا لمعلوماتك
نحن نستخدم معلوماتك الشخصية ومعلومات الاتصال في:



            </p>    
            
            <ol>
                <li>
                    التواصل معكم كلما كان ذلك ضرورياً
                </li>
                <li>
                    تزويد الطلبات / الخدمات المطلوبة من قبلك
                </li>
                <li>
                    الاتصال بكم لعمل مسح أو استفتاء
                </li>
                <li>
                    لإعلامك إذا فزت بأي مسابقة
                </li>
                <li>
                    لإرسال إعلانات ترويجية من الرعاة المعلنين لدينا أو مسابقات
                </li>
            </ol>

            <p>
                متى يتم الإفصاح عن المعلومات الخاصة بك
<br><br>
                لن نستخدم معلوماتك الشخصية لأي غرض آخر سوى إتمام الصفقة معك. نحن لا نؤجر أو نبيع المعلومات الشخصية الخاصة بك، ولن نكشف عن أي معلومات شخصية إلى أي طرف ثالث إلا:


            </p>    
            
            <ol>
                <li>
                    إذا كان لدينا إذن منك.
                </li>
                <li>
                    لتوفير المنتجات والخدمات التي طلبتها.
                </li>
                <li>
                    للمساعدة في تحقيق ومنع اتخاذ إجراءات تتعلق بأنشطة غير مشروعة وغير قانونية، والمشتبه بهم بالاحتيال، خطراً على سلامة أو أمن أي شخص، انتهاكات شروط استخدام أو للدفاع ضد المطالبات القانونية.
                </li>
                <li>
                    ظروف خاصة مثل الامتثال لأوامر الاستدعاء، أوامر المحكمة، طلبات / أمر من السلطة القانونية أو وكالات إنفاذ القانون التي تتطلب مثل هذا الكشف.
                </li>
            </ol>
        </div>
        <div class="mb-2"></div>
    </div>
</div>