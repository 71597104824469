import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { IndexComponent } from './pages/home/index/index.component';
import { AboutPageComponent } from './pages/about/about.component';
import { ContactPageComponent } from './pages/contact/contact.component';
import { FaqsPageComponent } from './pages/faqs/faqs.component';
import { PaymentMethodsPageComponent } from './pages/payment-methods/payment-methods.component';
import { PricingPolicyPageComponent } from './pages/pricing-policy/pricing-policy.component';
import { PrivacyPageComponent } from './pages/privacy/privacy.component';
import { ReturnPolicyPageComponent } from './pages/return-policy/return-policy.component';
import { TermsPageComponent } from './pages/terms/terms.component';
import { ShippingPolicyPageComponent } from './pages/shipping-policy/shipping-policy.component';
import { TranslateModule } from '@ngx-translate/core';
import { WarrantyFormComponent } from './pages/warranty-form/warranty-form.component';
import { SubscribeFormComponent } from './pages/subscribe-form/subscribe-form.component';
import { ComplaintsFormComponent } from './pages/complaints-form/complaints-form.component';
import { TestPageComponent } from './pages/test/test.component';
import { BrandComponent } from './pages/shop/brand/brand.component';
import { BunchComponent } from './pages/shop/bunch/bunch.component';
import { CategoryComponent } from './pages/shop/category/category.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { LoginPageComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/404/404.component';
import { LoyalityPolicyPageComponent } from './pages/loyality-policy/loyality-policy.component';
import { WarrantyPlusTermsPageComponent } from './pages/warranty-plus-terms/warranty-plus-terms.component';
import { NationaldayPageComponent } from './pages/nationalday/nationalday.component';
import { StoresPageComponent } from './pages/stores/stores.component';
import { RetailPageComponent } from './pages/about/retail/retail.component';
import { OnlinePageComponent } from './pages/about/online/online.component';
import { DealersPageComponent } from './pages/about/dealers/dealers.component';
import { ProjectsPageComponent } from './pages/about/projects/projects.component';
import { FollowAndWinPageComponent } from './pages/follow-and-win/follow-and-win.component';
import { ContractsComponent } from './pages/shop/contracts/contracts.component';
import { ReturnPageComponent } from './pages/return/return.component';

const children: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: IndexComponent
	},
	{
		path: 'product',
		loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule)
	},
	{
		path: 'account',
		loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
	},
	{
		path: 'shop',
		loadChildren: () => import('./pages/shop/shop.module').then(m => m.ShopModule)
	},
	{
		path: 'brand/:slug',
		component: BrandComponent
	},
	{
		path: 'contracts/:token',
		component: ContractsComponent
	},
	{
		path: 'bunch/:slug',
		component: BunchComponent
	},
	{
		path: 'category/:slug',
		component: CategoryComponent
	},
	{
		path: 'about-us',
		component: AboutPageComponent
	},
	{
		path: 'retail',
		component: RetailPageComponent
	},
	{
		path: 'dealers',
		component: DealersPageComponent
	},
	{
		path: 'online',
		component: OnlinePageComponent
	},
	{
		path: 'projects',
		component: ProjectsPageComponent
	},
	{
		path: 'contact-us',
		component: ContactPageComponent
	},
	{
		path: 'return/:orderId',
		component: ReturnPageComponent
	},
	{
		path: 'stores-location',
		component: StoresPageComponent
	},
	{
		path: 'faqs',
		component: FaqsPageComponent
	},
	{
		path: 'payment-methods',
		component: PaymentMethodsPageComponent
	},
	{
		path: 'pricing-policy',
		component: PricingPolicyPageComponent
	},
	{
		path: 'privacy-policy',
		component: PrivacyPageComponent
	},
	{
		path: 'return-policy',
		component: ReturnPolicyPageComponent
	},
	{
		path: 'loyalty-program',
		component: LoyalityPolicyPageComponent
	},
	{
		path: 'shipping-policy',
		component: ShippingPolicyPageComponent
	},
	{
		path: 'terms',
		component: TermsPageComponent
	},
	{
		path: 'warranty-plus-terms',
		component: WarrantyPlusTermsPageComponent
	},
	{
		path: 'subscribe-form',
		component: SubscribeFormComponent
	},
	{
		path: 'complaint-form',
		component: ComplaintsFormComponent
	},
	{
		path: 'test',
		component: TestPageComponent
	},
	{
		path: 'categories',
		component: CategoriesComponent
	},
	{
		path: 'login',
		component: LoginPageComponent
	},{
		path: 'warranty-form',
		component: WarrantyFormComponent
	},{
		path: '404',
		component: NotFoundComponent
	},{
		path: 'national-day',
		component: NationaldayPageComponent
	},{
		path: 'follow-and-win',
		component: FollowAndWinPageComponent
	}
];
const routes: Routes = [
	{
		path: '',
		// redirectTo: '/ar',
		// pathMatch: 'prefix'
		component: LayoutComponent,
		children: children
	},
	{
		path: ':lang',
		component: LayoutComponent,
		children: children
	}, 
	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'disabled', scrollPositionRestoration: 'disabled', initialNavigation: 'enabledBlocking' }), TranslateModule],
	exports: [RouterModule]
})

export class AppRoutingModule { }