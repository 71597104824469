import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { RootService } from "src/app/shared/services/root.service";

@Component({
  selector: "molla-category-menu",
  templateUrl: "./category-menu.component.html",
  styleUrls: ["./category-menu.component.scss"],
})
export class CategoryMenuComponent implements OnInit {
  items = [];
  selected: string;
  constructor(
    private lang: LanguageService,
    private auth: AuthService,
    public root: RootService
  ) {}

  ngOnInit(): void {
    this.selected = this.lang.selected;
    this.auth.getCategories(0).subscribe((res) => {
      this.items = res;
    });
  }
}
