<div class="product product-list">
    <div class="row pr-2">
        <div class="col-lg-3 col-md-3 col-6">
            <figure class="product-media">
                <!-- <span class="product-label label-new" *ngIf="product.new">New</span>
                <span class="product-label label-sale" *ngIf="product.sale_price">Sale</span>
                <span class="product-label label-top" *ngIf="product.top">Top</span> -->
                <span class="product-label label-out" *ngIf="!product.stock || product.stock == 0">{{'outOfStock'|translate}}</span>
                <a [routerLink]="['/product/'+ product.slug]">
                    <molla-image [src]="product.img" alt="{{product.title_en}}" class="product-image"></molla-image>
                </a>
            </figure>
        </div>

        <div class="col-lg-6 col-md-6 order-last">
            <div class="product-body product-action-inner">

                <h3 class="product-title">
                    <a [routerLink]="['/product/'+ product.slug]">{{ product['brand_'+selected] }} {{ product['title_'+selected] }} {{product.sku}}</a>
                </h3>

                <div class="product-content">
                    <p>{{ product['short_'+selected] }}</p>
                </div>
                <div class="extra-tags">
                    <div class="express" *ngIf="product.express && product.express > 0 else elseExpress">{{'express'| translate}}</div>
                    <ng-template #elseExpress>
                        <br>
                    </ng-template>
                    <div class="coupon" *ngIf="product.coupon && product.coupon['shorter_'+selected] && company_can_see_gifts">
                        <div class="coupon-details">
                            {{product.coupon['shorter_'+selected]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 order-md-last order-lg-last">
            <div class="product-list-action">
                <div class="product-price" *ngIf="!product.stock || product.stock == 0 else elseBlock">
                    <span class="out-price">{{ product.vat | currencyFormat}}</span>
                </div>
                <ng-template #elseBlock>
                    <div class="product-price" *ngIf="!product.svat || product.svat >= product.vat">{{ product.vat |currencyFormat}}</div>
                    <div class="product-price" *ngIf="product.svat && product.svat < product.vat">
                        <span class="new-price">{{ product.svat | currencyFormat}}</span>&nbsp;
                        <span class="old-price">{{ product.vat | currencyFormat}}</span>
                        </div>
                </ng-template>
                <div class="product-action">
                    <a href="javascript:;" class="btn-product btn-quickview" title="Quick view"
                        (click)="quickView($event)"><span>{{'quickview'|translate}}</span></a>
                    <a href="javascript:;" class="btn-product btn-wishlist" [class.added-to-wishlist]="isInWishlist()"
                        (click)="addToWishlist($event)"><span>{{'Wishlist'|translate}}</span></a>
                </div>
                <ng-template *ngIf="product.stock && product.stock > 0">
                    <button class="btn-product btn-cart" (click)="addToCart($event)">
                        <span>{{'addToCart' | translate}}</span>
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>