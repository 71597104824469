<div class="dropdown category-dropdown h-100">
    <a href="javascript:;" class="dropdown-toggle" title="Browse Categories">{{'shopByCategory' | translate}} <i
            class="icon-angle-down"></i></a>

    <div class="dropdown-menu">
        <nav class="side-nav">
            <ul class="menu-vertical sf-arrows">
                <li *ngFor="let item of items">
                    <category-menu-item [item]="item"></category-menu-item>
                </li>
            </ul>
        </nav>
    </div>
</div>

<!-- <a class="sf-with-ul" [href]="root.category(item.slug)">{{item['title_'+selected]}}</a>
                        <ul class="menu-vertical sf-arrows">
                            <li *ngFor="let item of item.items">
                                <a [ngClass]="{'sf-with-ul': item.items?.length}"
                                 [href]="root.category(item.slug)">{{item['title_'+selected]}}</a>
                            </li>
                        </ul> -->