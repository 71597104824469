import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Params } from 'src/app/shared/interfaces/params';

import { AuthService } from 'src/app/shared/services/auth.service';
import { DirectionService } from 'src/app/shared/services/direction.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'category-page',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss'],
})

export class CategoryComponent implements OnInit {
	products = [];
	pageTitle = '';
	toggle = false;
	loaded = false;
	info_loaded = false;
	firstLoad = false;
	params: Params = new Params();
	total: number;
	limit: number;
	type = '4cols';
	filters = [];
	category = [];
	subCategories = [];
	selected;

	categoriesSlider: OwlOptions = {
		items: 10,
		navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
		smartSpeed: 400,
		autoplayTimeout: 15000,
		nav: false,
		dots: false,
		margin: 20,
		autoplay: false,
		loop: true,
		responsive: {
			0: {
				items: 3.5,
			},
			576: {
				items: 4.5
			},
			768: {
				items: 5.5
			},
			992: {
				items: 6.5
			},
			1200: {
				items: 10,
				loop: false
			}
		},
		rtl: this.direction.isRTL()
	};

	constructor(
		public activeRoute: ActivatedRoute,
		public router: Router,
		public utilsService: UtilsService,
		public auth: AuthService,
		private meta: Meta,
        private title: Title,
		private lang: LanguageService,
		private direction:DirectionService,
		public root:RootService
	) {
		this.selected = this.lang.selected;
		this.activeRoute.params.subscribe(p => {
			this.params.category = p.slug;
			this.auth.getCategoryBySlug({ 'slug': p.slug }).subscribe(res => {
				this.info_loaded = true;
				this.subCategories = res.subCategories;
				this.category = res;
				this.pageTitle = res['category']['title_' + this.selected];
				const seoTitle = res['seo']['title_' + this.selected];
				const seoDesc = res['seo']['desc_' + this.selected];
				this.title.setTitle(seoTitle);
				this.meta.updateTag({ name: 'description', content: seoDesc });
				this.meta.updateTag({ name: 'og:title', content: seoTitle});
				this.meta.updateTag({ name: 'og:description', content: seoDesc });
				this.meta.updateTag({ name: 'twitter:title', content: seoTitle});
				this.meta.updateTag({ name: 'twitter:description', content: seoDesc });
				this.meta.updateTag({ name: 'og:url', content: environment.domain + 'category/' +res.category.slug });
				if(res.category.img){
					this.meta.updateTag({ name: 'og:image', content: 'https://admin.aptools.sa/uploads/images/categories/' +res.category.img });
					this.meta.updateTag({ name: 'twitter:image', content: 'https://admin.aptools.sa/uploads/images/categories/' +res.category.img });
				}
			});
			this.activeRoute.queryParams.subscribe(parameters => {
				if (parameters['qry']) {
					this.params.qry = parameters['qry'];
				} else {
					this.params.qry = '';
				}
				if (parameters['sort']) {
					this.params.sort = parameters['sort'];
				} else {
					this.params.sort = 'views';
				}
				if (parameters['type']) {
					this.type = parameters['type'];
				}
				if (parameters['category']) {
					this.params.slug = parameters['category'];
				}
				if (parameters['brand']) {
					this.params.filter_brand = parameters['brand'];
				}else{
					this.params.filter_brand = null;
				}
				if (parameters['filter_price']) {
					this.params.filter_price = parameters['filter_price'];
				} else {
					this.params.filter_price = null;
				}
				if (parameters['page']) {
					this.params.page = parameters['page'];
				}
				if (parameters['stock']) {
					this.params.filter_stock = parameters['stock'];
				}
				this.loaded = false;
				this.auth.getCategory(this.params).subscribe(result => {
					this.products = result.items;
					this.limit = result.limit;
					this.total = result.total;
					this.filters = result.filters;
					this.loaded = true;
					if (!this.firstLoad) {
						this.firstLoad = true;
					}
					this.utilsService.scrollToPageContent();
				});
			})
		});
	}

	ngOnInit(): void {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}
	changeType(type) {
		this.type = type;
	}
	@HostListener('window: resize', ['$event'])
	onResize(event: Event) {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}

	changeOrderBy(event: any) {
		this.router.navigate([], { queryParams: { sort: event.currentTarget.value, page: 1 }, queryParamsHandling: 'merge' });
	}

	toggleSidebar() {
		if (document.querySelector('body').classList.contains('sidebar-filter-active'))
			document.querySelector('body').classList.remove('sidebar-filter-active');
		else
			document.querySelector('body').classList.add('sidebar-filter-active');
	}

	hideSidebar() {
		document.querySelector('body').classList.remove('sidebar-filter-active');
	}
}