<div class="main">
    <molla-page-header title="{{'goldWarrantyTerms'| translate}}"
        subtitle="{{'goldWarrantyTermsSub'| translate}}"></molla-page-header>
    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'goldWarrantyTerms'| translate}}</li>
            </ol>
        </div>
    </nav>
    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <p>
                They are additional warranty services paid by the customer for a specified period of time in addition to
                the basic warrantee that the customer obtains for his purchase, which are:
            </p>
            <ul>
                <li>
                    Free comprehensive repair of spare parts for the duration of the warranty plus
                </li>
                <li>Repair is carried out regardless of the cause of the malfunction</li>
                <li>It is possible to repair the same fault twice as a maximum</li>
                <li>The Tool should not be deliberately damaged, broken or burned</li>
                <li>To obtain the guarantee, the serial number on the invoice and the equipment must be present and
                    matched</li>
            </ul>
            <p>
                <b>Warranty is offered in two periods:</b>
            </p>
            <ul>
                <li>The first period is a full year from the date of the invoice at a cost of 15% of the value of the
                    equipment in the invoice at the time of purchase.</li>
                <li>The second period is two years from the date of the invoice at a cost of 25% of the value of the
                    equipment in the invoice at the time of purchase</li>
            </ul>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <p>هي خدمات ضمان اضافيه مدفوعه من العميل لمده محدده من الوقت اضافة عن ضمانة الأساسي الذي يحصل عليه العميل عن
                الشراء وهي: </p>
            <ul>
                <li>اصلاح مجاني شامل قطع الغيار طوال فترة الضمان بلس </li>
                <li> الإصلاح يتم بغض النظر عن سبب العطل </li>
                <li>يمكن الإصلاح لنفس العطل مرتين بحد اقصى </li>
                <li>يجب ان لا يتم تعمد اتلاف المعده او كسرها او حرقها </li>
                <li>للحصول على الضمان يجب وجود وتطابق الرقم المسلسل على الفاتورة والمعده </li>
            </ul>
            <p>
                <b>الضمان يقدم على فترتين </b>
            </p>
            <ul>
                <li>الفترة الأولى عام كامل من تاريخ الفاتورة بتكلفة 15% من قيمة المعده بالفاتورة وقت الشراء </li>
                <li> الفترة الثانية عامان من تاريخ الفاتورة بتكلفة 25% من قيمة المعده بالفاتورة وقت الشراء</li>
            </ul>
        </div>
    </div>