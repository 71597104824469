<div class="main">
    <nav class="breadcrumb-nav mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [href]="root.home()">{{'home' | translate}}</a>
          </li>
          <li class="breadcrumb-item active">{{'about'| translate}}</li>
        </ol>
      </div>
    </nav>
    <!-- <div class="container"> -->
    <img class="page-header-big" src="assets/images/about/arabpro_aboutus_desktop.webp">
    <!-- <div class="page-header page-header-big text-center desktop"
              style="background-image: url('assets/images/about/arabpro_aboutus_desktop.jpg')"></div><div class="page-header page-header-big text-center mobile" style="background-image: url('assets/images/about/arabpro_aboutus_mobile.jpg')"></div> -->
    <!-- </div> -->
    <div class="page-content pb-6 pt-6 bg-light-2">
      <div class="container">
        <div class="row" *ngIf="selected==='en'">
          <div class="col-lg-6">
            <h2 class="title">Our Vision</h2>
            <p>At aptools, our aim is to offer you Industrial Equipments that show you that we really care! Not only have we got the trendiest Industrial Equipments, but we can also guarantee that they are of the finest quality. </p>
          </div>
          <div class="col-lg-6">
            <h2 class="title">Our Mission</h2>
            <p>We aim to offer our customers a variety of the latest Indstrial Equipments. We’ve come a long way, so we know exactly which direction to take when supplying you with high quality yet budget friendly products. </p>
          </div>
        </div>
        <div class="row" *ngIf="selected==='ar'">
          <div class="col-lg-6">
            <h2 class="title">رؤيتنا</h2>
            <p>هدفنا في aptools هو أن نقدم لك المعدات الصناعية التي تظهر لك أننا نهتم حقًا! ليس لدينا فقط أحدث المعدات الصناعية ، ولكن يمكننا أيضًا أن نضمن أنها ذات جودة عالية.</p>
          </div>
          <div class="col-lg-6">
            <h2 class="title">مهمتنا</h2>
            <p>نحن نهدف إلى أن نقدم لعملائنا مجموعة متنوعة من أحدث المعدات الصناعية. لقد قطعنا شوطًا طويلاً ، لذا فنحن نعرف بالضبط الاتجاه الذي يجب أن نسلكه عند تزويدك بمنتجات عالية الجودة ولكن منخفضة التكلفة.</p>
          </div>
        </div>
      </div>
    </div>


    <div class="page-content pb-4 pt-4">
      <div class="container">
        <div class="row">
          
          <div class="col-lg-4 mobile">
            <img src="assets/images/about/Arab_Pro_Company_History.webp" alt="About" class="about-img-front" />
          </div>
          <div class="col-lg-8" *ngIf="selected==='en'" style="padding:4rem;">
            <h2 class="title">History of the Company</h2>
            <p class="mb-2">We offer all of this while providing excellent customer service and friendly support. We always keep an eye on the latest trends in Power Tools and put our customers’ wishes first. That is why we have satisfied customers all over the world, and are thrilled to be a part of the Industrial industry. </p>
          </div>
          <div class="col-lg-8" *ngIf="selected==='ar'" style="padding:4rem;">
            <h2 class="title">تاريخ الشركة</h2>
            <p class="mb-2">نحن نقدم كل هذا مع توفير خدمة عملاء ممتازة ودعم ودود. نحن نراقب دائمًا أحدث الاتجاهات في أدوات الطاقة ونضع رغبات عملائنا أولاً. لهذا السبب قمنا بإرضاء العملاء في جميع أنحاء العالم ، ويسعدنا أن نكون جزءًا من الصناعة الصناعية.</p>
          </div>
          <div class="col-lg-4 desktop">
            <img src="assets/images/about/Arab_Pro_Company_History.webp" alt="About" class="about-img-front" />
          </div>
        </div>
      </div>
    </div>


    <div class="page-content pb-3 bg-light-2 pt-5">
      <div class="container">
        <h2 class="title text-center mb-4">{{'salesDept'|translate}}</h2>
        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.retail()">
              <div class="member member-2 text-center">
                  <figure class="member-media">
                    <img src="assets/images/about/retail_m.webp" alt="{{'Retail Stores'|translate}}">
                    <figcaption class="member-overlay"></figcaption>
                  </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Retail Stores'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.dealers()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/wholesale_m.webp" alt="{{'Dealers'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Dealers'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.online()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/online_m.webp" alt="{{'Online Store'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Online Store'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-lg-3">
            <a [href]="root.projects()">
              <div class="member member-2 text-center">
                <figure class="member-media">
                  <img src="assets/images/about/projects_m.webp" alt="{{'Projects'|translate}}">
                  <figcaption class="member-overlay"></figcaption>
                </figure>
                <div class="member-content">
                  <h3 class="member-title">{{'Projects'|translate}}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>