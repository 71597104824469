import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { Order } from "../interfaces/order";
import { User } from "../interfaces/user";

@Injectable()
export class SessionService {
  constructor(private router: Router) {}
  loggedUser: User = new User();
  currentPage: string;
  sessionUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  getUser(): User {
    try {
      if (localStorage.currentUser) {
        return JSON.parse(localStorage.currentUser);
      } else {
        // navigate to login
      }
    } catch (error) {
      // this.router.navigateByUrl("/login")
    }
    return null;
  }
  getCompany(): any {
    try {
      if (localStorage.company) {
        return JSON.parse(localStorage.company);
      } else {
        // navigate to login
      }
    } catch (error) {
      // this.router.navigateByUrl("/login")
    }
    return null;
  }
  getOrders(): Order[] {
    try {
      if (localStorage.order) {
        return JSON.parse(localStorage.order);
      } else {
        // navigate to login
      }
    } catch (error) {
      // this.router.navigateByUrl("/login")
    }
    return null;
  }
  getFullName(): string {
    try {
      if (localStorage.currentFullName) {
        return localStorage.currentFullName;
      } else {
        // navigate to login
      }
    } catch (error) {
      // this.router.navigateByUrl("/login")
    }
    return null;
  }
  getToken(): string {
    try {
      if (localStorage.token) {
        return localStorage.token;
      } else {
        // navigate to login
      }
    } catch (error) {
      // this.router.navigateByUrl("/login")
    }
    return null;
  }
  getNeedReload(): boolean {
    try {
      return localStorage.needReload;
    } catch (e) {}
    return null;
  }
  logout() {
    // localStorage.clear();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("company");
    localStorage.removeItem("order");
    this.removeReloadAfterLogin();
  }
  private setCurrentFullName(value: string) {
    if (value) {
      localStorage.currentFullName = value;
    } else {
      localStorage.removeItem("currentFullName");
    }
  }
  setUser(value: User, token: string, orders: Order[], company: any) {
    if (value) {
      if (value.firstname && value.lastname) {
        this.setCurrentFullName(value.firstname + " " + value.lastname);
      }
      this.loggedUser = value;
      localStorage.currentUser = JSON.stringify(value);
      localStorage.justLogin = "1";
      this.setToken(token);
      localStorage.company = JSON.stringify(company);
      localStorage.order = JSON.stringify(orders);
      this.needReloadAfterLogin();
    }
  }
  updateUserData(value: User, token: string) {
    if (value) {
      if (value.firstname && value.lastname) {
        this.setCurrentFullName(value.firstname + " " + value.lastname);
      }
      this.loggedUser = value;
      localStorage.currentUser = JSON.stringify(value);
      this.setToken(token);
      this.needReloadAfterLogin();
    }
  }
  updateLoyality(value) {
    let user = this.getUser();
    user.loyality = value;
    localStorage.currentUser = JSON.stringify(user);
  }
  getLoyalityValue(): Observable<number> {
    if (localStorage.currentUser) {
      let user = JSON.parse(localStorage.currentUser);
      return user.loyality ?? 0;
    }
    return null;
  }
  sessionExpired() {
    this.logout();
    this.router.navigateByUrl("/account/login").then(() => {
      // location.reload();
    });
  }
  updateUser(value: User) {
    if (value) {
      if (value.firstname && value.lastname) {
        this.setCurrentFullName(value.firstname + " " + value.lastname);
      }
      this.loggedUser = value;
      localStorage.currentUser = JSON.stringify(value);
    }
  }
  setToken(token) {
    localStorage.token = token;
  }
  needReloadAfterLogin() {
    localStorage.needReload = true;
  }
  removeReloadAfterLogin() {
    localStorage.removeItem("needReload");
  }
  getUserRedirect(type = "page") {
    if (type === "page") {
      let url = localStorage.getItem("pageHistoryBeforeLogin") ?? "/";
      if (url === "/login" || url === "/en/login" || url === "/ar/login") {
        url = "/";
      }
      this.router.navigate([url]).then(() => {
        localStorage.removeItem("pageHistoryBeforeLogin");
        // location.reload();
      });
    } else if (type === "modal") {
      this.router.navigate(["./"]).then(() => {
        location.reload();
      });
    }
  }
}
