<div class="main">
    <molla-page-header title="{{'faqs'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'faqs'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <h3>Shipping Information</h3>
            <h6>What shipping methods are available?</h6>

            <p>
                Currently we have two shipping methods available:

            </p>
            <ul>
                <li>SMSA Express</li>
                <li>Standard Shipping</li>
            </ul>



            <h6>How Long Will it Take To Get My Package?</h6>

            <p>
                Our estimated delivery time are usually 3-5 business days.
                <br><br>In the event that the order is not received within 10 working days, you should contact customer
                service.
            </p>

            <h6>How Do I Track My Order?</h6>

            <p>
                You can track your order from your account dashboard or you can click on Track your order from the
                top-bar.
            </p>
            <hr>
            <h3>Orders Information</h3>
            <h6>How do i place an order</h6>

            <p>

                <b>STEP 1.</b>
                <br>
                Click on the product that you are interested in.
                <br><br>
                <li>“ADD TO CART” button on the right.</li>
                <li> Now you can continue shopping or check your shopping cart’s contents, by clicking on the cart icon
                    in the upper right-hand corner.</li>
                <li> When you click on the shopping cart’s icon, its content will show up on the right. If you want to
                    buy all of the items, click on the “Cart” button.</li>
                <li> You can also add the product you are interested in to the “Wish list” and purchase it at another
                    time (please note that you must be a registered user for the product to be permanently saved on the
                    list).</li>
                <li> To view the wish list, log in to aptools.sa by entering your username and password, then click on
                    “Your Account” button on the menu on the top. In the next step, select “wishlist”, “heart icon”and
                    then add the items to cart.</li>
                <br><br><b>STEP 2</b><br>

                In the shopping cart’s preview you can see a list of the products you have chosen, together with their
                quantity and price.
                <br><br>
                <li>If you want to remove a product from the shopping cart, click on the “x” on the right side of the
                    price. If you want to change the quantity, click on +/- button next to the quantity and wait for a
                    moment. In a short while, the shopping cart will be updated.</li>
                <li>If you have a promo code, click on the “PROMO CODE” button on the left and a box will pop up. Enter
                    your promo code in the box and click “APPLY”. If the code is correct, the price will be reduced. If
                    the price does not change, please check the promotion’s rules and conditions and make sure that it
                    applies to the items in your cart.</li>

                To continue, click “PROCEED TO CHECKOUT”.

                <br><br><b>STEP 3</b><br>

                If you are a registered customer, please sign in. If you are not, you can sign up by clicking on
                “Login/Register”. You can also place an order as a guest (without registration).

                <br><br><b>STEP 4</b><br>

                Enter your shipping details by filling in all mandatory fields and ensure that your data is correct.

                <br><br><b>STEP 5</b><br>

                Select your payment method.
                <br><br>
                After you choose your preferred payment method, agree to have your personal data processed and agree to
                the terms and conditions, please click on the “Place Order” button.
                <br>
                <br><b>STEP 6</b><br>

                Check your mailbox – you should receive an order confirmation email.

            </p>
            <hr>
            <h3>Contact Information</h3>
            <h6>How Should I to Contact if I Have Any Queries?</h6>

            <p>
                You can contact us by visiting our Contact us page, or you can send us a message through whatsapp
                <a class="desktop-init" href="https://web.whatsapp.com/send?phone=966552822719">+966552822719</a>
                <a class="mobile-init" href="whatsapp://send?phone=966552822719">+966552822719</a> 
                and other social platforms listed in our hompage.

            </p>

        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <h3>معلومات الشحن</h3>
            <h6>ما هي طرق الشحن المتوفرة؟</h6>

            <p>
                حاليا لدينا اثنين من طرق الشحن المتوفرة:
            </p>
            <ul>
                <li>SMSA Express</li>
                <li>Standard Shipping</li>
            </ul>



            <h6>كم من الوقت سيستغرق الحصول على الحزمة الخاصة بي؟</h6>

            <p>
                عادة ما يكون وقت التسليم المقدر لدينا من 3-5 أيام عمل.
                <br><br>في حالة عدم استلام الطلب خلال 10 أيام عمل ، يجب عليك الاتصال بخدمة العملاء.

            </p>

            <h6>كيف يمكنني تتبع طلبي؟</h6>

            <p>
                يمكنك تتبع طلبك من لوحة معلومات حسابك أو يمكنك النقر فوق تتبع طلبك from the top-bar.
            </p>
            <hr>
            <h3>معلومات الطلب</h3>
            <h6>كيف يمكنني تقديم طلب؟</h6>

            <p>

                <b>الخطوة 1.</b>
                <br>
                انقر فوق المنتج الذي تهتم به.
                <br><br>
                <li> انقر فوق الزر “إضافة إلى عربة التسوق” على اليمين.</li>
                <li>يمكنك الآن متابعة التسوق أو التحقق من محتويات عربة التسوق الخاصة بك ، من خلال النقر على رمز سلة
                    التسوق في الزاوية اليمنى العليا.</li>
                <li> عند النقر فوق رمز عربة التسوق ، سيظهر محتواها على اليمين. إذا كنت ترغب في شراء جميع العناصر ، انقر
                    فوق الزر “عربة التسوق”.</li>
                <li> يمكنك أيضًا إضافة المنتج الذي تهتم به إلى “قائمة الرغبات” وشرائه في وقت آخر (يرجى ملاحظة أنه يجب أن
                    تكون مستخدمًا مسجلاً حتى يتم حفظ المنتج بشكل دائم في القائمة).</li>
                <li> لعرض قائمة الرغبات ، قم بتسجيل الدخول إلى aptools.sa عن طريق إدخال اسم المستخدم وكلمة المرور
                    الخاصين بك ، ثم انقر فوق الزر “حسابك” في القائمة بالأعلى. في الخطوة التالية ، حدد “قائمة الرغبات” ،
                    “رمز القلب” ثم أضف العناصر إلى سلة التسوق.
                </li>
                <br><br><b>الخطوة 2</b><br>

                في معاينة عربة التسوق ، يمكنك مشاهدة قائمة بالمنتجات التي اخترتها ، مع كميتها وسعرها
                <br><br>
                <li>إذا كنت ترغب في إزالة منتج من عربة التسوق ، فانقر فوق “x” على الجانب الأيمن من السعر. إذا كنت ترغب
                    في تغيير الكمية ، فانقر فوق +/- بجوار الكمية وانتظر لحظة. بعد فترة وجيزة ، سيتم تحديث عربة التسوق.
                </li>
                <li>إذا كان لديك رمز ترويجي ، فانقر فوق الزر “PROMO CODE” على اليسار وسيظهر مربع. أدخل الرمز الترويجي في
                    المربع وانقر على “تطبيق”. إذا كان الرمز صحيحًا ، فسيتم تخفيض السعر. إذا لم يتغير السعر ، فيرجى
                    التحقق من قواعد وشروط العرض الترويجي والتأكد من أنها تنطبق على العناصر الموجودة في سلة التسوق الخاصة
                    بك.
                </li>

                للمتابعة ، انقر فوق “متابعة الدفع”.


                <br><br><b>الخطوة 3</b><br>

                ذا كنت عميلاً مسجلاً ، فالرجاء تسجيل الدخول. وإذا لم تكن كذلك ، يمكنك التسجيل بالنقر فوق “تسجيل الدخول /
                التسجيل” يمكنك أيضًا تقديم طلب كضيف (بدون تسجيل).


                <br><br><b>الخطوة 4</b><br>

                أدخل تفاصيل الشحن الخاصة بك عن طريق ملء جميع الحقول الإلزامية والتأكد من صحة بياناتك.


                <br><br><b>الخطوة 5</b><br>

                إختر طريقة الدفع.

                <br><br>
                بعد اختيار طريقة الدفع المفضلة لديك ، وافق على معالجة بياناتك الشخصية والموافقة على الشروط والأحكام ،
                يرجى النقر على زر “تقديم الطلب”.

                <br>
                <br><b>الخطوة 6</b><br>

                تحقق من صندوق البريد الخاص بك – يجب أن تتلقى رسالة بريد إلكتروني لتأكيد الطلب.


            </p>
            <h6>هل أحتاج إلى حساب لتقديم طلب؟</h6>
            <p>
                لا ، لست بحاجة إلى حساب لتقديم طلب. يُسمح للضيوف (المستخدمين غير المسجلين) بتقديم طلب.

            </p>
            <hr>
            <h3>معلومات التواصل</h3>
            <h6>كيف يمكنني الاتصال إذا كان لدي أي استفسارات؟</h6>

            <p>
                يمكنك الاتصال بنا عن طريق زيارة صفحة اتصل بنا, أو يمكنك إرسال رسالة إلينا عبر Whatsapp (+966552822719)
                وغيرها من المنصات الاجتماعية المدرجة في صفحتنا الرئيسية.


            </p>
        </div>
        <div class="mb-2"></div>
    </div>
</div>