<main class="main">
    <molla-page-header title="{{'contactus'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'contactus'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <!-- <div id="map" class="mb-5">
            <div *ngIf="apiLoaded | async">
                <google-map></google-map>
            </div>
        </div> -->

        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="contact-box text-center">
                        <h3>{{'address'|translate}}</h3>
                        <address *ngIf="selected==='en'">Al Muhammadiya, Dammam <br> Saudi Arabia
                        </address>
                        <address *ngIf="selected==='ar'">
                            حي المحمدية ، الدمام <br>، المملكة العربية السعودية
                        </address>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="contact-box text-center">
                        <h3>{{'Start a Conversation'|translate}}</h3>

                        <div><a href="mailto:support@aptools.sa"><i class="icon-envelope"></i> support@aptools.sa</a></div>
                        <div>
                            <a class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                            <a class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="contact-box text-center">
                        <h3>{{'Social'|translate}}</h3>

                        <div class="social-icons justify-content-center mb-0">
                            <a class="social-icon" target="_blank" href="https://m.facebook.com/ArabProTools/">
                                <i class="icon-facebook-f"></i>
                            </a>
                            <a class="social-icon" target="_blank" href="https://twitter.com/ArabProTools"><i
                                    class="icon-twitter"></i>
                            </a>
                            <a class="social-icon" target="_blank" href="https://www.instagram.com/arabprotools/"><i
                                    class="icon-instagram"></i>
                            </a>
                            <a class="social-icon" target="_blank"
                                href="https://www.linkedin.com/company/arab-pro-tools"><i class="icon-linkedin"></i>
                            </a>
                            <!-- <a class="social-icon" target="_blank" href="https://www.youtube.com/@arabprotools"><i
                                    class="icon-youtube"></i>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-5 mt-md-1">
            <div class="touch-container row justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div class="text-center">
                        <h2 class="title mb-1">{{'GetInTouch'|translate}}</h2>
                    </div>
                    <!-- <div class="container" *ngIf="cloading">
                        <div class="row">
                            <div class="col-12">
                                <div class="cart-empty-page text-center loading">
                                    <div class="bounce-loader">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <form action="#" class="contact-form mb-2" >
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="cname" class="sr-only">{{'name'|translate}}</label>
                                <input type="text" class="form-control" id="cname" placeholder="{{'name'|translate}} *"
                                    required name="name" [(ngModel)]="contactUs.name">
                            </div>

                            <div class="col-sm-4">
                                <label for="cemail" class="sr-only">{{'email'|translate}}</label>
                                <input type="email" class="form-control" id="cemail"
                                    placeholder="{{'email'|translate}} *" required name="email"
                                    [(ngModel)]="contactUs.email">
                            </div>

                            <div class="col-sm-4">
                                <label for="cphone" class="sr-only">{{'mobile'|translate}}</label>
                                <input type="tel" class="form-control" id="cphone" placeholder="{{'mobile'|translate}}"
                                    name="mobile" [(ngModel)]="contactUs.mobile">
                            </div>
                        </div>

                        <label for="csubject" class="sr-only">{{'subject'|translate}}</label>
                        <input type="text" class="form-control" id="csubject" placeholder="{{'subject'|translate}}"
                            name="subject" [(ngModel)]="contactUs.subject">

                        <label for="cmessage" class="sr-only">{{'message'|translate}}</label>
                        <textarea class="form-control" cols="30" rows="4" id="cmessage" required
                            placeholder="{{'message'|translate}} *" name="message"
                            [(ngModel)]="contactUs.message"></textarea>

                        <div class="text-center">
                            <button type="button" (click)="SendMessage()"
                                class="btn btn-outline-primary-2 btn-minwidth-sm">
                                <span>SUBMIT</span>
                                <i class="icon-long-arrow-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>