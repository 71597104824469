import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DirectionService } from 'src/app/shared/services/direction.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'products-block',
  templateUrl: './products-block.component.html',
  styleUrls: ['./products-block.component.scss']
})
export class ProductsBlockComponent implements OnInit {

  @Input() header = '';
  @Input() products = [];
  @Input() loaded = false;
  selected: string;
  sliderOption: OwlOptions = {
    items: 6.5,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navText: ['<i class="icon-angle-left">', '<i class="icon-home">'],
    // smartSpeed: 400,
    // autoplay: true,
    // autoplayTimeout: 15000,
    nav: true,
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 2.5
      },
      480: {
        items: 3.5
      },
      768: {
        items: 4.5
      },
      992: {
        items: 5.5
      },
      1200: {
        items: 6.5
      }
    },
    rtl: this.direction.isRTL()
  }
  constructor(private lang: LanguageService, private direction: DirectionService) { }

  ngOnInit(): void {
    this.selected = this.lang.selected;
    this.sliderOption.navText = this.selected === 'en' ? ['<i class="icon-angle-left">', '<i class="icon-angle-right">'] : ['<i class="icon-angle-right">', '<i class="icon-angle-left">']; 
  }
}
