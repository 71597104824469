import { Component, OnInit, Input } from "@angular/core";
import { ModalService } from "src/app/shared/services/modal.service";
import { CartService } from "src/app/shared/services/cart.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { Product } from "src/app/shared/interfaces/product";
import { RootService } from "src/app/shared/services/root.service";
import { SessionService } from "src/app/shared/services/session.service";

@Component({
  selector: "product-card-contract",
  templateUrl: "./product-card-contract.component.html",
  styleUrls: ["./product-card-contract.component.scss"],
})
export class ProductCardContract implements OnInit {
  @Input() product: Product;
  maxPrice = 0;
  minPrice = 99999;

  selected: string;
  saved;
  company;
  addingToCart = false;
  company_can_see_gifts = false;

  constructor(
    private modalService: ModalService,
    private cart: CartService,
    private lang: LanguageService,
    public root: RootService,
    private session: SessionService
  ) {}

  ngOnInit(): void {
    this.selected = this.lang.selected;
    let min = this.minPrice;
    let max = this.maxPrice;
    this.minPrice = min;
    this.maxPrice = max;
    this.product.svat = Number(this.product.svat);
    this.product.vat = Number(this.product.vat);
    this.saved = Math.round(
      ((this.product.price - this.product.sale) / this.product.price) * 100
    );
    this.company = this.session.getCompany();
    this.company_can_see_gifts = !this.company || (this.company && this.company.allow_slaps == 0) ? true: false;
  }

  addToCart(event: Event) {
    event.preventDefault();
    if ((event.currentTarget as HTMLElement).classList.contains("btn-disabled"))
      return;
    if (!this.addingToCart) {
      this.addingToCart = true;
      this.cart.getStock(this.product, 1).subscribe((res) => {
        this.product.stock = res.valueOf();
      });
      this.cart.add(this.product, 1, [], 0).subscribe({
        complete: () => {
          this.addingToCart = false;
          if (this.product.bundle) {
            this.cart.add(this.product.bundle, 1).toPromise();
          }
        },
      });
    }
  }

  quickView(event: Event) {
    event.preventDefault();
    console.log(this.product);
    this.modalService.showQuickView(this.product);
  }
}
