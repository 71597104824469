import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'molla-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {

	current = '/';
	items;
	private subscr: Subscription;

	constructor(private router: Router,public root:RootService) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.current = event.url;
			} else if (event instanceof NavigationEnd) {
				this.current = event.url;
			}
		});
	}

	ngOnInit(): void {
		this.items = [
			{label: 'home', url: this.root.home()},
			// {label: 'shop', url: this.root.shop()},
			{label: 'topSelling', url: this.root.bunch('top-selling')},
			{label: 'newArrivals', url: this.root.bunch('new-arrival')},
			// {label: 'clearance', url: this.root.category('clearance')},
			{label: 'bestDeals', url: this.root.bunch('best-deals')},
			{label: 'salesDepartments', url: this.root.about()}
		];
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	viewAllDemos(event: any) {
		event.preventDefault();
		var list = document.querySelectorAll('.demo-list .hidden');
		for (let i = 0; i < list.length; i++) {
			list[i].classList.add('show');
		}

		event.target.parentElement.classList.add('d-none');
	}
}
