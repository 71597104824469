import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DirectionService } from './direction.service';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selectedLanguage$ = new BehaviorSubject<string>('');
  selected = '';
  constructor(
    private translate: TranslateService,
    private direction: DirectionService
    ) { }
  setInitialAppLanguage(){
    let browserLanguage = this.translate.getBrowserLang();
    this.translate.setDefaultLang(browserLanguage);
    let localStorageLanguage = localStorage.getItem(LNG_KEY);
    let lng = '';
      if (localStorageLanguage) {
        lng = localStorageLanguage;
      }else{
        lng = browserLanguage;
      }
    this.setLanguage(lng);
    return lng;
  }

  setDirectionCSS(lng){
    if(lng==='ar'){
      this.direction.value = 'rtl';
    }else{
      this.direction.value = 'ltr';
    }
  }

  getLanguages(){
    return [
        {name: 'English',code: 'en' ,direction:'ltr' ,image: 'language-1'},
        {name: 'العربية',code: 'ar' ,direction:'rtl' ,  image: 'language-2'}
    ]
  }
  setLanguage(lng){
    this.translate.use(lng);
    this.selectedLanguage$.next(lng);
    this.selected = lng;
    localStorage.setItem(LNG_KEY, lng);
    this.setDirectionCSS(lng);
  }
}
