import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Facebook } from 'src/app/shared/interfaces/facebook';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { SessionService } from 'src/app/shared/services/session.service';

import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
	selector: 'molla-header-search',
	templateUrl: './header-search.component.html',
	styleUrls: ['./header-search.component.scss']
})

export class HeaderSearchComponent implements OnInit, OnDestroy {

	products = [];
	searchTerm = "";
	suggestions = [];
	timer: any;
	qry = '';
	selected;
	hasSuggestions = false;
    counter = 0;
    total = 0;
	company: any;

	constructor(
		public activeRoute: ActivatedRoute,
		public router: Router,
		public utilsService: UtilsService,
		private auth: AuthService,
		public root:RootService,
		private lang:LanguageService,
		private session:SessionService,
		private cookie:CookieService,
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.activeRoute.queryParams.subscribe(parameters => {
			this.qry = parameters.qry ?? '';
		});
		document.querySelector('body').addEventListener('click', this.closeSearchForm);
	}

	ngOnDestroy(): void {
		document.querySelector('body').removeEventListener('click', this.closeSearchForm);
	}

	searchProducts(event: any) {
		this.searchTerm = event.target.value;
		this.fetchProducts();
	}

	fetchProducts() {
		if (this.searchTerm.length > 2) {
			if (this.timer) {
				window.clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				this.auth.getSuggestions({'qry':this.searchTerm}).subscribe(products => {
					this.hasSuggestions = products.items?.length > 0;
					this.counter = products.counter;
					this.total = products.total;
					if (products.items?.length > 0) {
						this.suggestions = products.items;
					}
				})
				// add facebook Event Server Side
				let facebook: Facebook = {};
				facebook.fbc = this.cookie.getCookie('_fbc');
				facebook.fbp = this.cookie.getCookie('_fbp');
				facebook.user = this.session.getUser() ?? null;
				facebook.event_name = "Search";
				this.auth.addFacebookEvent(facebook).subscribe(res => {
				console.log(res);
				});
			}, 500)
		} else {
			window.clearTimeout(this.timer);
			this.suggestions = [];
		}
	}

	matchEmphasize(name: string) {
		var regExp = new RegExp(this.searchTerm, 'i');
		return name.replace(
			regExp,
			match => '<strong>' + match + '</strong>'
		);
	}

	goProductPage() {
		this.searchTerm = '';
		this.suggestions = [];
		var inputElement: any = document.querySelector('.header-search .form-control');
		inputElement.value = "";
		this.closeSearchForm();
	}

	searchToggle(e: Event) {
		document.querySelector('.header-search').classList.toggle('show');
		e.stopPropagation();
	}

	showSearchForm(e: Event) {
		document
			.querySelector('.header .header-search')
			.classList.add('show');
		e.stopPropagation();
	}

	closeSearchForm() {
		document
			.querySelector('.header .header-search')
			.classList.remove('show');
	}

	submitSearchForm(e: Event) {
		e.preventDefault();
		this.router.navigate(['/shop'], { queryParams: { page: 1, qry: this.searchTerm } });
	}
	saveSearch(): void {
        let user = this.session.getUser();
        let data = {
            'products': this.total,
            'user': user ? user.id : 0,
            'qry': this.searchTerm
        };
        this.auth.saveSearch(data).subscribe();
    }
    search(): void {
        this.suggestions = [];
		var inputElement: any = document.querySelector('.header-search .form-control');
		inputElement.value = "";
		this.closeSearchForm();
        if (this.searchTerm) {
            this.saveSearch();
            if (this.company && this.company.allow_slaps == 1) {
                this.router.navigateByUrl('/company?page=1&qry=' + this.searchTerm);
            } else {
                this.router.navigateByUrl('/shop?page=1&qry=' + this.searchTerm);
            }
        }
    }
}
