import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { SessionService } from '../services/session.service';
import { filter, first } from 'rxjs/operators';

@Component({
	selector: 'molla-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, OnDestroy {

	containerClass = 'container';
	isBottomSticky = false;
	current = "/";

	loaded = false;

	private subscr: Subscription;

	constructor(private router: Router, private route: ActivatedRoute,
		private session: SessionService,
		private auth: AuthService,
		private lang: LanguageService
	) {
		const navigationEnd = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		);
		navigationEnd.pipe(first()).subscribe(() => {
			this.loaded = true;
		});
		this.route.params.subscribe(data => {
			if (data.lang) {
				if (data.lang != 'en' && data.lang != 'ar') {
					this.router.navigate(['/']).then();
				} else {
					if (data.lang != this.lang.selected) {
						this.lang.setLanguage(data.lang);
						if (this.session.getUser()) {
							this.auth.updateUserLanguage({ 'lang': data.lang }).subscribe(res => {
								window.location.reload();
							});
						} else {
							window.location.reload();
						}
					}
				}
			}
		});

		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.current = event.url;
				if (this.current.includes('fullwidth')) {
					this.containerClass = 'container-fluid';
				} else {
					this.containerClass = 'container';
				}

				if (this.current.includes('product/default') && (window.innerWidth > 991)) {
					this.isBottomSticky = true;
				} else {
					this.isBottomSticky = false;
				}
			} else if (event instanceof NavigationEnd) {
				this.current = event.url;
				if (this.current.includes('fullwidth')) {
					this.containerClass = 'container-fluid';
				} else {
					this.containerClass = 'container';
				}

				if (this.current.includes('product/default') && (window.innerWidth > 991)) {
					this.isBottomSticky = true;
				} else {
					this.isBottomSticky = false;
				}
			}
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	@HostListener('window:resize', ['$event'])
	handleKeyDown(event: Event) {
		this.resizeHandle()
	}

	resizeHandle() {
		if (this.current.includes('product/default') && window.innerWidth > 992)
			this.isBottomSticky = true;
		else
			this.isBottomSticky = false;
	}
}
