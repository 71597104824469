<main class="main">
    <molla-page-header title="{{'subscribeRegistration' | translate}}" subtitle=""></molla-page-header>
    <div class="login-page  pt-4 pb-8 pt-md-4 pb-md-12 pt-lg-4 pb-lg-17">
        <div class="container">
            <div class="form-box">
                <form action="#" name="myForm" #myForm="ngForm">
                    <label>{{'name'|translate}} *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.name" name="name"
                        [ngClass]="{'error': errors.name}">
                    <small class="form-text form-error" *ngIf="errors.name">{{errors.name|translate}}</small>

                    <label>{{'mobile'|translate}} *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.mobile" name="mobile"
                        [ngClass]="{'error': errors.mobile}">
                    <small class="form-text form-error" *ngIf="errors.mobile">{{errors.mobile|translate}}</small>

                    <label>{{'email'|translate}} *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.email" name="email"
                        [ngClass]="{'error': errors.email}">
                    <small class="form-text form-error" *ngIf="errors.email">{{errors.email|translate}}</small>

                    <button type="submit" class="btn btn-outline-primary-2" (click)="submit()">
                        <span>{{'submit' | translate}}</span>
                        <i class="icon-long-arrow-right"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</main>