<div class="container new-collection">
    <div class="heading heading-flex mb-3">
        <div class="heading-left" aniName="blurIn">
            <h2 class="title">{{header}}</h2>
        </div>
    </div>

    <owl-carousel-o [options]="sliderOption" carouselClasses="owl-theme carousel-equal-height carousel-with-shadow"
        *ngIf="!loaded else elseBlock">
        <ng-template *ngFor="let item of [1,2,3,4,5,6]" carouselSlide>
            <div class="skel-pro"></div>
        </ng-template>
    </owl-carousel-o>
    <ng-template #elseBlock>
        <owl-carousel-o *ngIf="products?.length" [options]="sliderOption">
            <ng-template *ngFor="let product of products" carouselSlide>
                <product-card [product]="product"></product-card>
            </ng-template>
        </owl-carousel-o>
    </ng-template>
</div>