<a [ngClass]="{'sf-with-ul': item.items?.length}" [href]="root.category(item.slug)">
    {{item['title_'+selected]}}
    <i class="icon-angle-right" *ngIf="selected==='en' && item.items?.length"></i>
    <i class="icon-angle-left" *ngIf="selected==='ar' && item.items?.length"></i>
    <!-- <span class="item-count">{{ item.products }}</span> -->
</a>
<ul class="menu-vertical sf-arrows" *ngIf="item.items?.length">
    <li *ngFor="let item of item.items">
        <category-menu-item [item]="item"></category-menu-item>
    </li>
</ul>