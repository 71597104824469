<main class="main">
    <molla-page-header title="{{'returnRequest'|translate}}" subtitle=""></molla-page-header>

    <div class="page-content">
        <div class="cart">
            <div class="container" *ngIf="!loaded">
                <div class="row">
                    <div class="col-12">
                        <div class="cart-empty-page text-center loading bg-lighter">
                            <div class="bounce-loader">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 touch-container row justify-content-center" *ngIf="loaded" style="margin:20px auto 0;padding-top:10px">
                <form action=" #" class="mb-2">
                    <ng-container *ngIf="order && order.items">
                        <div class="return-item" *ngFor="let item of order.items; let i = index">
                            <ng-container *ngIf="!item.GoldenWarrantyTransID">
                                <label >{{item['title_'+selected]}}</label>
                                <molla-quantity-input [value]="item.qty" [max]="item.qty" [step]="1" [allowZero]="true" (changeQty)="onChangeQty( $event, item ,i )"> </molla-quantity-input>
                            </ng-container>
                        </div>
                    </ng-container>
                    <label for="reason">{{'reason'|translate}}*</label>
                    <textarea class="form-control" cols="120" rows="4" id="reason" required name="reason" [(ngModel)]="reasonValue"></textarea>

                    <div class="text-center">
                        <button type="button" (click)="onSubmit()" class="btn btn-outline-primary-2 btn-minwidth-sm">
                            <span>{{'sendRequest' | translate}}</span>
                            <i class="icon-long-arrow-right"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>