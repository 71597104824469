import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ComplaintFormData } from 'src/app/shared/interfaces/static-forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
	selector: 'complaints-form-page',
	templateUrl: './complaints-form.component.html',
	styleUrls: ['./complaints-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ComplaintsFormComponent implements OnInit {
	@ViewChild('myForm', { static: false }) myForm: NgForm;
	loading = false;
	data = new ComplaintFormData();
	errors = new ComplaintFormData();
	selected: string;
	constructor(
		private alert: AlertService,
		private auth: AuthService,
		private lang: LanguageService
	) { }

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
	submit() {
		if (this.loading) {
			return;
		}
		this.auth.complaintFormData(this.data).subscribe((result: any) => {
			this.loading = false;
			if (result.data) {
				this.alert.showSuccess(result['msg_' + this.selected]);
				this.myForm.resetForm();
			} else {
				this.alert.showError(result['msg_' + this.selected]);
			}
		});

	}
}
