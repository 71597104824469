import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
	selector: 'pages-test-page',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TestPageComponent implements OnInit {
	selected;
	result = '';
	constructor(
		private lang: LanguageService,
		private auth: AuthService
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.auth.test({}).subscribe(res => {
			console.log(res);
			this.result = res;
		})
	}
}
