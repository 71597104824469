export class User {
    id:number;
    mobile: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    repassword?: string;
    confirmPassword:string;
    active:number;
    billing_city_id:number;
    billing_address:string;
    billing_city:string;
    billing_postcode:string;
    companyName_en:string;
    companyName_ar:string;
    cr:string;
    vat:string;
    created_date:Date;
    oid:number;
    shipping_city_id:number;
    shipping_address:string;
    shipping_city:string;
    shipping_postcode:string;
    //extend
    newPassword:string;
    reNewPassword:string;
    company:string;
	mobile_verified:number;
	recaptcha_token?: string;
	captcha?:string;
    cod_block?:number;
    agreeNewsletter?:boolean;
    lang?:string;
    loyality?:number;
    cod_token?:string;
    register_token?:string;
}
export class ChangePassword{
    password:string;
    oldpassword:string;
    repassword:string;
}
export class ForgetPassword{
    mobile:string;
    reset_token:string;

}
export class ResetPasswordLogin{
    password:string;
    repassword:string;
    mobile:string;
}
