export class Params {
    slug?: string;
    page?:number;
    limit?:number;
    sort?:string;
    discount?:string;
    filter_brand?:string;
    filter_price?:string;
    qry?:string;
    brand?:string;
    category?:string;
    bunch?:string;
    filter_stock?:string;
}