import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component( {
	selector: 'molla-quantity-input',
	templateUrl: './quantity-input.component.html',
	styleUrls: [ './quantity-input.component.scss' ]
} )

export class QuantityInputComponent implements OnInit {

	@Input() value;
	@Input() max = 10000;
	@Input() step = 1;
	@Input() adClass = "";
	@Input() disabled = false;
	@Input() allowZero = false;
	@Output() changeQty: EventEmitter<number>;

	current = this.step;

	constructor () {
		this.changeQty = new EventEmitter<number>();
	}

	// ngOnChanges (): void {
	// 	this.current = this.value;
	// }

	ngOnInit (): void {
		this.current = this.value ?? this.intParser(this.step);
	}
	intParser(x): any{
		return Math.round(x *100) /100;
	}
	increment () {
		if ( this.intParser(this.max) <= 0 || this.intParser(this.current) >= this.intParser(this.max) ){
			return;
		}
		this.current = (Math.round(this.current *100) /100) + (Math.round(this.step *100) /100);
		this.changeQty.emit( this.current );
	}

	decrement () {
		if ( this.intParser(this.current) > this.step || (this.intParser(this.current) >= this.step && this.allowZero)) {
			this.current = this.current - this.step;
			this.changeQty.emit( this.current );
		}
	}

	changeCurrent ( event: any ) {
		if ( parseInt( event.currentTarget.value ) < this.max && parseInt( event.currentTarget.value ) > 0 ) {
			this.current = parseInt( event.currentTarget.value );
			this.changeQty.emit( this.current );
		} else {
			event.currentTarget.value = this.current;
		}
	}
}