import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// Header Element
import { CartMenuComponent } from './components/headers/shared/cart-menu/cart-menu.component';
import { CompareMenuComponent } from './components/headers/shared/compare-menu/compare-menu.component';
import { WishlistMenuComponent } from './components/headers/shared/wishlist-menu/wishlist-menu.component';
import { CategoryMenuComponent } from './components/headers/shared/category-menu/category-menu.component';
import { MainMenuComponent } from './components/headers/shared/main-menu/main-menu.component';
import { HeaderSearchComponent } from './components/headers/shared/header-search/header-search.component';
import { MobileButtonComponent } from './components/headers/shared/mobile-button/mobile-button.component';
import { MobileMenuComponent } from './components/headers/shared/mobile-menu/mobile-menu.component';

// Header Component
import { HeaderComponent } from './components/headers/header/header.component';

// // Product Component

import { ProductCardHorizontal } from './components/product/product-card-horizontal/product-card-horizontal.component';

// Footer Component
import { FooterComponent } from './components/footer/footer.component';
// // Page Element
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
// Product Element
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';

// // single use component
import { QuickViewComponent } from './components/modals/quick-view/quick-view.component';
import { NewsletterModalComponent } from './components/modals/newsletter-modal/newsletter-modal.component';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import { ImageComponent } from './components/image/image.component';

// // Custom Directives
import { BgParallaxDirective } from './directives/bg-parallax.directive';
import { TabClickDirective } from './directives/custom-tab-click.directive';
// import { ProductHoverDirective } from './directives/product-hover.directive';
import { ContentAnimDirective } from './directives/content-anim.directive';

// Pipes
import { CatFilterPipe } from './pipes/cat-filter.pipe';
import { AttrFilterPipe } from './pipes/attr-filter.pipe';
import { SafeContentPipe } from './pipes/safe-content.pipe';

// // Post Component
import { HttpClientModule } from '@angular/common/http';
import { ProductCard } from './components/product/product-card/product-card.component';
import { AlertService } from './services/alert.service';
import { FormsModule } from '@angular/forms';
import { ProductCardShopComponent } from './components/product/product-card-shop/product-card-shop.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { WarrantyFormComponent } from '../pages/warranty-form/warranty-form.component';
import { ComplaintsFormComponent } from '../pages/complaints-form/complaints-form.component';
import { SubscribeFormComponent } from '../pages/subscribe-form/subscribe-form.component';
import { TestPageComponent } from '../pages/test/test.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { StickymenuComponent } from './components/stickymenu/stickymenu.component';
import { CategoriesComponent } from '../pages/categories/categories.component';
import { LoginPageComponent } from '../pages/login/login.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { productsBlockRowsComponent } from '../pages/home/products-block-rows/products-block-rows.component';
import { CategoryMenuItem } from './components/headers/shared/category-menu-item/category-menu-item.component';
import { NotFoundComponent } from '../pages/404/404.component';
import { CategoryMobileMenuItem } from './components/headers/shared/category-menu-mobile-item/category-menu-mobile-item.component';
import { TabbymodalComponent } from './components/modals/tabbymodal/tabbymodal.component';
import { TamaramodalComponent } from './components/modals/tamaramodal/tamaramodal.component';
import { VouchermodalComponent } from './components/modals/vouchermodal/vouchermodal.component';
import { SearchDropdown } from './components/search-dropdown/search-dropdown';
import { ProductsBlockComponent } from '../pages/home/products-block/products-block.component';
import { ProductCardContract } from './components/product/product-card-contract/product-card-contract.component';

@NgModule({
    declarations: [
        // header
        CartMenuComponent,
        CompareMenuComponent,
        WishlistMenuComponent,
        CategoryMenuComponent,
        MainMenuComponent,
        HeaderSearchComponent,
        MobileButtonComponent,
        MobileMenuComponent,
        HeaderComponent,
        FooterComponent,
        // product
        ProductCard,
        ProductCardShopComponent,
        ProductCardHorizontal,
        ProductCardContract,
        // single-use components
        BreadcrumbComponent,
        PageHeaderComponent,
        QuickViewComponent,
        NewsletterModalComponent,
        LoginModalComponent,
        QuantityInputComponent,
        PaginationComponent,
        ImageComponent,
        // directives
        BgParallaxDirective,
        TabClickDirective,
        // ProductHoverDirective,
        ContentAnimDirective,
        // pipes
        CatFilterPipe,
        AttrFilterPipe,
        SafeContentPipe,
        CurrencyFormatPipe,
        // // blog-post
        WarrantyFormComponent,
        ComplaintsFormComponent,
        SubscribeFormComponent,
        TestPageComponent,
        AccordionComponent,
        StickymenuComponent,
        CategoriesComponent,
        LoginPageComponent,
        productsBlockRowsComponent,
        CategoryMenuItem,
        NotFoundComponent,
        CategoryMobileMenuItem,
        TabbymodalComponent,
        TamaramodalComponent,
        VouchermodalComponent,
        SearchDropdown,
        ProductsBlockComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        LazyLoadImageModule,
        HttpClientModule,
        TranslateModule,
        FormsModule,
        NgbNavModule,
        CarouselModule
    ],
    providers: [
        AlertService,
        NgbNavModule
    ],
    exports: [
        // header
        HeaderComponent,
        // mobile-menus
        MobileMenuComponent,
        // footer
        FooterComponent,
        // products
        ProductCard,
        ProductCardShopComponent,
        ProductCardHorizontal,
        ProductCardContract,
        // // single-use components
        BreadcrumbComponent,
        PageHeaderComponent,
        PaginationComponent,
        QuantityInputComponent,
        ImageComponent,
        // directives
        BgParallaxDirective,
        TabClickDirective,
        // ProductHoverDirective,
        ContentAnimDirective,
        // pipes
        CatFilterPipe,
        AttrFilterPipe,
        SafeContentPipe,
        CurrencyFormatPipe,
        WarrantyFormComponent,
        ComplaintsFormComponent,
        SubscribeFormComponent,
        TestPageComponent,
        AccordionComponent,
        productsBlockRowsComponent,
        NotFoundComponent,
        SearchDropdown,
        ProductsBlockComponent,
        CartMenuComponent,
        HeaderSearchComponent,
    ]
})

export class SharedModule { }