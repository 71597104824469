<div class="main">
    <molla-page-header title="{{'returnPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'returnPolicy'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <ol>
                <li>
                    Products may be returned within 7 days from the date of receipt
                </li>
                <li>
                    Products must have been supplied by Arab Pro for Industrial Equipment directly and it must be unused, undamaged, and in its original and non-separated boxes and it will be inspected by Arab Pro for Industrial Equipment

                </li>
                <li>
                    Chemical products (with an expiration date) may not be returned

                </li>
                <li>
                    Products purchased as part of an order including a free product sample or a promotion product may not be returned
                </li>
                <li>
                    The customer may return the entire order or only part of it

                </li>
                <li>
                    Return Policy : aptools.sa has the right to refuse returning any order that does not comply with its internal policies

                </li>
                <li>
                    In case of returning the product because of a defect in the product, defect from the manufacturer, damage during delivery of the product or because the delivered product is not right/wrong, no additional charges will be charged for shipping service or bank transfer fees. In case of other reasons for returning, ( customer need to replace the product or the customer’s desire has changed in general), shipping fees or bank transfer fees shall be imposed on the customer

                </li>
                <li>
                    In case the customer refuses to receive the product from the shipping company, the amount paid shall be returned to the customer after deducting the cost of shipping to the warehouses and the bank transfer fee

                </li>
            </ol>    
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <ol>
                <li>
                    ارجاع البضاعة يسمح به خلال سبعة أيام من تاريخ استلام المنتج.

                </li>
                <li>
                    يجب أن تكون البضاعة قد تم توريدها من شركة المحترفون العرب مباشرة ويشترط أن تكون غير مستعملة، غير تالفة، وفي صناديقها الاصلية وغير مفرقة وسيتم الكشف عليها من قبل شركة المحترفون العرب.

                </li>
                <li>
                    المنتجات الكيميائية( التي لها تاريخ انتهاء صلاحية )لا يمكن قبول ارجاعها.

                </li>
                <li>
                    لا يمكن ارجاع البضائع التي قد تم شراؤها كجزء من طلبية تم فيها اعطاء منتج مجانا أو منتج بغرض الشرح او كعرض ترويجي.

                </li>
                <li>
                    يمكن ارجاع جزء من البضاعة أو كامل الطلبية المباعة.

                </li>
                <li>
                    من حق شركة المحترفون العرب رفض قبول ارجاع أي طلبية لا تتوافق مع سياساتها الداخلية.

                </li>
                <li>
                    في حالة إرجاع المنتج بسبب خلل في المنتج أو عيب من الشركة المصنعة أو نتيجة ضرر خلال توصيل المنتج أو بسبب توصيل المنتج غير الصحيح / الخاطئ، فلن يتم احتساب رسوم إضافية لخدمة الشحن أو رسوم التحويل البنكي أما في حالة الارجاع لأسباب أخرى، (حاجة الزبون إلى تبديل المنتج أو تغيرت رغبة الزبون بشكل عام) فسيتم احتساب رسوم الشحن و رسوم التحويل البنكي على الزبون.
                </li>
                <li>
                    في حال رفض العميل استلام المنتج من شركة الشحن يتم ارجاع المبلغ المدفوع الى العميل بعد خصم تكلفة شحن المنتج الى المستودعات ورسوم التحويل البنكي.
                </li>
            </ol> 
        </div>
        <div class="mb-2"></div>
    </div>
</div>