import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/interfaces/product';
import { ModalService } from 'src/app/shared/services/modal.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { CompareService } from 'src/app/shared/services/compare.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
	selector: 'product-card-horizontal',
	templateUrl: './product-card-horizontal.component.html',
	styleUrls: ['./product-card-horizontal.component.scss']
})

export class ProductCardHorizontal implements OnInit {

	@Input() product: Product;
	maxPrice = 0;
	minPrice = 99999;
	selected;
	addingToCart = false;
    company;
    company_can_see_gifts = false;

	constructor(
		private router: Router,
		private modalService: ModalService,
		private cart: CartService,
		private wishlistService: WishlistService,
		private compareService: CompareService,
		private lang:LanguageService
	) { }

	ngOnInit(): void {
		this.selected = this.lang.selected;
		let min = this.minPrice;
		let max = this.maxPrice;
		this.minPrice = min;
		this.maxPrice = max;
		this.company_can_see_gifts = !this.company || (this.company && this.company.allow_slaps==0) ? true : false;
	}

	addToCart(event: Event) {
		event.preventDefault();
		if ((event.currentTarget as HTMLElement).classList.contains('btn-disabled')) return;
		if (!this.addingToCart) {
		  this.addingToCart = true;
		  this.cart.getStock(this.product, 1).subscribe(res => {
			this.product.stock = res.valueOf();
		  });
		  this.cart.add(this.product, 1, [], 0).subscribe({
			complete: () => {
			  this.addingToCart = false;
			}
		  });
		}
	}

	addToWishlist(event: Event) {
		event.preventDefault();

		if (this.isInWishlist()) {
			this.router.navigate(['/shop/wishlist']);
		} else {
			this.wishlistService.addToWishList(this.product);
		}
	}

	addToCompare(event: Event) {
		event.preventDefault();
		if (this.isInCompare()) return;
		this.compareService.addToCompare(this.product);
	}

	quickView(event: Event) {
		event.preventDefault();
		this.modalService.showQuickView(this.product);
	}

	isInCompare() {
		return this.compareService.isInCompare(this.product);
	}

	isInWishlist() {
		return this.wishlistService.isInWishlist(this.product);
	}
}