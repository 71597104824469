import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WarrantyFormData } from 'src/app/shared/interfaces/static-forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
	selector: 'warranty-form-page',
	templateUrl: './warranty-form.component.html',
	styleUrls: ['./warranty-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class WarrantyFormComponent implements OnInit {
	@ViewChild('myForm', { static: false }) myForm: NgForm;
	loading = false;
	data: WarrantyFormData = new WarrantyFormData();
	errors: WarrantyFormData = new WarrantyFormData();
	image = 'assets/images/page-header-bg.jpg';
	constructor(
		private alert: AlertService,
		private auth: AuthService
	) { }

	ngOnInit(): void {
	}
	submit() {
		if (this.loading) {
			return;
		}
		this.auth.warrantyFormData(this.data).subscribe((result: any) => {
			this.loading = false;
			if (result.data) {
				this.alert.showSuccess(result['msg']);
				this.myForm.resetForm();
			} else {
				this.alert.showError(result['msg']);
			}
		});

	}
}
