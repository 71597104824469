import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { RootService } from '../../services/root.service';

@Component({
	selector: 'molla-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

	@Input() containerClass = "container";
	@Input() isBottomSticky = false;

	selected;
	address_ar = 'حي المحمدية ، الدمام ، المملكة العربية السعودية';
	emailAddress = 'support@aptools.sa';
	phone_ar = ['0552822719'];
	hours_ar = 'من الاحد الي الخميس ' + ' ٨ صباحا - ٥ مساءً';
	phone = ['+966552822719'];
	address_en = 'Al Muhammadiya, Dammam, Saudi Arabia';
	phone_en = ['(966) 55 2822 719'];
	hours_en = 'Sun-Thu 8:00am - 5:00pm';
	copyrights_en = 'Copyright © ' + (new Date()).getFullYear() + ' Arab Pro Tools. All Rights Reserved.';
	copyrights_ar = '© ' + (new Date()).getFullYear() + ' جميع الحقوق محفوظة لدي شركة المحترفون العرب';

	constructor(
		private lang: LanguageService,
		public root: RootService
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
}
