<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="modal-terms">
        <h5>{{'voucherDetails'|translate}}</h5>
        <h6>{{'voucher'|translate}} {{voucherVal | currencyFormat}} </h6>
        <hr>
        <ul *ngIf="selected==='en'">
            <li><i class="icon-check-circle-o"></i> The coupon can be used for your next purchase from APtools store</li>
            <li><i class="icon-check-circle-o"></i> This purchase voucher is valid until <b>December 31, 2023</b>.</li>
            <li><i class="icon-check-circle-o"></i> The purchase voucher can be used once </li>
            <li><i class="icon-check-circle-o"></i> In case of a return: If the purchase voucher has not been used, it will be canceled.<br> If the purchase voucher has been used, its value will be deducted from the refund amount.</li>
            <li><i class="icon-check-circle-o"></i> Purchase voucher will be sent through the e-mail after the shipment of the order.</li>
        </ul>
        <ul *ngIf="selected==='ar'">
            <li><i class="icon-check-circle-o"></i> هذه القسيمة الشرائية صالحة حتى<b> 31 ديسمبر 2023</b></li>
            <li><i class="icon-check-circle-o"></i> يمكن استخدام القسيمة الشرائية مرة واحدة </li>
            <li><i class="icon-check-circle-o"></i> في حالة الإرجاع: إذا لم يتم استخدام القسيمة الشرائية، ستُلغى.<br> إذا تم استخدام القسيمة الشرائية، سيتم خصم قيمتها من مبلغ الاسترداد.</li>
            <li><i class="icon-check-circle-o"></i> سيتم إرسال القسيمة الشرائية بعد شحن الطلب عبر البريد الإلكتروني</li>
        </ul>
    </div>
</div>