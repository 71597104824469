import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonService {
  static scriptType = 'application/json+ld';
	static websiteSchema = (url?: string, name?: string) => {
		return {
			'@context': 'http://schema.org',
			'@type'      : 'WebSite',
			url          : url || 'https:\/\/aptools.sa',
			name         : name || 'Arab Pro Tools',
			"sameAs": ["https://facebook.com/ArabProTools",
				   "https://instagram.com/arabprotools",
				   "https://twitter.com/ArabProTools"]
			};
	};

	static orgSchema = () => ({
			'@context'  :
				'https://schema.org',
			'@type'     :
				'Organization',
			url         :
				'https://aptools.sa',
			name        :
				'Arab Pro Tools',
			contactPoint: {
				'@type'    :
					'ContactPoint',
				telephone  :
					'0552822719',
				contactType:
					'Customer service'
			}
		});

  constructor(@Inject(DOCUMENT) private _document: Document) { }
  removeStructuredData(): void {
		const els = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any> = JsonService.orgSchema, className = 'structured-data'): void {
		let script;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}
}
