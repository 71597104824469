<header class="header header-intro-clearance header-4 mobileSticky">
    <div class="mobile-search-container mobile header-search-mobile">
        <label class="sr-only">{{'searchPlaceholder' | translate}}</label>
        <input type="search" class="form-control" name="searchTerm" id="mobile-search">
        <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
    </div>
    <div class="header-middle sticky-wrapper"
        [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
        <div class="pt-2 desktop"></div>
        <div class="container sticky-header" [class.fixed]="utilsService.isSticky">
            <div class="header-left">
                <a href="javascript:;" class="logo">
                    <img src="assets/images/apt_logo.svg" alt="Arab Pro Tools">
                </a>
            </div>

            <!-- Search form -->
            <div class="header-center d-none d-lg-flex">
                <div class="header-search header-search-extended header-search-visible d-none d-lg-block">
                    <a href="#" class="search-toggle" role="button">
                        <i class="icon-search"></i>
                    </a>
                    <div class="header-search-wrapper search-wrapper-wide">
                        <label class="sr-only">{{'searchPlaceholderContract' | translate}}</label>
                        <button class="btn btn-primary" type="submit">
                            <span class="sr-only">{{'searchPlaceholderContract' | translate}}</span>
                            <i class="icon-search"></i>
                        </button>
                        <input type="text" class="form-control" name="searchTerm" id="searchTerm"
                            placeholder="{{'searchPlaceholderContract' | translate}}" required 
                            [(ngModel)]="searchTerm" (input)="searchProducts()"
                        />
                    </div>
                </div>
            </div>

            <div class="header-right">
                
                <button [disabled]="(cartService.items$ | async).length == 0 || !loaded" (click)="createOrder()" class="btn btn-primary">{{'PlaceOrder' | translate}}</button>

                <div class="cart-dropdown desktop-flex">
                    <div class="dropdown-toggle">
                        <a href="javascript:;" role="button" [class]="otherLang.code"
                            (click)="changeLanguage(otherLang.code)">
                            <div class="icon"><img [src]="'assets/images/'+otherLang.image"></div>
                            <p>{{otherLang.name}}</p>
                        </a>
                    </div>
                </div>



                <!-- Cart Icon dropdown -->
                <div class="dropdown cart-dropdown">
                    <a href="javascript:;" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" data-display="static">
                        <div class="icon">
                            <i class="icon-shopping-cart"></i>
                            <span class="cart-count">{{ cartService.quantity$ | async }}</span>
                        </div>
                        <p>{{'cart'|translate}}</p>
                    </a>
                
                    <div class="dropdown-menu dropdown-menu-{{direction}}"
                        [ngClass]="{'text-center': (cartService.items$ | async).length == 0}">
                        <p *ngIf="(cartService.items$ | async).length == 0 else elseBlock">{{'emptyCart' | translate}}</p>
                
                        <ng-template #elseBlock>
                            <div class="dropdown-cart-products">
                                <div class="product justify-content-between" *ngFor="let item of cartService.items$ | async">
                                    <div class="product-cart-details">
                                        <h4 class="product-title">
                                            {{ item.product['title_'+selected] }}
                                        </h4>
                
                                        <span class="cart-product-info">
                                            <span class="cart-product-qty">{{ item.quantity}}</span>
                                            x {{ item.product.svat ? item.product.svat: item.product.vat }}
                                        </span>
                                    </div>
                
                                    <figure class="product-image-container">
                                        
                                            <img [src]="item.product.img" alt="product" width="50" height="50">
                                    </figure>
                
                                    <a href="javascript:;" class="btn-remove" title="Remove Product"
                                        (click)="removeFromCart( $event, item )"><i class="icon-close"></i></a>
                                </div>
                            </div>
                            <div class="dropdown-cart-subtotal" *ngIf="(cartService.bundleDiscount$ | async)">
                                <span>{{ 'bundle_discount' | translate }}</span>
                                <span class="cart-total-price" style="color: green;">{{ (cartService.bundleDiscount$ | async) |currencyFormat }}</span>
                            </div>
                            <div class="dropdown-cart-total">
                                <span>{{'Total' | translate}}</span>
                                <span class="cart-total-price">{{ cartService.subtotal$|async|currencyFormat}}</span>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <!-- Cart Icon dropdown END-->

                <!-- <div class="cart-dropdown desktop-flex">
                    <div class="dropdown-toggle">
                        <a *ngIf="!user" href="javascript:;" role="button" (click)="showLoginModal($event)">
                            <div class="icon"><i class="icon-user icon-user-red"></i></div>
                            <p>{{'login'|translate}}</p>
                        </a>
                        <a *ngIf="user" href="javascript:;" role="button" [href]="selected+'/account/dashboard'">
                            <div class="icon"><i class="icon-user icon-user-green"></i></div>
                            <p>{{'MyAccount'|translate}}</p>
                        </a>
                    </div>
                </div> -->

            </div>
        </div>
    </div>

    <div>

    </div>
</header>
<hr>

<div class="main shop">
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 skeleton-body sekl-shop-products" [class.loaded]="loaded">
                    <div class="error" *ngIf="errorMsg">{{errorMsg|translate}}</div>
                    <div class="success" *ngIf="succMsg">{{succMsg}}</div>
                    <shop-list-contract [type]="type" [products]="filteredProducts" [loaded]="loaded"></shop-list-contract>
                    <molla-pagination [perPage]="limit" [total]="total" *ngIf="total > limit">
                    </molla-pagination>
                </div>
            </div>
        </div>
    </div>
</div>