<header *ngIf="!contractsHeader" class="header header-intro-clearance header-4 mobileSticky">
    <div class="mobile-search-container mobile header-search-mobile">
        <form action="#" method="get" class="mobile-search" (click)="showSearchForm($event)" (submit)="submitSearchForm($event)">
            <label class="sr-only">{{'searchPlaceholder' | translate}}</label>
            <input type="search" class="form-control" name="searchTerm" id="mobile-search"
                placeholder="{{'searchPlaceholder' | translate}}" (input)="searchProducts($event)" [value]="qry" required>
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
            <a class="btn btn-default" type="cancel" (click)="toggleSearch($event)"><i class="icon-close"></i></a>

            <div class="live-search-list" *ngIf="searchTerm.length > 2">
                <div class="autocomplete-suggestions" *ngIf="suggestions.length > 0" (click)="goProductPage()">
                    <a [href]="root.product(product.slug)" class="autocomplete-suggestion"
                        *ngFor="let product of suggestions">
                        <figure class="mb-0 mr-3" style="width: 40px; height: 40px;">
                            <molla-image [src]="product.img" alt="{{product.title_en}}" width="40" height="40" class="bg-transparent">
                            </molla-image>
                        </figure>

                        <div class="search-name" [innerHTML]="matchEmphasize(product['brand_'+selected]+ ' ' + product['title_'+selected] + ' ' + product.sku) | safeContent"></div>
                        <span class="search-price">
                            <div class="product-price mb-0" *ngIf="!product.svat || product.svat >= product.vat || product.svat==='0'">
                                {{ product.vat | currencyFormat }}</div>
                                <div class="product-price mb-0" *ngIf="product.svat && product.svat < product.vat && product.svat!=='0'">
                                    <span class="new-price">{{ product.svat | currencyFormat }}</span><br>
                                    <span class="old-price">{{ product.vat | currencyFormat }}</span>
                                </div>
                        </span>
                    </a>
                    <a href="javascript:;" class="autocomplete-suggestion" (click)="search()">
                        <div class="search-name" >{{'ViewAll' | translate}}</div>
                    </a>
                </div>
            </div>
        </form>

    </div>


    <div class="header-middle sticky-wrapper" [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
        <div [class]="containerClass" class="sticky-header" [class.fixed]="utilsService.isSticky">
            <div class="header-left">
                <molla-mobile-button></molla-mobile-button>

                <a [href]="root.home()" class="logo">
                    <img src="assets/images/apt_logo.svg" alt="Arab Pro Tools">
                    <!-- <img *ngIf="selected==='ar'" src="assets/images/apt_ar.png" alt="Arab Pro Tools"> -->
                </a>
            </div>

            <div class="header-center d-none d-lg-flex">
                <molla-header-search></molla-header-search>
            </div>

            <div class="header-right">
                <!-- <molla-compare-menu></molla-compare-menu> -->
                <div class="cart-dropdown desktop-flex">
                    <div class="dropdown-toggle">
                        <a href="javascript:;" role="button" [class]="otherLang.code"
                            (click)="changeLanguage(otherLang.code)">
                            <div class="icon"><img [src]="'assets/images/'+otherLang.image"></div>
                            <p>{{otherLang.name}}</p>
                        </a>
                    </div>
                </div>




                <!-- <a href="javascript:;" [class]="otherLang.code" (click)="changeLanguage(otherLang.code)">
                    <img [src]="'assets/images/'+otherLang.image">
                    {{ otherLang.name }}
                </a>
                <a class="login" *ngIf="!user" href="javascript:;" (click)="showLoginModal($event)">{{'signin' |
                    translate}}</a>
                <a class="login" *ngIf="user" href="javascript:;" [href]="selected+'/account/dashboard'">{{'MyAccount' |
                    translate}}</a> -->

                <molla-wishlist-menu></molla-wishlist-menu>

                <div class="wishlist mobile">
                    <a (click)="toggleSearch($event)">
                        <div class="icon">
                            <i class="icon-search"></i>
                        </div>
                        <p>{{'Search'|translate}}</p>
                    </a>
                </div>

                <molla-cart-menu class="desktop"></molla-cart-menu>

                <div class="cart-dropdown desktop-flex">
                    <div class="dropdown-toggle">
                        <a *ngIf="!user" href="javascript:;" role="button" (click)="showLoginModal($event)">
                            <div class="icon"><i class="icon-user icon-user-red"></i></div>
                            <p>{{'login'|translate}}</p>
                        </a>
                        <a *ngIf="user" href="javascript:;" role="button" [href]="selected+'/account/dashboard'">
                            <div class="icon"><i class="icon-user icon-user-green"></i></div>
                            <p>{{'MyAccount'|translate}}</p>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div>
        <div class="header-bottom">
            <div [ngClass]="containerClass">
                <div class="header-left">
                    <molla-category-menu style="display: contents;"></molla-category-menu>
                </div>
                <div class="header-center">
                    <molla-main-menu></molla-main-menu>
                </div>

                <!-- <div class="header-right">
                    <i class="icon-medapps"></i>
                    <p>Clearance<span class="highlight">&nbsp;Up to 30% Off</span></p>
                </div> -->
            </div>
        </div>
    </div>
</header>