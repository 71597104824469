import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AlertService {
  constructor(
    private toasterService: ToastrService
    ) {}

  showSuccess(message?: string) {
    this.toasterService.success(
      message ? message : "success",
      "Completed successfully"
    );
  }
  showError(message?: string) {
    this.toasterService.error(message ? message : "Error");
  }

  clear() {
    this.toasterService.clear();
  }
  showWarning(message: string) {
    this.toasterService.warning(message);
  }
}
