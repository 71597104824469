import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "./session.service";
import { isPlatformBrowser } from "@angular/common";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  headers = new HttpHeaders({ "content-type": "application/json" });
  options = { headers: this.headers, withCredintials: false };

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  post(serviceName: string, data: any, needAuthorization?: boolean) {
    let header;
    if (needAuthorization) {
      let token: string = this.sessionService.getToken();
      if (!token) {
        //redirect to login
      }
      let authHeader = new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "accept/json",
        Authorization: `Bearer ${token}`,
      });

      header = { headers: authHeader, withCredintials: true };
    } else {
      header = this.options;
    }
    const url = environment.apiUrl + serviceName;
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post(url, JSON.stringify(data), header);
    }
    return new Observable();
  }
  postFile(serviceName: string, data: any, needAuthorization?: boolean) {
    let header;
    let headers = { headers: new HttpHeaders({}), withCredintials: false };
    if (needAuthorization) {
      let token: string = this.sessionService.getToken();
      if (!token) {
        //redirect to login
      }
      let authHeader = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      header = { headers: authHeader, withCredintials: true };
    } else {
      header = headers;
    }
    const url = environment.apiUrl + serviceName;
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post(url, data, header);
    }
    return new Observable();
  }
  get(serviceName: string, data: any, needAuthorization?: boolean) {
    const url = environment.apiUrl + serviceName;
    let header;
    if (needAuthorization) {
      let token: string = this.sessionService.getToken();
      if (!token) {
        //redirect to login
      }
      header = {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      header = this.options;
    }
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get(url, header);
    }
    return new Observable();
  }
}
