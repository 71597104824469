<div class="main">
    <molla-page-header title="{{'paymentMethods'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'paymentMethods'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <h6>Arab Pro Tools Payment methods</h6>
            <p>
                You can choose from various payment methods for your products purchases and extra services (e.g. tool
                repair, reconditioning etc.), depending on the country in which the payment takes place and the time of
                the payment. But whichever method you choose, you can rest assured that your payment is always safe.
            </p>


            <h6>Available payment methods:</h6>
            <ol>
                <li>
                    <img src="../../../../assets/images/payments/tappay.svg" class="payments-img" alt=""> MASTER CARD, VISA & MADA
                </li>
                <li>
                    <img src="../../../../assets/images/payments/tabby_installments.svg" class="payments-img" alt=""> TABBY
                </li>
                <li>
                    <img src="../../../../assets/images/payments/tamara.svg" class="payments-img" alt=""> TAMARA
                </li>
                <li>
                    <img src="../../../../assets/images/payments/bank_transfer.svg" class="payments-img" alt=""> DIRECT BANK TRANSFER
                </li>
                <li>
                    <img src="../../../../assets/images/payments/cod.svg" class="payments-img" alt=""> Cash On Delivery
                </li>
            </ol>

            <h6>Arab Pro Tools Payment methods </h6>
            <p>
                <b>Amount reserved on your credit or debit card</b> When using a credit or debit card for a payment on
                aptools.sa, you may see within a few days that your bank account is charged with the amount needed for
                your purchase. In fact, your bank only reserves this amount until Arab Pro Tools finalises your payment,
                after which your account will actually be charged. This may take up to 30 days. During this time, the
                amount reserved may be blocked: it cannot be used for other purchases.
                <br><br>
                When purchasing multiple items in one transaction, some banks wrongly reserve twice the amount: once for
                the total amount and once per every individual item. As a result, it reduces the amount you have
                available for other purchases. We are sorry for the inconvenience, but this procedure is outside the
                control of Arab Pro Tools. If you wish, you can contact your bank to request the release of the reserved
                amount.
            </p>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <h6>طرق الدفع </h6>
            <p>
                يمكنك الاختيار من بين طرق الدفع المختلفة لشراء منتجاتك والخدمات الإضافية (مثل إصلاح الأدوات ، التجديد
                وما إلى ذلك) ، اعتمادًا على الدولة التي يتم فيها الدفع ووقت الدفع. ولكن أيًا كانت الطريقة التي تختارها ،
                يمكنك أن تطمئن إلى أن دفعتك آمنة دائمًا.
            </p>

            <h6>طرق الدفع المتاحة: </h6>

            <ol>
                <li>
                    <img src="../../../../assets/images/payments/tappay.svg" class="payments-img" alt=""> فيزا و ماستر و مدى
                </li>
                <li>
                    <img src="../../../../assets/images/payments/tabby_installments.svg" class="payments-img" alt=""> تابي
                </li>
                <li>
                    <img src="../../../../assets/images/payments/tamara.svg" class="payments-img" alt=""> تمارا
                </li>
                <li>
                    <img src="../../../../assets/images/payments/bank_transfer.svg" class="payments-img" alt=""> تحويل بنكي
                </li>
                <li>
                    <img src="../../../../assets/images/payments/cod.svg" class="payments-img" alt=""> الدفع عند الاستلام
                </li>
            </ol>

            <p>
                <b>المبلغ المحجوز في بطاقة الائتمان أو الخصم الخاصة بك</b>عند استخدام بطاقة ائتمان أو بطاقة خصم لإجراء
                عملية دفع على موقع aptools.sa ، قد ترى في غضون أيام قليلة أنه تم تحميل حسابك المصرفي بالمبلغ المطلوب
                لعملية الشراء. في الواقع ، يحتفظ البنك الذي تتعامل معه بهذا المبلغ فقط حتى ينتهي Arab Pro Tools من الدفع
                الخاص بك ، وبعد ذلك سيتم بالفعل تحميل حسابك. قد يستغرق هذا ما يصل إلى 30 يومًا. خلال هذا الوقت ، قد يتم
                حظر المبلغ المحجوز: لا يمكن استخدامه في عمليات شراء أخرى.
                <br><br>
                عند شراء عدة عناصر في معاملة واحدة ، تحتفظ بعض البنوك بشكل خاطئ بضعف المبلغ: مرة للمبلغ الإجمالي ومرة
                واحدة لكل عنصر على حدة. نتيجة لذلك ، فإنه يقلل من المبلغ المتاح لديك لعمليات الشراء الأخرى. نأسف للإزعاج
                ، لكن هذا الإجراء خارج عن سيطرة Arab Pro Tools. إذا كنت ترغب في ذلك ، يمكنك الاتصال بالمصرف الذي تتعامل
                معه لطلب الإفراج عن المبلغ المحجوز.
            </p>
        </div>
        <div class="mb-2"></div>
    </div>
</div>