<div class="header-search header-search-extended header-search-visible d-none d-lg-block">
    <a href="#" class="search-toggle" role="button">
        <i class="icon-search"></i>
    </a>
    <form action="#" method="get" (click)="showSearchForm($event)" (submit)="submitSearchForm($event)">
        <div class="header-search-wrapper search-wrapper-wide">
            <label class="sr-only">{{'searchPlaceholder' | translate}}</label>
            <button class="btn btn-primary" type="submit">
                <span class="sr-only">{{'searchPlaceholder' | translate}}</span>
                <i class="icon-search"></i>
            </button>
            <input type="text" class="form-control" name="searchTerm" id="searchTerm"
                placeholder="{{'searchPlaceholder' | translate}}" required (input)="searchProducts($event)" [value]="qry" />
        </div>
        <div class="live-search-list" *ngIf="searchTerm.length > 2">
            <div class="autocomplete-suggestions" *ngIf="suggestions.length > 0" (click)="goProductPage()">
                <a [href]="root.product(product.slug)" class="autocomplete-suggestion"
                    *ngFor="let product of suggestions">
                    <figure class="mb-0 mr-3" style="width: 40px; height: 40px;">
                        <molla-image [src]="product.img" alt="{{product.title_en}}" width="40" height="40" class="bg-transparent">
                        </molla-image>
                    </figure>

                    <div class="search-name" [innerHTML]="matchEmphasize(product['brand_'+selected]+ ' ' + product['title_'+selected] + ' ' + product.sku) | safeContent"></div>
                    <span class="search-price">
                        <div class="product-price mb-0" *ngIf="!product.svat || product.svat >= product.vat || product.svat==='0'">
                            {{ product.vat | currencyFormat }}</div>
                            <div class="product-price mb-0" *ngIf="product.svat && product.svat < product.vat && product.svat!=='0'">
                                <span class="new-price">{{ product.svat | currencyFormat }}</span><br>
                                <span class="old-price">{{ product.vat | currencyFormat }}</span>
                            </div>
                    </span>
                </a>
                <a href="javascript:;" class="autocomplete-suggestion" (click)="search()">
                    <div class="search-name" >{{'ViewAll' | translate}}</div>
                </a>
            </div>
        </div>
    </form>
</div>