import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  selected;
  categories = [];
  loaded = false;
  constructor(
    private lang: LanguageService,
    private auth: AuthService,
    public root: RootService
  ) { }
  ngOnInit() {
    this.selected = this.lang.selected;
    this.auth.getCategoriesWithImgs().subscribe(res => {
      this.loaded = true;
      this.categories = res;
    })
  }
  goToCategory(c) {
    console.log(c);
    this.root.category(c);
  }
}
