import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class AuthService {

	userData$ = new BehaviorSubject<any>([]);
	selected;
	constructor(
		private httpService: HttpService
	) { }
	getBundles(products, data): Observable<any> {
		return this.httpService.post('getBundlesAndGifts', { products: products, data: data });
	}
	getCategories(comapnyId: number): Observable<any> {
		if (comapnyId == null) {
			return this.httpService.post('getCategories', null);

		}
		return this.httpService.post('getCategories', { 'company_id': comapnyId });
	}
	getCategoriesWithProductsCounter(): Observable<any> {
		return this.httpService.post('getCategoriesWithProductsCounter', {});
	}
	getCategoriesMobile(comapnyId: number): Observable<any> {
		if (comapnyId == null) {
			return this.httpService.post('getCategoriesMobile', null);

		}
		return this.httpService.post('getCategoriesMobile', { 'company_id': comapnyId });
	}
	getCoupon(postData: any): Observable<any> {
		return this.httpService.post('getCoupon', postData);
	}
	walletBalance(): Observable<any> {
		return this.httpService.post(`protected/wallet`, {}, true);
	}
	getLoyalityValue(): Observable<any> {
		return this.httpService.post(`protected/loyality`, {}, true);
	}
	wallet_transactions(): Observable<any> {
		return this.httpService.post(`protected/wallet_transactions`, {}, true);
	}
	notifyUser(postData: any): Observable<any> {
		return this.httpService.post(`protected/notify`, postData, true);
	}
	getCategory(postData: any): Observable<any> {
		return this.httpService.post('getCategory', postData);
	}
	getCategoryBySlug(postData: any): Observable<any> {
		return this.httpService.post('getCategoryBySlug', postData);
	}
	getBunches(postData: any): Observable<any> {
		return this.httpService.post('getBunches', postData);
	}
	getProductBySlug(postData: any): Observable<any> {
		return this.httpService.post('getProductsBySlug', postData);
	}
	getBestSellers(postData: any): Observable<any> {
		return this.httpService.post('getBestSellers', postData);
	}
	getOnlineExclusive(postData: any): Observable<any> {
		return this.httpService.post('getOnlineExclusive', postData);
	}
	getPopularCategories(): Observable<any> {
		return this.httpService.post('getPopularCategories', null);
	}
	getNewArrivals(postData: any): Observable<any> {
		return this.httpService.post('getNewArrivals', postData);
	}
	getTopSelling(postData: any): Observable<any> {
		return this.httpService.post('getTopSelling', postData);
	}
	getLastViewed(postData: any): Observable<any> {
		return this.httpService.post('getLastViewed', postData);
	}
	getLatestProducts(postData: any): Observable<any> {
		return this.httpService.post('getLatestProducts', postData);
	}
	getProductsList(postData: any): Observable<any> {
		return this.httpService.post('getProductsList', postData);
	}
	searchPage(postData: any): Observable<any> {
		return this.httpService.post('searchPage', postData);
	}
	getProductsListCompany(postData: any): Observable<any> {
		return this.httpService.post('getProductsListCompany', postData);
	}
	getProductsListForCompanies(postData: any): Observable<any> {
		return this.httpService.post('protected/shopForCompanies', postData, true);
	}
	getPopularBrands(): Observable<any> {
		return this.httpService.post('getBrands', null);
	}
	getProduct(postData: any): Observable<any> {
		return this.httpService.post('getProduct', postData);
	}
	getProductCompany(postData: any): Observable<any> {
		return this.httpService.post('getProductCompany', postData);
	}
	getProductGifts(postData: any): Observable<any> {
		return this.httpService.post('getProductGifts', postData);
	}
	// getFooterProducts(): Observable<any> {
	// 	return this.httpService.post('getFooterProducts', null);
	// }
	getRelatedProducts(postData: any): Observable<any> {
		return this.httpService.post('getRelatedProducts', postData);
	}
	getRelatedProductsCompany(postData: any): Observable<any> {
		return this.httpService.post('getRelatedProductsCompany', postData);
	}
	getRelatedProductsKhID(postData: any): Observable<any> {
		return this.httpService.post('getRelatedProductsKhID', postData);
	}
	getAccessories(postData: any): Observable<any> {
		return this.httpService.post('getAccessories', postData);
	}
	getCartAccessories(postData: any): Observable<any> {
		return this.httpService.post('getCartAccessories', postData);
	}
	getAccessoriesCompany(postData: any): Observable<any> {
		return this.httpService.post('getAccessoriesCompany', postData);
	}
	getMakitaOffers(postData: any): Observable<any> {
		return this.httpService.post('getMakitaOffers', postData);
	}
	getPaymentMethods(postData: any): Observable<any> {
		return this.httpService.post('getPaymentMethods', postData);
	}
	getQuoteItems(): Observable<any> {
		return this.httpService.post('protected/getQuoteItems', {}, true);
	}
	saveQuoteItems(postData: any): Observable<any> {
		return this.httpService.post('protected/saveQuoteItems', postData, true);
	}
	getWishListItems(): Observable<any> {
		return this.httpService.post('protected/getWishListItems', {}, true);
	}
	saveWishListItems(postData: any): Observable<any> {
		return this.httpService.post('protected/saveWishListItems', postData, true);
	}
	getCartItems(): Observable<any> {
		return this.httpService.post('protected/getCartItems', {}, true);
	}
	saveCartItems(postData: any): Observable<any> {
		return this.httpService.post('protected/saveCartItems', postData, true);
	}
	saveCartGiftItems(postData: any): Observable<any> {
		return this.httpService.post('protected/saveCartGiftItems', postData, true);
	}
	sendCodSMS(postData: any): Observable<any> {
		return this.httpService.post('protected/codSMS', postData, true);
	}
	createOrder(postData: any): Observable<any> {
		// return this.httpService.postFile('protected/createOrderTest', postData, true);
		return this.httpService.postFile('protected/createOrder', postData, true);
	}
	createQuote(postData: any): Observable<any> {
		return this.httpService.post('protected/createQuote', postData, true);
	}
	getOrders(postData: any): Observable<any> {
		return this.httpService.post('protected/getOrders', postData, true);
	}
	getYgags(postData: any): Observable<any> {
		return this.httpService.post('protected/getYgags', postData, true);
	}
	getOrderDetails(postData: any): Observable<any> {
		return this.httpService.post('protected/getOrderDetails', postData, true);
	}
	getShippingMethod(postData: any): Observable<any> {
		return this.httpService.post('getShippingMethod', postData);
	}
	verifyOrderPayment(postData: any): Observable<any> {
		return this.httpService.post('protected/verifyOrderPayment', postData, true);
	}
	getSlides(): Observable<any> {
		return this.httpService.post('getSlides', null);
	}
	getBrand(postData: any): Observable<any> {
		return this.httpService.post('getBrand', postData);
	}
	getBrandBySlug(postData: any): Observable<any> {
		return this.httpService.post('getBrandBySlug', postData);
	}
	getCategoryByProduct(postData: any): Observable<any> {
		return this.httpService.post('getCategoryByProduct', postData);
	}
	getProductRelatedCategories(postData: any): Observable<any> {
		return this.httpService.post('getProductRelatedCategories', postData);
	}
	createError(postData: any): Observable<any> {
		return this.httpService.post('createError', postData, true);
	}
	getMaintenance(postData: any): Observable<any> {
		return this.httpService.post('getMaintenance', postData);
	}
	getStores(postData: any): Observable<any> {
		return this.httpService.post('getStores', postData);
	}
	saveSearch(postData: any): Observable<any> {
		return this.httpService.post('saveSearch', postData);
	}
	updateUserLanguage(postData: any): Observable<any> {
		return this.httpService.post('protected/updateUserLanguage', postData, true);
	}
	getFlashProducts(): Observable<any> {
		return this.httpService.post('getFlashProducts', null);
	}
	addReferrerEvent(postData: any): Observable<any> {
		return this.httpService.post('addReferrerEvent', postData);
	}
	addFacebookEvent(postData: any): Observable<any> {
		return this.httpService.post('addFacebookEvent', postData);
	}
	getProductCoupon(postData: any): Observable<any> {
		return this.httpService.post('getProductCoupon', postData);
	}
	getReviews(postData: any): Observable<any> {
		return this.httpService.post('getReviews', postData);
	}
	postReview(postData: any): Observable<any> {
		return this.httpService.post('protected/postReview', postData, true);
	}
	getCategoriesWithImgs(): Observable<any> {
		return this.httpService.post('getCategoriesWithImgs', null);
	}
	getGiftsfromProduct(postData: any): Observable<any> {
		return this.httpService.post('getGiftsfromProduct', postData);
	}
	getFeaturedProducts(postData: any): Observable<any> {
		return this.httpService.post('getFeaturedProducts', postData);
	}
	getTrendingProducts(postData: any): Observable<any> {
		return this.httpService.post('getTrendingProducts', postData);
	}
	getProductsByBlockId(postData: any): Observable<any> {
		return this.httpService.post('getProductsByBlockId', postData);
	}
	getCities(postData: any): Observable<any> {
		return this.httpService.post('getCities', postData);
	}
	warrantyFormData(postData: any): Observable<any> {
		return this.httpService.post('warrantyFormData', postData);
	}
	subscribeFormData(postData: any): Observable<any> {
		return this.httpService.post('subscribeFormData', postData);
	}
	complaintFormData(postData: any): Observable<any> {
		return this.httpService.post('complaintFormData', postData);
	}
	redeem(postData: any): Observable<any> {
		return this.httpService.post('redeem', postData);
	}
	getSuggestions(postData: any): Observable<any> {
		return this.httpService.post('search', postData);
	}
	SendContactus(postData: any): Observable<any> {
		return this.httpService.post('contactus', postData);
	}
	SendContactusNationalday(postData: any): Observable<any> {
		return this.httpService.post('SendContactusNationalday', postData);
	}
	getStoresLocation(postData: any): Observable<any> {
		return this.httpService.post('getStoresLocation', postData);
	}
	getCoupons(postData: any): Observable<any> {
		return this.httpService.post('protected/getCoupons', postData, true);
	}
	getExpressShipping(postData: any): Observable<any> {
		return this.httpService.post('getExpressShipping', postData);
	}
	test(postData: any): Observable<any> {
		return this.httpService.post('test', postData);
	}
	getOnlineExclusiveTest(postData: any): Observable<any> {
		return this.httpService.post('getOnlineExclusiveTest', postData);
	}
	ValidateResetCode(postData:any): Observable<any>{
        return this.httpService.post("reset_validate", postData)
    }
	contractsLinkLogin(postData:any): Observable<any>{
        return this.httpService.post("contractsLinkLogin", postData)
    }
	contractsCreateOrder(postData:any): Observable<any>{
        return this.httpService.post("contractsCreateOrder", postData)
    }
	returnRequest(postData: any): Observable<any> {
		return this.httpService.postFile('protected/returnRequest', postData, true);
	}
	
}
