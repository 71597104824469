<div class="dropdown cart-dropdown">
    <a href="javascript:;" (click)="gotoCart()" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" data-display="static">
        <div class="icon">
            <i class="icon-shopping-cart"></i>
            <span class="cart-count">{{ cartService.quantity$ | async }}</span>
        </div>
        <p>{{'cart'|translate}}</p>
    </a>

    <div class="dropdown-menu dropdown-menu-{{direction}}"
        [ngClass]="{'text-center': (cartService.items$ | async).length == 0}">
        <p *ngIf="(cartService.items$ | async).length == 0 else elseBlock">{{'emptyCart' | translate}}</p>

        <ng-template #elseBlock>
            <div class="dropdown-cart-products">
                <div class="product justify-content-between" *ngFor="let item of cartService.items$ | async">
                    <div class="product-cart-details">
                        <h4 class="product-title">
                            <a [href]="root.product(item.product.slug)">{{ item.product['title_'+selected] }}</a>
                        </h4>

                        <span class="cart-product-info">
                            <span class="cart-product-qty">{{ item.quantity}}</span>
                            x {{ item.product.svat ? item.product.svat: item.product.vat }}
                        </span>
                    </div>

                    <figure class="product-image-container">
                        <a [href]="root.product(item.product.slug)" class="product-image">
                            <img [src]="item.product.img" alt="product" width="50" height="50">
                        </a>
                    </figure>

                    <a href="javascript:;" class="btn-remove" title="Remove Product"
                        (click)="removeFromCart( $event, item )"><i class="icon-close"></i></a>
                </div>
            </div>
            <div class="dropdown-cart-subtotal" *ngIf="(cartService.bundleDiscount$ | async)">
                <span>{{ 'bundle_discount' | translate }}</span>
                <span class="cart-total-price" style="color: green;">{{ (cartService.bundleDiscount$ | async) |currencyFormat }}</span>
            </div>
            <div class="dropdown-cart-total">
                <span>{{'Total' | translate}}</span>
                <span class="cart-total-price">{{ cartService.subtotal$|async|currencyFormat}}</span>
            </div>

            <div class="dropdown-cart-action">
                <a [href]="root.cart()" class="btn btn-primary">{{'shoppingCart' | translate}}</a>
                <a [href]="root.checkout()" class="btn btn-outline-primary-2" *ngIf="user"><span>{{'Checkout' |
                        translate}}</span>
                    <i class="icon-long-arrow-{{direction}}"></i></a>
            </div>
        </ng-template>
    </div>
</div>