import { Component, Input} from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'aptools-vouchermodal',
  templateUrl: './vouchermodal.component.html',
  styleUrls: ['./vouchermodal.component.scss']
})
export class VouchermodalComponent {
	@Input() voucherVal: number;
	selected;
	constructor(
		private lang:LanguageService
	){
		this.selected = this.lang.selected;
	}
	closeModal() {
		let modal = document.querySelector('.vb-modal') as HTMLElement;
		if (modal)
			modal.click();
	}
	iframeLoaded(){
		// console.log('iframe loaded')
	}
}
