<main class="main">
    <molla-page-header title="{{'StoreLocator'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'StoreLocator'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <!-- <div class="container" *ngIf="!loaded; else showContent">
            <div class="row">
                <div class="col-12">
                    <div class="cart-empty-page text-center loading">
                        <div class="bounce-loader">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <ng-template #showContent> -->
        <div class="container">
            <div class="row">

                <div class="col-lg-6" *ngFor="let store of stores">
                    <div class="store">
                        <div class="row">
                            <div class="col-sm-6 col-xl-6" [innerHTML]="sanitize(store.location)"></div>
                            <div class="col-sm-6 col-xl-6">
                                <div class="store-content mt-1">
                                    <h2 class="store-title">{{store['name_'+selected]}}:</h2>
                                    <address> {{store['address_'+selected]}}</address>
                                    <a href="mailto:{{store.email}}"><i class="icon-envelope"></i> {{store.email}}</a>
                                    <div><a href="tel:{{store.mobile}} "><i class="icon-mobile-alt"></i> <span class="mobileNumber"> {{store.mobile}}</span> </a></div>
                                    <div *ngIf="store.phone"><a href="tel:{{store.phone}} "><i class="icon-phone"></i>  <span class="mobileNumber">{{store.phone}} </span></a></div>
                                    <h4 class="store-subtitle">{{'storeHours'|translate}}</h4>
                                    <div [innerHTML]="store.time"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- </ng-template> -->
    </div>
</main>