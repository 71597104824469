<div class="main">
    <nav class="breadcrumb-nav mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [href]="root.home()">{{'home' | translate}}</a>
          </li>
          <li class="breadcrumb-item">
            <a [href]="root.about()">{{'about' | translate}}</a>
          </li>
          <li class="breadcrumb-item active">{{'Online Store'| translate}}</li>
        </ol>
      </div>
    </nav>
    <!-- <div class="container"> -->
    <img class="page-header-big text-center" src="assets/images/about/online_d.webp">
    <div class="page-content pb-6 pt-6">
      <div class="container" *ngIf="selected==='en'">
        <h2 class="title">{{'Online Store'| translate}}</h2>
        <p>
          In your place, get whatever you need!! We provide a variety of professional solutions , industrial machines and equipment from all of our brands on our online store. Your online orders are implemented professionally to deliver your needed products wherever you are , as our shipping Services covers all over Saudi Arabia   . Our professional sales consultant and after sales team supports you all the way.  
          <br>Contact our team 
        </p>
        <div><a href="mailto:sales@aptools.sa"><i class="icon-envelope"></i> sales@aptools.sa</a>
        </div>
        <div>
            <a class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i
                    class="icon-whatsapp"></i> 0552822719</a>
            <a class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i>
                0552822719</a>
        </div>
      </div>
      <div class="container" *ngIf="selected==='ar'">
        <h2 class="title">{{'Online Store'| translate}}</h2>
        <p>
          وانت في مكانك، احصل على كل ما تحتاجه.!!  نقدم لك مجموعة متنوعة الحلول الأحترافية و الآلات والمعدات الصناعية المهنية من جميع علاماتنا التجارية على متجرنا عبر الإنترنت. يتم تنفيذ طلباتك عبر الإنترنت بشكل احترافي لتوصيل المنتجات التي تحتاجها أينما كنت، حيث أن الشحن لدينا يغطي جميع المواقع تقريبًا في وقت قصير. يعمل مستشاري المبيعات المحترفين لدينا وفريق ما بعد البيع على دعمك طول الطريق.
          <br>تواصل مع فريقنا 
        </p>
        <div><a href="mailto:sales@aptools.sa"><i class="icon-envelope"></i> sales@aptools.sa</a></div>
          <div>
              <a class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
              <a class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i>
                  0552822719</a>
          </div>
      </div>
    </div>
</div>