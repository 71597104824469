<div class="main">
    <nav class="breadcrumb-nav mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [href]="root.home()">{{'home' | translate}}</a>
          </li>
          <li class="breadcrumb-item">
            <a [href]="root.about()">{{'about' | translate}}</a>
          </li>
          <li class="breadcrumb-item active">{{'Retail Stores'| translate}}</li>
        </ol>
      </div>
    </nav>
    <!-- <div class="container"> -->
    <img class="page-header-big text-center" src="assets/images/about/retail_d.webp">
    <!-- <div class="page-header page-header-big text-center desktop"
              style="background-image: url('assets/images/about/arabpro_aboutus_desktop.jpg')"></div><div class="page-header page-header-big text-center mobile" style="background-image: url('assets/images/about/arabpro_aboutus_mobile.jpg')"></div> -->
    <!-- </div> -->
    <div class="page-content pb-6 pt-6">
      <div class="container" *ngIf="selected==='en'">
      <h2 class="title">{{'Retail Stores'| translate}}</h2>
        <p>
          Our retail stores are located a step away from your business, and it offers a wide range of products and services for your industrial needs. Whether you are looking for tools, machinery, parts, or accessories, you can find them all at our store. You can also consult with our expert staff, who can help you choose the best option for your project. Our retail stores is your one-stop shop for all your industrial equipment needs.
          <br>Visit us today <a [href]="root.storeLocator()"> Store Locator</a>
          <br>
        </p>
        <div><a href="mailto:retail@arabprotools.com"><i class="icon-envelope"></i>retail@arabprotools.com</a></div>
        <div><a href="tel:966553100062"><i class="icon-mobile-alt"></i> 0553100062</a></div>
      </div>
      <div class="container" *ngIf="selected==='ar'">
        <h2 class="title">{{'Retail Stores'| translate}}</h2>
        <p>
          متاجر التجزئة لدينا تقع على بُعد خطوة من عملك، لتقدم مجموعة واسعة من المنتجات والخدمات لاحتياجاتك الصناعية. سواء كنت تبحث عن أدوات، آلات، قطع غيار، أو ملحقات، يمكنك العثور عليها جميعًا في متجرنا. يمكنك أيضًا التشاور مع طاقمنا الخبير، الذي يمكنه مساعدتك في اختيار أفضل الخيارات لمشروعك. متاجر التجزئة لدينا هي محطتك الواحدة لجميع احتياجاتك من المعدات الصناعية
          قم بزيارة معارضنا <a [href]="root.storeLocator()"> البحث عن المعارض</a>
        </p>
        <div><a href="mailto:retail@arabprotools.com"><i class="icon-envelope"></i>retail@arabprotools.com</a></div>
        <div><a href="tel:966553100062"><i class="icon-mobile-alt"></i> 0553100062</a></div>
      </div>
    </div>
</div>