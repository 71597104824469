<div class="container quickView-container" style="padding-left: 0; padding-right: 0">
    <button title="Close (Esc)" type="button" class="mfp-close" (click)="closeQuickView()">×</button>

    <div class="quickView-content horizontal skeleton-body">
        <div class="row skel-pro-single skel-quickview mb-0" [ngClass]="{loaded: loaded}">
            <div class="col-lg-6 p-0 pr-lg-2 mb-2 mb-lg-0">
                <div class="skel-product-gallery"></div>
                <div class="product-lg mb-1 position-relative" *ngIf="product">
                    <span class="product-label label-out"
                        *ngIf="!product.stock || product.stock == 0">{{'outOfStock'|translate}}</span>
                    <molla-image class="d-block position-relative" [src]="product.imgs[currentIndex]"></molla-image>
                    <!-- <owl-carousel carouselClasses="product-gallery-carousel owl-full owl-nav-dark" [options]="options"
                        id="owl-quickview" #singleSlider>
                        <molla-image class="d-block position-relative" *ngFor="let item of product.imgs; let i = index;"
                            [src]="item">
                        </molla-image>
                    </owl-carousel> -->
                    <!-- <owl-carousel-o carouselClasses="product-gallery-carousel owl-full owl-nav-dark" [options]="options"
                        id="owl-quickview" #singleSlider>
                        <ng-template *ngFor="let item of product.imgs; let i = index;" carouselSlide>
                            <molla-image class="d-block position-relative" [src]="item">
                            </molla-image>
                        </ng-template>
                    </owl-carousel-o> -->
                </div>
                <div class="product-sm row px-2" id="product-image-gallery" *ngIf="product">
                    <a href="#" [ngClass]="'product-gallery-item mb-0 ' + ( i === currentIndex ? ' active' : '' )"
                        (click)="changeImage( $event, i )" *ngFor="let item of product.imgs; let i = index;">
                        <molla-image [src]="product.imgs[i]" alt="Product side" class="product-image">
                        </molla-image>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0">
                <div class="entry-summary">
                    <div class="col-md-12">
                        <div class="entry-summary1 mt-2 mt-md-0"></div>
                    </div>
                    <div class="col-md-12">
                        <div class="entry-summary2"></div>
                    </div>
                </div>

                <div class="product-details w-100 pr-lg-4" *ngIf="product">
                    <h1 class="product-title">{{ product['brand_'+selected] }} {{ product['title_'+selected] }}
                        {{product.sku}}</h1>

                    <div class="product-price" *ngIf="!product.stock || product.stock == 0 else elseBlock">
                        <span class="out-price">{{ product.vat | currencyFormat}}</span>
                    </div>

                    <ng-template #elseBlock>
                        <div class="product-price" *ngIf="!product.svat || product.svat >= product.vat else elseBlock">
                            {{ product.vat | currencyFormat}}</div>
                        <ng-template #elseBlock>
                            <div class="product-price" *ngIf="product.svat && product.svat < product.vat">
                                <span class="new-price">{{ product.svat | currencyFormat}}</span>
                                <span class="old-price">{{ product.vat | currencyFormat}}</span>
                            </div>
                        </ng-template>
                    </ng-template>

                    <div class="product-content">
                        <p>{{ product['short_'+selected] }}</p>
                    </div>
                    <div class="product-desc-content" *ngIf="product['bullets_'+selected]">
                        <ul>
                            <li *ngFor="let bullet of product['bullets_'+selected];let i = index">{{bullet}}</li>
                        </ul>
                    </div>
                    
    <div class="details-filter-row details-row-size">
        <label for="qty">{{'quantity'|translate}}:</label>
        <molla-quantity-input [max]="product.stock" [step]="product.qty" (changeQty)="onChangeQty( $event )"
            [value]="qty">
        </molla-quantity-input>
            <a href="javascript:;" class="btn-product btn-cart" *ngIf="product.stock > 0" (click)="addCart( $event )"><span>{{'addToCart'|translate}}</span></a>
            <a href="javascript:;" *ngIf="product.stock <= 0" class="btn-product btn-cart btn-disabled"><span>{{'outOfStock'|translate}}</span></a>

            <div class="details-action-wrapper">
                <a href="javascript:;" class="btn-product btn-wishlist" [class.added-to-wishlist]="isInWishlist()"
                    (click)="addToWishlist($event)">
                    <span>{{ isInWishlist() ? 'Go' : 'Add' }} to Wishlist</span>
                </a>
            </div>
    </div>

                    <div class="product-details-footer">
                        <div class="product-cat w-100 text-truncate">
                            <span>{{'category'|translate}}:</span>
                            <span *ngFor="let cat of product.categories; let i =index">
                                <a [routerLink]="['/category/'+cat.slug]" (click)="closeQuickView()"
                                    [queryParams]="{category: cat.slug}">{{ cat['title_'+selected] }}</a>{{ i <
                                    product.categories.length - 1 ? ',' : '' }}</span>
                        </div>

                        <!-- <div class="social-icons social-icons-sm">
                            <span class="social-label">Share:</span>
                            <a href="javascript:;" class="social-icon" title="Facebook"><i
                                    class="icon-facebook-f"></i></a>
                            <a href="javascript:;" class="social-icon" title="Twitter"><i class="icon-twitter"></i></a>
                            <a href="javascript:;" class="social-icon" title="Instagram"><i
                                    class="icon-instagram"></i></a>
                            <a href="javascript:;" class="social-icon" title="Pinterest"><i
                                    class="icon-pinterest"></i></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>