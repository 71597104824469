<main class="main">
    <div class="container" *ngIf="!loaded">
        <div class="row">
            <div class="col-12">
                <div class="cart-empty-page text-center loading">
                    <div class="bounce-loader">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="categories-container darkBg" *ngIf="loaded">
        <a *ngFor="let category of categories" class="category-item" [href]="root.category(category.slug)">
            <img class="category-img" [src]="category.img" alt="{{category['title_'+selected]}}">
            <h6>{{category['title_'+selected] }}</h6>
            <p>{{category.counter}} {{'Products'| translate }}</p>
        </a>
    </div>
</main>