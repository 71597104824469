<div class="product product-7 text-center">
    <figure class="product-media">
        <span class="product-label label-out" *ngIf="!product.stock || product.stock == 0">{{'outOfStock'|translate}}</span>
        <span class="product-label label-express" *ngIf="product.express && product.express > 0">{{'express'| translate}}</span>

        <a href="javascript:;" (click)="quickView($event)">
            <molla-image [src]="product.img" alt="{{product.title_en}}" class="product-image"></molla-image>
        </a>
        <div class="product-action desktop-flex" *ngIf="product.stock && product.stock > 0">
            <button class="btn-product btn-cart" (click)="addToCart($event)">
                <span>{{'addToCart' | translate}}</span>
            </button>
        </div>
        <img class="warranty" *ngIf="product.freeGoldenWarrantyID && product.freeGoldenWarrantyID > 0"
            src="../../../../../assets/images/3_months_warranty_plus.png" alt="">
    </figure>

    <div class="product-body">
        <h3 class="product-title">
            <a href="javascript:;" (click)="quickView($event)">{{ product['brand_'+selected] }} {{ product['title_'+selected]}}</a>
        </h3>
        <div class="product-card-gift" *ngIf="product.bundle && product.bundle.id">
            <img [src]="product.bundle.img" [alt]="product.bundle['title_'+selected]">
            <div class="desc">
                <h4><i class="icon-gift-1"></i> {{'freeGift'|translate}}</h4>
                <p>{{product.bundle['title_'+selected]}}</p>
            </div>
        </div>

        <div class="product-price" *ngIf="!product.stock || product.stock == 0 else elseBlock">
            <span class="out-price">{{ product.vat | currencyFormat }}</span>
        </div>

        <ng-template #elseBlock>
            <div class="product-price">{{ product.vat | currencyFormat }}</div>
        </ng-template>
    </div>
    <div class="product-card-footer">
        <button class="btn-product btn-card-cart" title="{{'addToCart' | translate}}"
        *ngIf="product.stock && product.stock > 0"
            (click)="addToCart($event)"></button>
        <button class="btn-product btn-card-quickview" title="{{'quickview'|translate}}"
            (click)="quickView($event)"></button>
    </div>
</div>