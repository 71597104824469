<div class="bg-light pt-5 pb-5">
    <div class="container trending-products">
        <div class="heading heading-flex mb-3">
            <div class="heading-left">
                <h2 class="title" contentAnim aniName="blurIn">{{header}}</h2>
            </div>

            <div class="heading-right" *ngIf="categories && categories.length>0">
                <ul class="nav nav-pills nav-border-anim justify-content-center" role="tablist" tabClick>
                    <li class="nav-item" *ngFor="let cat of categories;let i = index">
                        <a class="nav-link"
                            [ngClass]="(selectedBlock && cat.blockId == selectedBlock) || (!selectedBlock && i === 0)  ? 'active' : ''"
                            href="javascript:;" role="tab" (click)="select(cat.blockId)">{{cat['title_'+selected]}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <owl-carousel-o carouselClasses="owl-full carousel-equal-height carousel-with-shadow" [options]="sliderOption"
            *ngIf="!loaded || !products else elseBlock">
            <ng-template *ngFor="let item of [1,2,3,4,5]" carouselSlide>
                <div class="skel-pro"></div>
            </ng-template>
        </owl-carousel-o>
        <ng-template #elseBlock>
            <owl-carousel-o *ngIf="products && products.length>0"
                carouselClasses="owl-full carousel-equal-height carousel-with-shadow" [options]="sliderOption">
                <ng-template *ngFor="let product of products" carouselSlide>
                    <product-card [product]="product"></product-card>
                </ng-template>
            </owl-carousel-o>
        </ng-template>
        <!-- <ul ngbNav #nav="ngbNav" clas="just-action-sicons-sm tab-content-carousel" [destroyOnHide]="false">
            <li [ngbNavItem]="cat.slug" *ngFor="let cat of categories">
                <ng-template ngbNavContent>
                    <owl-carousel-o carouselClasses="owl-full carousel-equal-height carousel-with-shadow"
                        [options]="sliderOption" *ngIf="!loaded || !products else elseBlock">
                        <ng-template *ngFor="let item of [1,2,3,4,5]" carouselSlide>
                            <div class="skel-pro"></div>
                        </ng-template>
                    </owl-carousel-o>
                    <ng-template #elseBlock>
                        <owl-carousel-o *ngIf="products && products.length>0"
                            carouselClasses="owl-full carousel-equal-height carousel-with-shadow"
                            [options]="sliderOption">
                            <ng-template *ngFor="let product of products" carouselSlide>
                                <product-card [product]="product"></product-card>
                            </ng-template>
                        </owl-carousel-o>
                    </ng-template>
                </ng-template>
            </li>
        </ul> -->
        <!-- <div [ngbNavOutlet]="nav"></div> -->
    </div>
</div>