import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Params } from 'src/app/shared/interfaces/params';

import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
	selector: 'bunch-page',
	templateUrl: './bunch.component.html',
	styleUrls: ['./bunch.component.scss'],
})

export class BunchComponent implements OnInit {
	products = [];
	pageTitle = 'Shop';
	toggle = false;
	loaded = false;
	firstLoad = false;
	params:Params = new Params();
	total:number;
	limit:number;
	type='4cols';
	filters = [];

	constructor(
		public activeRoute: ActivatedRoute,
		public router: Router, 
		public utilsService: UtilsService, 
		public auth: AuthService
		) {
		
			this.activeRoute.params.subscribe(p => {
				this.params.bunch = p.slug;
				this.activeRoute.queryParams.subscribe(parameters => {
					if (parameters['qry']) {
						this.params.qry = parameters['qry'];
					} else {
						this.params.qry = '';
					}
					if (parameters['sort']) {
						this.params.sort = parameters['sort'];
					} else {
						this.params.sort = 'views';
					}
					if (parameters['type']) {
						this.type = parameters['type'];
					}
					if (parameters['category']) {
						this.params.slug = parameters['category'];
					}
					if (parameters['brand']) {
						this.params.filter_brand = parameters['brand'];
					}else{
						this.params.filter_brand = null;
					}
					if (parameters['filter_price']) {
						this.params.filter_price = parameters['filter_price'];
					}else{
						this.params.filter_price = null;
					}
					if (parameters['page']) {
						this.params.page = parameters['page'];
					}
					if (parameters['stock']) {
						this.params.filter_stock = parameters['stock'];
					}
					this.loaded = false;
					this.auth.getBunches(this.params).subscribe(result => {
						this.products = result.items;
						this.limit = result.limit;
						this.total = result.total;
						this.filters = result.filters;
						this.loaded = true;
						if (!this.firstLoad) {
							this.firstLoad = true;
						}
						this.utilsService.scrollToPageContent();
					})
				})
			});
	}

	ngOnInit(): void {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}
	changeType(type){
		this.type = type;
	}
	@HostListener('window: resize', ['$event'])
	onResize(event: Event) {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}

	changeOrderBy(event: any) {
		this.router.navigate([], { queryParams: { sort: event.currentTarget.value, page: 1 }, queryParamsHandling: 'merge' });
	}

	toggleSidebar() {
		if (document.querySelector('body').classList.contains('sidebar-filter-active'))
			document.querySelector('body').classList.remove('sidebar-filter-active');
		else
			document.querySelector('body').classList.add('sidebar-filter-active');
	}

	hideSidebar() {
		document.querySelector('body').classList.remove('sidebar-filter-active');
	}
}