export const environment = {
  production: true,
  demo: 'aptools',
  apiUrl: "https://api.aptools.sa/",
  version: 0.2,
  mobileWidthLimit: 800,
  domain: 'https://aptools.sa/',
  tabbyPublicKey : 'pk_test_b39e2f65-2c04-43b9-ab27-82d8626f6108',
  tamaraPublicKey : '1c1be9b4-08fd-4d86-883a-de9935a1508e',
  vat:15,
  shippingLimit:200,
  loyaltyPoints:1
};