import { Component, Input, OnInit } from "@angular/core";
import { LanguageService } from "src/app/shared/services/language.service";
import { RootService } from "src/app/shared/services/root.service";

declare var $: any;
@Component({
  selector: "category-menu-mobile-item",
  templateUrl: "./category-menu-mobile-item.component.html",
  styleUrls: ["./category-menu-mobile-item.component.scss"],
})
export class CategoryMobileMenuItem implements OnInit{
  @Input() item;
  selected: string;
  constructor(
    private lang: LanguageService,
    public root: RootService
  ) {}
  ngOnInit(): void {
    this.selected = this.lang.selected;
  }
  submenuToggle(e) {
		const parent: HTMLElement = e.target.closest('li');
		const submenu: HTMLElement = parent.querySelector('ul');

		if (parent.classList.contains('open')) {
			$(submenu).slideUp(300, function () {
				parent.classList.remove('open');
			});
		}
		else {
			$(submenu).slideDown(300, function () {
				parent.classList.add('open');
			});
		}

		e.preventDefault();
		e.stopPropagation();
	}
}
