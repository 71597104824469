import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { LanguageService } from "../services/language.service";

@Pipe({
  name: "currencyFormat",
  pure: false,
})
export class CurrencyFormatPipe implements PipeTransform {
  currencyPipe: CurrencyPipe = new CurrencyPipe(this.locale);

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private lang: LanguageService
  ) {}

  transform(
    value: any,
    currencyCode?: string,
    display?: "code" | "symbol" | "symbol-narrow" | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    currencyCode = this.lang.selected === "en" ? "SAR" : "ريال";
    display = display || 'symbol';
    digitsInfo = digitsInfo || '1.2-2';
    locale = locale;

    return this.currencyPipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      locale
    );
  }
}
