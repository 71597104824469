<main class="main">
    <div class="page-content">
        <div class="container" *ngIf="selected==='en'">
            <img src="assets/images/banners/fullwidth/follow-and-win-en.webp" class="mb-5 desktop"
                style="width:100%" alt="Follow and Win">
            <img src="assets/images/banners/fullwidth/follow-and-win-en-mobile.webp" class="mb-1 mobile" style="width:100%"
                alt="Follow and Win">
        </div>
       
        <div class="container" *ngIf="selected==='ar'">
            <img src="assets/images/banners/fullwidth/follow-and-win-ar.webp" class="mb-5 desktop"
                style="width:100%" alt="Follow and Win">
            <img src="assets/images/banners/fullwidth/follow-and-win-ar-mobile.webp" class="mb-1 mobile" style="width:100%"
                alt="Follow and Win">
        </div>
       
        <div class="container" *ngIf="selected==='en'">

            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="title mb-2">Follow and Win</h3>
                    <p>
                        This &quot;Follow and Win&quot; offer provides customers with a 10% discount on their purchases.
                        <br>To avail the discount, customers must like or follow any of our official social media pages on 
                        <br>Facebook : <a href="https://www.facebook.com/ArabProTools" target="_blank">https://www.facebook.com/ArabProTools</a>
                        <br>Instagram : <a href="https://www.instagram.com/arabprotools/" target="_blank">https://www.instagram.com/arabprotools/</a>
                        <br> Linked In : <a href="https://www.linkedin.com/company/arab-pro-tools" target="_blank">https://www.linkedin.com/company/arab-pro-tools</a> 
                        <br> Twitter : <a href="https://twitter.com/ArabProTools" target="_blank">https://twitter.com/ArabProTools</a>
                        <br> TikTok : <a href="https://www.tiktok.com/@aptools1" target="_blank">https://www.tiktok.com/@aptools1</a>

                        <br>After liking or following, customers should send a direct message to our social media account with a screenshot of their
                        <br>follow request to receive the promotional code.
                    </p>

                    <br>The promotional code will be sent to the customer via direct message.
                    <br>The discount is applicable for purchases made through our official website: https://aptools.sa/
                    <br>
                    <br>
                    <div class="social-icons justify-content-center mb-0">
                        <a class="social-icon" target="_blank" href="https://m.facebook.com/ArabProTools/">
                            <i class="icon-facebook-f"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://www.instagram.com/arabprotools/"><i
                                class="icon-instagram"></i>
                        </a>
                        <a class="social-icon desktop" target="_blank" href="https://web.whatsapp.com/send?phone=966552822719">
                            <i class="icon-whatsapp"></i>
                        </a>
                        <a class="social-icon mobile" target="_blank" href="whatsapp://send?phone=966552822719">
                            <i class="icon-whatsapp"></i>
                        </a>
                    </div>
                    <br>
                    <hr class="mt-2 mb-2 mt-md-1">

                    <br><b>Terms and Conditions:</b>

                    <br>• The maximum discount is 10% of the total purchase value, with a maximum of 100 Riyals.
                    <br>• This offer is valid for a limited time and is subject to change without prior notice.
                    <br>• The discount cannot be combined with other existing offers or discounts.
                    <br>• The promotional code is for one-time use only.
                    <br>• By participating in this offer, customers agree to comply with the specified terms and conditions.

                </div>
            </div>
            
        </div>
        <div class="container" *ngIf="selected==='ar'">

            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="title mb-2">تابع واربح</h3>
                        <br>يقدم هذا العرض &quot;تابع واربح&quot; خصمًا بنسبة 10٪ على مشتريات العملاء.
                        <br> للحصول على الخصم، يجب على العملاء الإعجاب أو متابعة احدي صفحاتنا الرسمية على وسائل التواصل الاجتماعي
                        <br>فيس بوك : <a href="https://www.facebook.com/ArabProTools" target="_blank">https://www.facebook.com/ArabProTools</a>
                        <br>إنستغرام : <a href="https://www.instagram.com/arabprotools/" target="_blank">https://www.instagram.com/arabprotools/</a>
                        <br> لينكد إن : <a href="https://www.linkedin.com/company/arab-pro-tools" target="_blank">https://www.linkedin.com/company/arab-pro-tools</a> 
                        <br> تويتر : <a href="https://twitter.com/ArabProTools" target="_blank">https://twitter.com/ArabProTools</a>
                        <br> تيك توك : <a href="https://www.tiktok.com/@aptools1" target="_blank">https://www.tiktok.com/@aptools1</a>
                        <br><br>بعد الإعجاب أو المتابعة، يجب على العملاء إرسال رسالة مباشرة إلى حساب وسائل التواصل الاجتماعي
                        <br>الخاص بنا بصورة من المتابعة لطلب الرمز الترويجي

                    <br>سيتم إرسال رمز الترويج إلى العميل عبر رسالة مباشرة.
                    <br>يسري الخصم على المشتريات التي تتم من خلال موقعنا الرسمي على الإنترنت https://aptools.sa/
                    <br>
                    <br>
                    <div class="social-icons justify-content-center mb-0">
                        <a class="social-icon" target="_blank" href="https://m.facebook.com/ArabProTools/">
                            <i class="icon-facebook-f"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://www.instagram.com/arabprotools/"><i
                                class="icon-instagram"></i>
                        </a>
                        <a class="social-icon desktop" target="_blank" href="https://web.whatsapp.com/send?phone=966552822719">
                            <i class="icon-whatsapp"></i>
                        </a>
                        <a class="social-icon mobile" target="_blank" href="whatsapp://send?phone=966552822719">
                            <i class="icon-whatsapp"></i>
                        </a>
                    </div>
                    <!-- <hr class="mt-2 mb-2 mt-md-1"> -->
                    <br><b>الشروط والأحكام:</b>
                    <br>• الحد الأقصى للخصم هو 10٪ من إجمالي قيمة الشراء، وبحد أقصى 100 ريال.
                    <br>• يعتبر هذا العرض صالحًا لفترة محدودة وقد يخضع للتغيير دون إشعار مسبق.
                    <br>• لا يمكن دمج الخصم مع عروض أو تخفيضات أخرى قائمة.
                    <br>• يُستخدم رمز الترويج مرة واحدة فقط.
                    <br>• من خلال المشاركة في هذا العرض، يوافق العملاء على الامتثال للشروط والأحكام المحددة.

                </div>
            </div>
        </div>
    </div>
</main>