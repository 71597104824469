export const META_INFO = {
  "/en": {
    title: "APT Store | Hardware and Tools",
    desc:
      "APT Store is your main source of tools including hand, power, gardening ,welding tools and much more. Buy online at Saudi!",
  },
  "/ar": {
    title: "متجر عرب برو | بيع العدد و الادوات",
    desc:
      "متجر عرب برو المصدر الرئيسي  للادوات و المعدات و التجهيزات الصناعية بالسعودية ",
  },
  "/en/about-us": {
    title: "Learn More about APT Store!",
    desc: "APT Store is the Main source of tools in Saudi Arabia",
  },
  "/ar/about-us": {
    title: "تعرف أكثر على متجر عرب برو الالكتروني",
    desc:
      "متجر عرب برو الالكتروني المصدر الرئيسي للعدد في المملكة العربية السعودية",
  },
  "/en/shop/cart": {
    title: "Add to your shopping cart now !",
    desc:
      "Shop with us hot offer, free delivery above SAR 500 , high quality product and secure payment",
  },
  "/ar/shop/cart": {
    title: "أضف الى عربة التسوق افضل المنتجات",
    desc:
      "تسوق معنا و تعرف على العروض الساخنة ، شحن مجاني لمبيعات فوق ال 500 ريال و منتجات عالية الجودة و دفع آمن .",
  },
  "/en/shop/checkout": {
    title: "Secured Payment ",
    desc:
      "Choose a payment method on APT Store website to make a purchase. We have variety of options to choose from, customers can pay from mada,mastercard and more",
  },
  "/ar/shop/checkout": {
    title: "دفع آمن ",
    desc:
      "اختر طريقة الدفع المناسبة و الآمنة لك بمتجر عرب برو الالكتروني حيث متوفر جميع الخيارات المناسبة لكل عميل ان كان عن طريق مدى او ماستركارد او اكثر ",
  },
  "/en/compare": {
    title: "Comaprison page ",
    desc:
      "Comparison page is having important features and comparable specifications that each users need ",
  },
  "/ar/compare": {
    title: "صفحة المقارنة",
    desc:
      "صفحة المقارنة حيث تحتوي على مواصفات مهمة و مزايا قابلة للمقارنة كما يحتاجها كل مستخدم",
  },
  "/en/contact-us": {
    title: "Customer Service",
    desc:
      "Do not hesitate to contact us through: support@aptools.sa, we are more than happy to assist you.",
  },
  "/ar/contact-us": {
    title: "خدمة العملاء",
    desc:
      "لاتتردد في التواصل معنا عبر الايميل :support@aptools.sa او عبر الواتزاب :0552822719",
  },
  "/en/faqs": {
    title: "FAQ, APT Store, Saudi Arabia, Read more for further assistance!",
    desc:
      "FAQ ,Should you need customer service assistance, browse our FAQ answers to find helpful product , service information, and save time!",
  },
  "/ar/faqs": {
    title: "صفحة الأسئلة الشائعة ، تصفح لمعرفة المزيد، السعودية",
    desc:
      "الرجاء تصفح صفحة الأسئلة الشائعة للعثور على المعلومات المطلوبة التي تفيدك وتوفر الوقت ",
  },
  "/en/account/dashboard": {
    title: "APT Store,Main pages, Saudi Arabia",
    desc:
      "APT Store is your Main source for tools and equipment including power tools, gardening tools, hand tools, generators and much more at Saudi Arabia",
  },
  "/ar/account/dashboard": {
    title: "متجر عرب برو الالكتروني، الصفحة الرئيسية، السعودية ",
    desc:
      "متجر عرب برو الالكتروني المصدر الرئيسي للمعدات الصناعية في السعودية و الوكيل للعلامة التجارية ماكيتا المتميزة وللعديد من الماركات العالمية !",
  },
  "/en/payment-methods": {
    title: "APT Store payment methods, Order now and pay online at Saudi!",
    desc:
      "APT Store payment methods, Order now and pay online at Saudi, choose payment method, we have variety of options to choose!",
  },
  "/ar/payment-methods": {
    title:
      "طرق الدفع بمتجر عرب برو الالكتروني، اطلبها الآن و ادفع الكترونيا في السعودية!",
    desc:
      "اختر احدى طرق الدفع الآمنة بمتجر عرب برو الالكتروني حيث تتوفر العديد من الطرق المناسبة لك ، اطلبها الآن في السعودية!",
  },
  "/en/pricing-policy": {
    title: "Pricing Policy, APT Store, Saudi Arabia, Read more!",
    desc:
      "Pricing Policy,APT Store,Saudi Arabia,has the right to update the products prices whenever it is necessary, prices including VAT",
  },
  "/ar/pricing-policy": {
    title: "سياسة التسعير في متجر عرب برو الالكتروني، اقرأ اكثر!",
    desc:
      "الرجاء قراءة سياسة التسعير بمتجر عرب برو الالكتروني حيث يحق للمتجر تحديث الاسعار متى مااحتاج الامر بالاضافة الى اضافة الضريبة ",
  },
  "/en/privacy-policy": {
    title: "Privacy-policy at APT Store, Learn More!",
    desc:
      "Please read the privacy policy carefully, By accessing the services provided by aptools.sa, you agree to the collection and usage of your data via aptools.sa",
  },
  "/ar/privacy-policy": {
    title: "سياسة الخصوصية في متجر عرب برو الالكتروني، تعرف اكثر !",
    desc:
      "يرجى قراءة سياسة الخصوصية بعناية. إن أستعمالك الموقع وللخدمات المتوفرة به او قبول سياسة الخصوصية أثناء التسجيل هو موافقه منك على جمع واستخدام بياناتك الخاصه بالطريقة الموضحه",
  },
  "/en/refund-policy": {
    title: "Return Policy, APT Store, Saudi Arabia,Order now!",
    desc:
      "Return Policy, APT Store, Saudi Arabia,Customer satisfaction is our goal,please contact our support team through support@aptools.sa",
  },
  "/ar/refund-policy": {
    title:
      "سياسة الاسترجاع بمتجر عرب برو الالكتروني في السعودية، اطلبها الآن !",
    desc:
      "غايتنا وهدفنا الاول في متجر عرب برو الالكتروني هو ارضاء العميل في حال الحاجة الى المساعدة والدعم الرجاء التواصل معنا عبر support@aptools.sa ",
  },
  "/en/quote": {
    title: "Request for Qoutation at APT Store,Online, Saudi Arabia!",
    desc:
      "Request for Qoutation is avaliable at APT Store and we can assist and support through support@aptools.sa, we are more than happy to help!",
  },
  "/ar/quote": {
    title: "طلبات عروض الأسعار في متجر عرب برو الالكتروني!",
    desc:
      "طلبات تحديد التسعيرة متوفر عبر متجر عرب برو الالكتروني و بامكانكم ايضا التواصل عبر يسعدنا خدمتكم و دعمكم ! support@aptools.sa",
  },
  "/en/return-policy": {
    title: "Return Policy, APT Store, Read more and Order now!",
    desc:
      "Return Policy, APT Store, Read more and Order now! Products must be returned within seven days from the date of receipt",
  },
  "/ar/return-policy": {
    title: "سياسة الاسترجاع في متجر عرب برو الالكتروني ، اطلبه الآن ",
    desc:
      "الرجاء قراءة سياسة الارجاع حيث الارجاع لايتم الافي غضون سبعة ايام من تاريخ الاستلام ",
  },
  "/en/cart-gift": {
    title: "Gifts Festival!",
    desc: "Gifts Festival",
  },
  "/ar/cart-gift": {
    title: "مهرجان الهدايا",
    desc: "مهرجان الهدايا",
  },
  "/en/shipping-policy": {
    title:
      "Shipping Policy, APT Store, Saudi Arabia, Read more and Order now!",
    desc:
      "Shipping Policy, APT Store, Saudi Arabia! all orders are proceed within 3-7 business days,not shipped or delivered on weekends or holidays",
  },
  "/ar/shipping-policy": {
    title: "سياسة الشحن بمتجر عرب برو الالكتروني، السعودية، اطلب الآن!",
    desc:
      "سياسة الشحن بمتجر عرب برو الالكتروني، السعودية ، يستغرق تنفيذ الطلبات من 3-7 ايام عمل و لايتم الشحن او التوصيل بنهاية الاسبوع",
  },
  "/en/shop": {
    title: "Shop ,Get the best industrial tools online at Saudi Arabia,",
    desc:
      "Shop the biggest range of industrial tools and variety of top brands online now, Get the best tools in Saudi Arabia online at APT Store.",
  },
  "/ar/shop": {
    title:
      "تسوق الآن، تعرف على أفضل الادوات الصناعية المتاحة اونلاين في السعودية !",
    desc:
      "تسوق الآن عبر متجر عرب برو الالكتروني حيث يتوفر أكبر مجموعة من الأدوات الصناعية ومجموعة متنوعة من أفضل العلامات التجارية، واحصل على أفضل الأدوات في المملكة العربية السعودية !",
  },
  "/en/store-directory": {
    title: "Store-directory at APT Store!",
    desc: "Disover your favorite brands at APT Store and buy now!",
  },
  "/ar/store-directory": {
    title: "دليل متجر عرب برو الالكتروني !",
    desc:
      "اطلع على الماركات المفضلة لديك من العدد الصناعية في متجر عرب برو الالكتروني! ",
  },
  "/en/store-locator": {
    title: "Store Locator, APT Store, Saudi Arabia, Buy now!",
    desc:
      "Store Locator,find APT Store near you and start shopping for tools. We offer high qulaity products for professional traders at Saudi!",
  },
  "/ar/store-locator": {
    title: "فروعنا في أرجاء المملكة العربية السعودية، تسوق الآن!",
    desc:
      "ابحث عن اقرب فرع لمعارضنا المنتشرة في أنحاء المملكة العربية السعودية حيث لديها العديد من المعدات العالية الجودة و المجهزة للمحترفين و المبتدئين !",
  },
  "/en/terms": {
    title: "Terms and Conditions - APT Store - Saudi Arabia",
    desc:
      "Terms and Conditions-APT Store -Saudi Arabia! please take a moment to read the terms and conditions carefully before purchasing.",
  },
  "/ar/terms": {
    title: "الأحكام و الشروط بمتجر عرب برو الالكتروني السعودية!",
    desc:
      "الرجاء قراءة الأحكام و الشروط بعناية قبل البدء بالشراء بمتجر عرب برو الالكتروني في السعودية !",
  },
  "/en/wishlist": {
    title: "Wishlist page at APT Store,Add now!",
    desc:
      "Add your favorite tools in the wishlist and get more chance to win with us ",
  },
  "/ar/wishlist": {
    title: "قائمتك المفضلة بمتجر عرب برو الالكتروني ، اضفها الآن!",
    desc:
      "قم باضافة معداتك المفضلة بالقائمة لربما هذه الاضافة تمنحك الفرصة بالفوز معنا !",
  },
};
