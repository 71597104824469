// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CompareState } from '../reducers/compare.reducer';
import { WishlistState } from '../reducers/wishlist.reducer';

export const getCompareState = createFeatureSelector<CompareState>('compare');
export const getWishlistState = createFeatureSelector<WishlistState>('wishlist');

/************************    Wishlist Selectors   ***********************/
export const wishlistSelector = createSelector(
    getWishlistState, wishlistState => {
        return wishlistState.data;
    }
);

/************************    Compare Selectors   ***********************/
export const compareSelector = createSelector(
    getCompareState, compareState => {
        return compareState.data;
    }
);