<div class="main">
  <nav class="breadcrumb-nav mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [href]="root.home()">{{'home' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <a [href]="root.about()">{{'about' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'Projects'| translate}}</li>
      </ol>
    </div>
  </nav>
  <!-- <div class="container"> -->
  <img class="page-header-big text-center" src="assets/images/about/projects_d.webp">
  <div class="page-content pb-6 pt-6">
    <div class="container" *ngIf="selected==='en'">
      <h2 class="title">{{'Projects'| translate}}</h2>
      <p>
        Our Projects department is responsible for designing, developing, and delivering customized solutions for our clients in various sectors, such as for example manufacture, construction , agriculture . Our diverse and talented team of engineers, technicians, and consultants collaborate to meet the specific needs and expectations of each project.
        <br><br>
        We are proud of the work that we do and the value that we create for our clients. We are always looking for new opportunities and challenges to apply our skills and expertise. Your success add to our success.
        <br><br>
        Interested in learning more about our projects department or working with us?  please contact us
      </p>
      <div><a href="mailto:projects@arabprotools.com"><i class="icon-envelope"></i>
        projects@arabprotools.com</a></div>
<div> <br> </div>
    </div>
    <div class="container" *ngIf="selected==='ar'">
      <h2 class="title">{{'Projects'| translate}} </h2>
      <p>
تتولى إدارة المشاريع لدينا مسؤولية تصميم وتطوير وتقديم حلول مخصصة لعملائنا في قطاعات مختلفة، مثل التصنيع والبناء والزراعة. لدينا فريق متنوع وموهوب من المهندسين والفنيين والمستشارين الذين يتعاونون لتلبية الاحتياجات والتوقعات الخاصة بكل مشروع.        
<br><br>
نحن فخورون بالعمل الذي نقوم به والقيمة التي نخلقها لعملائنا. نحن دائمًا نبحث عن فرص جديدة وتحديات لتطبيق مهاراتنا وخبراتنا. إذا كنت مهتمًا بمعرفة المزيد عن إدارة المشاريع لدينا أو العمل معنا تواصل معنا الآن

      </p>
      <div><a href="mailto:projects@arabprotools.com"><i class="icon-envelope"></i>
        projects@arabprotools.com</a></div>
    </div>
  </div>
</div>