<div class="products mb-3">
    <div class="row" *ngIf="!loaded else elseBlock">
        <div [class]="'skel-pro ' + grid[type]" *ngFor="let item of fakeArray[type]"></div>
    </div>
    <ng-template #elseBlock>
        <div class="row">
            <div [class]="grid[type]" class="justify-content-center" *ngFor="let product of products">
                <product-card-contract [product]="product" class="w-100"></product-card-contract>
            </div>
        </div>
    </ng-template>
</div>