<div class="main">
    <molla-page-header title="{{'error404'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'error404'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3">
        <div class="container" align="center">
            <br>
            <h2>{{'PageNotFound' | translate}}</h2>
            <br>
            <a class="btn btn-secondary btn-sm" [href]="root.home()">{{'GoToHomePage' | translate}}</a>
            <br>
            <br>
            <div class="contact-box text-center">
                <h3>{{'contactCustomerSupport'|translate}}</h3>

                <div><a target="_blank" href="mailto:support@aptools.sa"><i class="icon-envelope"></i> support@aptools.sa</a></div>
                <div>
                    <a target="_blank" class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                    <a target="_blank" class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i> 0552822719</a>
                </div>
            </div>
        </div>
        <div class="mb-2"></div>
    </div>

</div>