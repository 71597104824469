import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem } from 'src/app/shared/interfaces/cart-item';
import { CartService } from 'src/app/shared/services/cart.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
	selector: 'molla-cart-menu',
	templateUrl: './cart-menu.component.html',
	styleUrls: ['./cart-menu.component.scss']
})

export class CartMenuComponent implements OnInit {

	selected;
	direction;
	removedItems: CartItem[] = [];
	user;
	// bundle_discount = 0;
	constructor(
		public cartService: CartService, 
		public root: RootService,
		private lang: LanguageService,
		private session: SessionService,
		private router:Router
	) {
	}

	ngOnInit(): void {
		this.user = this.session.getUser();
		this.selected = this.lang.selected;
		this.direction = this.selected === 'ar' ? 'left' : 'right';
		// this.cartService.onCartReady.subscribe((isReady) => {
		//   if (isReady) {
		// 	this.cartService.totals$.subscribe(totals=>{
		// 		let obj = totals.find(obj=> obj['title'] === 'bundle_discount');
		// 		this.bundle_discount = obj ? obj.price : 0;
		// 		console.log(this.bundle_discount);
		// 	});
		//   }
		// });
		
	}

	removeFromCart(event: Event, item: any) {
		this.cartService.remove(item).subscribe({ complete: () => {
			// this.removedItems = this.removedItems.filter(eachItem => eachItem !== item);
		} });
	}
	gotoCart(){
		this.router.navigate([this.root.cart()]).then(()=>{
			window.location.reload();
		})
	}
}