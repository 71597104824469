<div class="main">
    <nav class="breadcrumb-nav mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [href]="root.home()">{{'home' | translate}}</a>
          </li>
          <li class="breadcrumb-item">
            <a [href]="root.about()">{{'about' | translate}}</a>
          </li>
          <li class="breadcrumb-item active">{{'Dealers'| translate}}</li>
        </ol>
      </div>
    </nav>
    <!-- <div class="container"> -->
    <img class="page-header-big text-center" src="assets/images/about/wholesale_d.webp">
    <div class="page-content pb-6 pt-6">
      <div class="container" *ngIf="selected==='en'">
        <h2 class="title">Wholesales</h2>
        <p>
          Our wholesales department is the best B2B partner to you . Our team of experienced and knowledgeable sales representatives can help you find the best solutions for your needs.
<br><br>
We offer a wide range of industrial equipment, such as Power tools, forklifts, cranes, generators, compressors, pumps, and more. We also provide after-sales service, maintenance, and repair for our products. We work with reputable brands and suppliers to ensure the quality and reliability of our equipment.
<br><br>
If you are interested in buying our industrial equipment, please contact us today. We will be happy to assist you and answer any questions you may have.
        </p>
        <div><a href="mailto:dealer@arabprotools.com"><i class="icon-envelope"></i>dealer@arabprotools.com</a></div>
        <div><a href="tel:966553100062 "><i class="icon-mobile-alt"></i> 0553100062 </a></div>
      </div>
      <div class="container" *ngIf="selected==='ar'">
        <h2 class="title">البيع بالجملة</h2>
        <p>
          تعتبر إدارة البيع بالجملة لدينا أفضل شريك تجاري لك. لدينا فريق من ممثلي المبيعات ذوي الخبرة والمعرفة الذين يمكنهم مساعدتك في العثور على أفضل الحلول لاحتياجاتك.
          <br><br>
          نحن نقدم مجموعة واسعة من المعدات الصناعية، مثل الأدوات والمعدات، رافعات شوكية، اوناش، مولدات، ضواغط، مضخات، وأكثر. كما نقدم خدمات الصيانة والضمان المجانية والشاملة للمنتجات، جميع منتجاتنا هي من العلامات التجارية المعروفة والعالية القدرة والكفاءة والموثوقية.
          <br><br>
          إذا كنت مهتمًا بشراء معداتنا الصناعية، يرجى الاتصال بنا اليوم. سنكون سعداء لمساعدتك والإجابة على أي أسئلة قد تكون لديك. رقم الاتصال للبائعين بالجملة
        </p>
        <div><a href="mailto:dealer@arabprotools.com"><i class="icon-envelope"></i>dealer@arabprotools.com</a></div>
        <div><a href="tel:966553100062 "><i class="icon-mobile-alt"></i> 0553100062 </a></div>
      </div>
    </div>
</div>