<div class="main">
    <molla-page-header title="{{'pricingPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'pricingPolicy'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <p>
                At aptools.sa, our commitment to our customers is to offer convenience, service and product availability
                online at competitive prices. We also offer merchandise at promotional prices.
                <br><br>
                aptools.sa operates independently from the Arab Pro store. Because they are separate companies, the
                products, prices and promotions offered online and in Arab Pro store may differ.
                <br><br>
                We may offer merchandise on-line at the same price at which it is offered in Arab Pro store, and our
                promotions may follow those that you’ll find in the Arab Pro store. However, in some cases, the prices
                will be different because the website and store may choose to offer items at different prices or may run
                different promotional events at different times. Additionally, Arab Pro.com may offer a product, price
                or promotion that is not available in the store.
                <br><br>
                For this reason, prices and other promotional offers (such as coupons and additional discounts) offered
                at aptools.sa may not be applied toward purchases from Arab Pro store. Similarly, prices and other
                promotional offers (such as promotion codes and free shipping) from Arab Pro stores may not be applied
                toward purchases from aptools.sa
            </p>

            <h6>Prices and terms of sale</h6>
            <p>
                Arab Pro Company has the right to update the products prices whenever it is necessary and this will
                affect the products displayed in the site, the product in the shopping cart and all unconfirmed
                products.
                <br><br>
                Existing prices include VAT
                <br><br>
                We hope this clarifies our pricing policy. If you have any further questions, please send us an email.
                Our goal is to provide you with full service and complete satisfaction, and we look forward to serving
                you.
            </p>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            <p>
                في aptools.sa ، التزامنا تجاه عملائنا هو توفير الراحة والخدمة وتوافر المنتجات عبر الإنترنت بأسعار
                تنافسية. كما نقدم البضائع بأسعار ترويجية.
                <br><br>
                يعمل موقع aptools.sa بشكل مستقل عن متجر عرب برو. نظرًا لأنهما شركتان منفصلتان ، فقد تختلف المنتجات
                والأسعار والعروض الترويجية المقدمة عبر الإنترنت وفي متجر Arab Pro.
                <br><br>
                قد نعرض البضائع عبر الإنترنت بنفس السعر الذي يتم تقديمها به في متجر Arab Pro ، وقد تتبع عروضنا الترويجية
                تلك التي ستجدها في متجر Arab Pro. ومع ذلك ، في بعض الحالات ، ستكون الأسعار مختلفة لأن الموقع الإلكتروني
                والمتجر قد يختاران عرض العناصر بأسعار مختلفة أو قد ينظمان أحداثًا ترويجية مختلفة في أوقات مختلفة.
                بالإضافة إلى ذلك ، قد يعرض موقع Arab Pro.com منتجًا أو سعرًا أو عرضًا ترويجيًا غير متوفر في المتجر.
                <br><br>
                لهذا السبب ، لا يجوز تطبيق الأسعار والعروض الترويجية الأخرى (مثل القسائم والخصومات الإضافية) المعروضة
                على موقع aptools.sa على عمليات الشراء من متجر Arab Pro. وبالمثل ، قد لا يتم تطبيق الأسعار والعروض
                الترويجية الأخرى (مثل الرموز الترويجية والشحن المجاني) من متاجر Arab Pro على عمليات الشراء من aptools.sa
            </p>

            <h6>الاسعار وشروط البيع </h6>
            <p>
                تقوم الشركة من وقت لآخر بمراجعة اسعار المنتجات وتحديثها على الموقع الإلكتروني وذلك دون إشعار مسبق.
                ويتأثر بتغير الأسعار كلا من المنتجات على المنصه ,المنتجات بعربه التسوق والطلبيات الغير مؤكده.
                <br><br>
                الأسعار المعروضه تشمل ضريبة القيمة المضافة
                <br><br>
                نأمل أن يوضح هذا سياستنا. إذا كانت لديك أي أسئلة أخرى ، فيرجى مراسلتنا عبر البريد الإلكتروني. هدفنا هو
                أن نقدم لك خدمة كاملة ورضا كامل ، ونتطلع إلى خدمتك.
            </p>
        </div>
        <div class="mb-2"></div>
    </div>
</div>