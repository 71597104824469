import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Bundle } from '../interfaces/bundle';
import { CartItem, Cart_gifts, GoldenWarranty, ProductGift } from '../interfaces/cart-item';
import { Coupon } from '../interfaces/coupon';
import { Product } from '../interfaces/product';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { SessionService } from './session.service';
import { ToastrService } from 'ngx-toastr';
import { Facebook } from '../interfaces/facebook';
import { CookieService } from './cookie.service';
import { environment } from 'src/environments/environment';

interface CartTotal {
	title: string;
	price: number;
	type: 'shipping' | 'fee' | 'tax' | 'other';
}

interface CartData {
	items: CartItem[];
	quantity: number;
	subtotal: number;
	weight: number;
	delivery?: number;
	totals: CartTotal[];
	total: number;
	codFee: number;
	from_wallet: number;
	from_loyality: number;
	coupon: Coupon;
	collect_from_store: boolean;
	oda?: number;
	productsOda?: number;
	vat: number;
	bundles?: Bundle;
	isStandardShipping?: boolean;
	cart_gift: Cart_gifts;
	products_gifts_total: number;
	products_gifts_spent: number;
	products_gifts_balance: number;
	products_gifts?: ProductGift[];
	bundleDiscount?:number;
}

@Injectable({
	providedIn: 'root'
})
export class CartService {
	private data: CartData = {
		items: [],
		quantity: 0,
		weight: 0,
		delivery: 0,
		subtotal: 0,
		totals: [],
		total: 0,
		codFee: 0,
		from_wallet: 0,
		from_loyality: 0,
		bundles: null,
		coupon: null,
		collect_from_store: false,
		isStandardShipping: false,
		oda: 0,
		productsOda: 0,
		vat: 0,
		products_gifts_total: 0,
		products_gifts_spent: 0,
		products_gifts_balance: 0,
		products_gifts: [],
		cart_gift: null,
		bundleDiscount:0
	};
	shippingLimit = environment.shippingLimit;
	wallet_balance: number;
	use_wallet: boolean = true;
	loyality_balance: number;
	use_loyality: boolean = true;
	private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
	private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
	private weightSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.weight);
	private deliverySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.delivery);
	private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
	private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
	private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
	private bundleDiscountSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.bundleDiscount);
	private vatSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.vat);
	private codFeeSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.codFee);
	private odaSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.oda);
	private productsOdaSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.productsOda);
	private onAddingSubject$: Subject<Product> = new Subject();
	wallet_balance$: BehaviorSubject<number> = new BehaviorSubject(0);
	fully_from_wallet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	loyality_balance$: BehaviorSubject<number> = new BehaviorSubject(0);
	fully_from_loyality$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	cart_gift$: BehaviorSubject<Cart_gifts> = new BehaviorSubject(this.data.cart_gift);
	onCartReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private products_gifts_totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.products_gifts_total);
	private products_gifts_spentSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.products_gifts_spent);
	private products_gifts_balanceSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.products_gifts_balance);
	private products_giftsSubject$: BehaviorSubject<ProductGift[]> = new BehaviorSubject(this.data.products_gifts);
	private saveEvent = new EventEmitter();
	private saveEventGift = new EventEmitter();

	get items(): ReadonlyArray<CartItem> {
		return this.data.items;
	}

	get quantity(): number {
		return this.data.quantity;
	}
	get weight(): number {
		return this.data.weight;
	}
	get delivery(): number {
		return this.data.delivery;
	}
	get from_wallet(): number {
		return this.data.from_wallet;
	}
	get from_loyality(): number {
		return this.data.from_loyality;
	}
	get subtotal(): number {
		return this.data.subtotal;
	}
	get total(): number {
		return this.data.total;
	}
	get bundleDiscount(): number {
		return this.data.bundleDiscount;
	}
	get coupon_code(): string {
		if (this.data.coupon != null) {
			return this.data.coupon.code;
		}
		return null;
	}
	get productsOda(): number {
		return this.data.productsOda;
	}
	get products_gifts_spent(): number {
		return this.data.products_gifts_spent;
	}
	get products_gifts_total(): number {
		return this.data.products_gifts_total;
	}
	get products_gifts_balance(): number {
		return this.data.products_gifts_balance;
	}
	get cart_gift(): Cart_gifts {
		return this.data.cart_gift;
	}

	get products_gifts(): ReadonlyArray<ProductGift> {
		return this.data.products_gifts;
	}
	readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
	readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
	readonly weight$: Observable<number> = this.weightSubject$.asObservable();
	readonly delivery$: Observable<number> = this.deliverySubject$.asObservable();
	readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
	readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
	readonly total$: Observable<number> = this.totalSubject$.asObservable();
	readonly bundleDiscount$: Observable<number> = this.bundleDiscountSubject$.asObservable();
	readonly vat$: Observable<number> = this.vatSubject$.asObservable();
	readonly codFee$: Observable<number> = this.codFeeSubject$.asObservable();
	readonly oda$: Observable<number> = this.odaSubject$.asObservable();
	readonly productsOda$: Observable<number> = this.productsOdaSubject$.asObservable();
	readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

	readonly products_gifts$: Observable<ProductGift[]> = this.products_giftsSubject$.asObservable();
	readonly products_gifts_total$: Observable<number> = this.products_gifts_totalSubject$.asObservable();
	readonly products_gifts_spent$: Observable<number> = this.products_gifts_spentSubject$.asObservable();
	readonly products_gifts_balance$: Observable<number> = this.products_gifts_balanceSubject$.asObservable();

	public init() {
		if (isPlatformBrowser(this.platformId)) {
			this.load().then(() => {
				this.refreshBundles().then(() => {
					this.calc();
					this.onCartReady.next(true);
				}, err => {
					this.calc();
					this.onCartReady.next(true);
				})
			}, err => {
				this.onCartReady.next(true);
			});
		}
	}
	constructor(
		@Inject(PLATFORM_ID)
		private platformId: any,
		private authService: AuthService,
		private translate: TranslateService,
		private alert: AlertService,
		private session: SessionService,
		private toastrService:ToastrService,
		private cookie:CookieService
	) {
		this.init();
		this.saveEvent.pipe(debounceTime(1000)).subscribe((items) => {
			this.authService.saveCartItems({ items: items }).toPromise();
			// this.refreshBundles();
		});
		this.saveEventGift.pipe(debounceTime(1000)).subscribe((gifts) => {
			this.authService.saveCartGiftItems({ items: gifts }).toPromise();
		});
	}
	correctCartItems(correctItems: any) {
		return new Promise((resolve, reject) => {
			//some little validations first
			if (!Array.isArray(correctItems)) { reject(false); }
			if (correctItems.length <= 0) { reject(false); }

			let cartItems = this.data.items;
			correctItems.forEach((item, i) => {
				let index = cartItems.findIndex(({ product }) => product.id === item.id);

				//save old product before correcting items so we can show differences in checkout page
				correctItems[i].old = JSON.parse(JSON.stringify(cartItems[index])); //deep clone

				//if out of stock remove from cartItems
				if (item.stock <= 0) {
					cartItems.splice(index, 1);
					return; //continue
				}

				//if qty is greater than stock make qty the same as stock
				if (cartItems[index].quantity > item.stock) {
					cartItems[index].quantity = parseInt(item.stock);
				}
				//if product is of grouped qty
				if (item.new_groupof_qty) {
					cartItems[index].quantity = item.new_qty;
				}

				//correcting values
				cartItems[index].product.price = item.price;
				cartItems[index].product.priceAfter = item.priceAfter;
				cartItems[index].product.sale = item.sale;
				cartItems[index].product.stock = item.stock;
				cartItems[index].product.svat = item.svat;
				cartItems[index].product.vat = item.vat;
			});
			var items = JSON.stringify(cartItems);
			//update in db first then locally when successful
			this.authService.saveCartItems({ items: items }).toPromise().then(() => {
				//replace old items with new 'corrected' ones
				this.data.items = cartItems;
				localStorage.setItem('cartItems', items);
				this.calc();
				resolve(correctItems);
			}, err => reject(false));
		})
	}

	getItem(product: Product): Observable<CartItem> {
		return timer(0).pipe(map(() => {
			let item = this.items.find(eachItem => {
				if (eachItem.product.id === product.id) {
					return true;
				}
				return false;
			});
			return item;
		}));
	}
	getProductGiftItem(product: Product): Observable<number> {
		return timer(0).pipe(map(() => {
			let qnty = 0;
			this.products_gifts.forEach(gift => {
				if(gift.product.id === product.id){
					qnty += gift.quantity;
				}
			});
			return qnty;
		}));
	}
	getStock(product: Product, newQuantity = 0): Observable<Number> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			// this.onAddingSubject$.next(product);
			let item = this.items.find(eachItem => {
				if (eachItem.product.id === product.id) {
					return true;
				}
				return false;
			});
			if (newQuantity) { // add condition
				if (item) {
					return product.stock - newQuantity;
				} else {
					return product.stock - newQuantity;
				}
			} else { // stock init condition
				if (item) {
					return product.stock - item.quantity;
				} else {
					return product.stock;
				}
			}
		}));
	}
	add(product: Product, quantity: number, options: { name: string; value: string }[] = [], goldenWarranty = 0): Observable<CartItem> {
		// timer only for demo
		window.dataLayer.push({
			'event': "addToCart",
			'ecommerce': {
				'currencyCode': "SAR",
				'add': {
					// 'add' actionFieldObject measures.
					'products': [
						{
							//  adding a product to a shopping cart.
							'name': product.title_en,
							'id': product.id,
							'price': product.priceAfter * quantity,
							'brand': product.brand_en,
							'category': "",
							'variant': "",
							'quantity': quantity,
						},
					],
				},
			},
		});
		// add facebook Event Server Side
		let facebook: Facebook = {};
		facebook.fbc = this.cookie.getCookie('_fbc');
		facebook.fbp = this.cookie.getCookie('_fbp');
		facebook.user = this.session.getUser() ?? null;
		facebook.event_name = "AddToCart";
		facebook.product = product;
		this.authService.addFacebookEvent(facebook).subscribe(res => {
		});
		quantity = quantity * 1; // solve issue of quantity changed to string mysteriously 
		return timer(0).pipe(map(() => {
			let priceVatAfter = product.svat ? product.svat : product.vat;
			this.onAddingSubject$.next(product);

			let item:CartItem = this.items.find(eachItem => {
				if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
					return false;
				}

				if (eachItem.options.length) {
					for (const option of options) {
						if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
							return false;
						}
					}
				}
				return true;
			});
			let weight = product.weight ? product.weight * quantity : 0;
			let delivery = product.delivery ? product.delivery : 0;
			this.toastrService.success( this.translate.instant('Product added to Cart') );
			if (item) { // item exist in the cart 
				item.quantity = Math.round((quantity + item.quantity) * 100) / 100;
				item.weight = weight;
				item.delivery = delivery;
				let oldWarranty = item.goldenWarranty.find(eachWarranty => {
					if (eachWarranty.percentage !== goldenWarranty) {
						return false;
					}
					return true;
				});
				if (oldWarranty || goldenWarranty > 0) {
					item.goldenWarranty = [{ qty: item.quantity, percentage: goldenWarranty, price: Math.round(goldenWarranty * priceVatAfter / 100) }];
				}
			} else { // New item to the cart 
				this.updateCoupon(null);
				let golden: GoldenWarranty[] = [];
				if (goldenWarranty > 0) {
					golden.push({ qty: quantity, percentage: goldenWarranty, price: Math.round(goldenWarranty * priceVatAfter / 100) });
				}
				item = { product, quantity, options, weight, delivery, goldenWarranty: golden };
				this.data.items.push(item);
			}
			item.freeGoldenWarranty = { id: item.product.freeGoldenWarrantyID, qty: item.quantity };
			this.refreshBundles().then(() => {
				this.calc();
				this.save();
			}, err => {
				this.calc();
				this.save();
			})
			return item;
		}));
	}
	addProductGift(product: Product, mainProduct: Product, quantity: number, goldenWarranty = 0): Observable<ProductGift> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			// let priceVatAfter = product.svat ? product.svat : product.vat;
			this.onAddingSubject$.next(product);
			let gift = this.products_gifts.find(eachGift => {
				if (eachGift.product.id !== product.id) {
					return false;
				}
				return true;
			});
			let weight = product.weight ? product.weight * quantity : 0;
			let delivery = product.delivery ? product.delivery : 0;
			if (gift) { // gift exist in the cart 
				gift.quantity = Math.round((quantity + gift.quantity) * 100) / 100;
				gift.weight = weight;
				gift.delivery = delivery;
			} else { // New gift to the cart 
				// this.updateCoupon(null);
				gift = { product, quantity, weight, delivery };
				this.data.products_gifts.push(gift);
			}
			this.calc();
			this.save();
			return gift;
		}));
	}
	update(updates: { item: CartItem, quantity: number }[]): Observable<void> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			updates.forEach(update => {
				const item = this.items.find(eachItem => eachItem === update.item);
				if (item) {
					item.quantity = update.quantity;
					if (item.goldenWarranty.length > 0) {
						item.goldenWarranty[0].qty = item.quantity;
					}
					if (item.freeGoldenWarranty) {
						item.freeGoldenWarranty.qty = item.quantity;
					}
				}
			});
			this.refreshBundles().then(() => {
				this.calc();
				this.save();
			}, err => {
				this.calc();
				this.save();
			})
		}));
	}

	remove(item: CartItem): Observable<void> {
		window.dataLayer.push({
			'event': "removeFromCart",
			'ecommerce': {
				'remove': {
					// 'add' actionFieldObject measures.
					'products': [
						{
							//  adding a product to a shopping cart.
							'name': item.product.title_en,
							'id': item.product.id,
							'price': item.product.priceAfter * item.quantity,
							'brand': item.product.brand_en,
							'category': "",
							'variant': "",
							'quantity': item.quantity,
						},
					],
				},
			},
		});
		// timer only for demo
		return timer(0).pipe(map(() => {
			this.data.items = this.data.items.filter(eachItem => eachItem !== item);
			this.updateCoupon(null);
			this.refreshBundles().then(() => {
				this.calc();
				this.save();
			}, err => {
				this.calc();
				this.save();
			})
		}));
	}
	removeGift(item: CartItem): Observable<void> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			this.data.products_gifts = this.data.products_gifts.filter(eachItem => eachItem !== item);
			this.calc();
			this.save();
		}));
	}
	removeProductsGifts() {
		this.data.products_gifts = [];
		this.save();
	}
	removeWarranty(item: CartItem, warranty: GoldenWarranty): Observable<void> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			const itemIndex = this.data.items.findIndex(eachItem => eachItem === item);
			this.data.items[itemIndex].goldenWarranty = this.data.items[itemIndex].goldenWarranty.filter(eachWarranty => eachWarranty !== warranty);
			this.refreshBundles().then(() => {
				this.calc();
				this.save();
			}, err => {
				this.calc();
				this.save();
			})
		}));
	}
	empty(): Observable<void> {
		// timer only for demo
		return timer(0).pipe(map(() => {
			this.updateCoupon(null);
			this.data.items = [];
			this.delete();
			this.calc();
		}));
	}
	//call onload or everytime new item added to cart
	refreshBundles() {
		return new Promise((resolve, reject) => {
			if (this.data.items.length) {
				let products = [];
				this.data.items.forEach((item: CartItem) => {
					products.push({ id: item.product.id, qty: item.quantity });
				});
				let giftsData = { 'coupon': this.data.coupon, 'subtotal': this.data.subtotal };
				this.authService.getBundles(products, giftsData).toPromise().then(res => {
					if (!this.session.getCompany()) {
						if (res.cart_gift) {
							this.data.cart_gift = res.cart_gift;
							this.cart_gift$.next(res.cart_gift);
						} else {
							this.data.cart_gift = null;
							this.cart_gift$.next(null);
						}
					}
					this.data.bundles = res.bundles;
					resolve(true);
				}, err => {
					reject(false);
				});
			}
			else {
				this.data.bundles = null;
				resolve(true);
			}
		});
	}
	refreshBundlesOld(calc = false) {
		return new Promise((resolve, reject) => {
			if (this.data.items.length) {
				let products = [];
				this.data.items.forEach((item: CartItem) => {
					products.push({ id: item.product.id, quantity: item.quantity });
				});
				let giftsData = { 'coupon': this.data.coupon, 'subtotal': this.data.subtotal };
				this.authService.getBundles(products, giftsData).toPromise().then(bundles => {
					this.data.bundles = bundles;
					if (calc) {
						this.calc();
					}
					resolve(true);
				}, err => {
					if (calc) {
						this.calc();
					}
					reject(false);
				});
			}
			else {
				this.data.bundles = null;
				resolve(true);
			}
		});
	}
	callCalc() {
		return new Promise((resolve, reject) => {
			if (this.data.items.length) {
				this.calc();
				resolve(true);
			} else {
				resolve(false);
			}
		});
	}
	getWalletBalance() {
		return new Promise((resolve, reject) => {
			if (localStorage.currentUser) {
				this.authService.walletBalance().toPromise().then(balance => {
					this.wallet_balance = balance; //always negative !important
					this.wallet_balance$.next(balance);
					// this.calc();
					resolve(true);
				}, err => reject('server failed'));
			} else {
				reject('user not logged in');
			}
		});
	}
	getLoyalityBalance() {
		return new Promise((resolve, reject) => {
			if (localStorage.currentUser) {
				this.authService.getLoyalityValue().toPromise().then(res => {
					// let l = res / 100;
					this.loyality_balance = res;
					this.loyality_balance$.next(res);
					resolve(true);
				}, err => reject('server failed'));
			} else {
				reject('user not logged in');
			}
		});
	}
	useWalletBalance(use_wallet: boolean) {
		this.use_wallet = use_wallet;
		this.calc();
	}
	useLoyalityBalance(use_loyality: boolean) {
		this.use_loyality = use_loyality;
		// this.calc();
	}
	updateCoupon(coupon: Coupon) {
		this.data.coupon = coupon;
		if (coupon && coupon.allow_gift == 0) {
			this.data.cart_gift = null;
			this.cart_gift$.next(null);
		} else {
			this.refreshBundles();
		}
		this.calc();
		this.save();
	}
	updateCodFee(codFee) {
		this.data.codFee = codFee;
		this.calc();
		this.save();
	}
	updateShippingFee(shippingId, oda = 0) {
		this.data.collect_from_store = !shippingId;
		this.data.oda = shippingId == 4 ? 0 : oda;
		this.calc();
		this.save();
	}
	setShippingStandard(b: boolean = true) {
		this.data.isStandardShipping = b;
		this.calc();
		this.save();
	}
	private getPrice(product, qty): number {
		if (product.slaps && product.slaps.length) {
			let i = product.slaps.findIndex(x => x.min <= qty && (x.max >= qty || x.max === null));
			return product.slaps[i].aprice;
		} else {
			return product.svat ? product.svat : product.vat;
		}
	}
	applyVat(x:number){
		return x * 1.15;
	}
	private calc(): void {
		let shipping = 21.73913 * 1.15;
		let quantity:number = 0;
		let weight = 0;
		let delivery = 0;
		let subtotal = 0;
		let codFee = this.data.codFee * 1.15;
		let balance = 0;
		let lbalance = 0;
		let coupon_discount = null;
		let bundle_discount = null;
		let productsODA = 0;
		let products_gifts_total = 0;
		let products_gifts_spent = 0;
		let products_gifts_balance = 0;
		this.data.products_gifts.forEach(gift => {
			products_gifts_spent += this.applyVat(gift.product.price) * gift.quantity;
		});
		this.data.items.forEach(item => {
			// item.product.stock = item.product.stock - item.quantity;
			quantity += item.quantity;
			let tempPrice = this.getPrice(item.product, quantity);
			if (item.product.slaps && item.product.slaps.length) {
				item.product.vat = tempPrice;
			}
			subtotal += tempPrice * item.quantity;
			if (item.goldenWarranty) {
				item.goldenWarranty.forEach(warranty => {
					subtotal += warranty.price * warranty.qty;
				});
			}
			weight += item.product.weight * item.quantity;
			delivery = item.product.delivery && item.product.delivery > delivery ? item.product.delivery : delivery;
			item.bundle_discount = 0;
			item.bundle_qty = 0;
			productsODA += item.product.oda * item.quantity * 1.15;
			// add gifts limit for products gifts
			if (item.product.brand == 69 || item.product.brand == 7) {
				products_gifts_total = (this.applyVat(item.product.priceAfter) * item.quantity) * 0.1;
			}
			products_gifts_balance = products_gifts_total - products_gifts_spent;
		});
		if ((!this.data.items.length && this.data.products_gifts.length) || products_gifts_spent > products_gifts_total || products_gifts_total === 0) {
			this.removeProductsGifts();
		}
		const totals: CartTotal[] = [];

		if (productsODA > 0) { this.data.isStandardShipping = true; }
		// add oda price to shipping and check if free
		let oda = weight >= 20 || this.data.isStandardShipping ? this.data.oda * 1.15 : 0;
		shipping = subtotal > this.shippingLimit ? oda : shipping + oda;

		shipping += productsODA;

		if (this.data.collect_from_store) shipping = 0;

		totals.push({
			title: 'shippingFee',
			price: shipping,
			type: 'shipping'
		});
		// totals.push({
		//     title: 'Tax',
		//     price: (subtotal+shipping+codFee) * (15/115),
		//     type: 'tax'
		// });
		let haveCoupon = false;
		if (this.data.coupon != null) {
			if (this.data.coupon.value > 0) {
				haveCoupon = true;
			}
		}
		let haveBundle = this.data.bundles != null;
		if (codFee > 0) {
			totals.push({
				title: 'CashOnDeliveryFee',
				price: codFee,
				type: 'fee'
			});
		}
		//remove old items bundle data if exists
		this.data.items.forEach((item, index) => {
			try {
				delete this.data.items[index].discount_value;
				delete this.data.items[index].discount_type;
				delete this.data.items[index].main;
			}
			catch (e) { }
		})
		if (haveBundle) {
			this.data.bundles.items.forEach((bundle_item) => {
				const cartitem_index = this.data.items.findIndex(cartitem => bundle_item.bundled_product == cartitem.product.id);
				if (cartitem_index != -1) {
					this.data.items[cartitem_index].bundle_discount += bundle_item.discount;
					this.data.items[cartitem_index].bundle_qty += bundle_item.qty;
					this.data.items[cartitem_index].discount_value = bundle_item.discount_value;
					this.data.items[cartitem_index].discount_type = bundle_item.discount_type;
					this.data.items[cartitem_index].main = bundle_item.product;
				}
			})
			bundle_discount = -Math.abs(this.data.bundles.total_discount); //keep it in minus
			this.data.bundleDiscount = bundle_discount;
			this.bundleDiscountSubject$.next(this.data.bundleDiscount);
			if (Math.abs(bundle_discount) > 0) {
				totals.push({
					title: 'bundle_discount',
					price: bundle_discount,
					type: 'other'
				});
			}
		}
		if (haveCoupon) {
			let coupon_subtotal = 0;
			if (this.data.coupon.apply_to == 'all') {
				if (haveBundle) {
					this.data.items.forEach(item => {
						//exclude bundle products (main_product & bundled_product)
						let notInBundle = this.data.bundles.items.findIndex(
							({ product, bundled_product }) => product === item.product.id || bundled_product === item.product.id
						) == -1;
						if (notInBundle) {
							coupon_subtotal += (item.product.price * item.quantity);
						}
					});
				}
				else {
					coupon_subtotal = subtotal + bundle_discount;
				}
			}
			else {
				this.data.coupon.coupon_products.forEach(ele => {
					this.data.items.forEach(item => {
						if (ele == item.product.id) {
							if (haveBundle) {
								//exclude bundle products (main_product & bundled_product)
								let notInBundle = this.data.bundles.items.findIndex(
									({ product, bundled_product }) => product === item.product.id || bundled_product === item.product.id
								) == -1;
								if (notInBundle) {
									coupon_subtotal += (item.product.price * item.quantity);
								}
							}
							else {
								coupon_subtotal += (item.product.price * item.quantity);
							}
						}
					});
				})
			}
			if (coupon_subtotal > 0) {
				if (this.data.coupon.type == 'amount') {
					coupon_discount = this.data.coupon.value * 1.15;
				}
				else if (this.data.coupon.type == 'percentage') {
					coupon_subtotal = coupon_subtotal * 1.15; // fixing calculated coupon discount to be after vat
					coupon_discount = ((coupon_subtotal * this.data.coupon.value) / 100);
					//limit the amount of discount to max amount 
					if (coupon_discount >= this.data.coupon.max_amount) {
						coupon_discount = this.data.coupon.max_amount;
					}
				}
				//discount applied to items only not shipping or codfee
				if (coupon_discount >= coupon_subtotal) {
					coupon_discount = coupon_subtotal;
				}

				coupon_discount = -Math.abs(coupon_discount); //keep it in minus
				totals.push({
					title: 'coupon_discount',
					price: coupon_discount,
					type: 'other'
				});
			}
			else {
				this.updateCoupon(null);
				this.alert.showWarning(this.translate.instant('one_discount_only'))
			}
		}
		this.data.vat = (subtotal + shipping + codFee + coupon_discount + bundle_discount) * (15 / 115);
		let le_total = subtotal + shipping + codFee + coupon_discount + bundle_discount;
		if (this.use_wallet && this.wallet_balance > 0) {
			balance = this.wallet_balance;
			if (balance >= le_total) {
				balance = le_total;
				this.fully_from_wallet$.next(true);
			}
			else {
				this.fully_from_wallet$.next(false);
			}
			balance = -Math.abs(balance); //keep value in minus
			totals.push({
				title: 'wallet_balance',
				price: balance,
				type: 'other'
			});
		} else {
			this.fully_from_wallet$.next(false);
		}
		le_total = le_total + balance;
		// Case use fully from wallet and user choose both loyality ans wallet
		if (le_total > 0) {
			if (this.use_loyality && this.loyality_balance > 0) {
				lbalance = this.loyality_balance;
				if (lbalance >= le_total) {
					lbalance = le_total;
					this.fully_from_loyality$.next(true);
				} else {
					this.fully_from_loyality$.next(false);
				}
				lbalance = -Math.abs(lbalance); //keep value in minus
				totals.push({
					title: 'loyality_balance',
					price: lbalance,
					type: 'other'
				});
			} else {
				this.fully_from_loyality$.next(false);
			}
		}
		this.data.from_wallet = balance;
		this.data.from_loyality = lbalance;
		this.data.quantity = quantity;
		this.data.weight = weight;
		this.data.delivery = delivery;
		this.data.subtotal = subtotal;
		this.data.totals = totals;
		this.data.productsOda = productsODA;
		this.data.products_gifts_total = products_gifts_total;
		this.data.products_gifts_spent = products_gifts_spent;
		this.data.products_gifts_balance = products_gifts_balance;
		if (Math.abs(coupon_discount) > 0) {
			// let subtotal_discounted_then_vatted = (subtotal_no_vat + coupon_discount) * 1.15;
			// this.data.total = subtotal_discounted_then_vatted+shipping+codFee+balance;
			this.data.total = subtotal + shipping + codFee + balance + lbalance + coupon_discount + bundle_discount;
		}else {
			this.data.total = subtotal + shipping + codFee + balance + lbalance + bundle_discount;
		}
		this.itemsSubject$.next(this.data.items);
		this.quantitySubject$.next(this.data.quantity);
		this.weightSubject$.next(this.data.weight);
		this.deliverySubject$.next(this.data.delivery);
		this.subtotalSubject$.next(this.data.subtotal);
		this.totalsSubject$.next(this.data.totals);
		this.totalSubject$.next(this.data.total);
		this.vatSubject$.next(this.data.vat);
		this.productsOdaSubject$.next(this.data.productsOda);
		this.products_gifts_totalSubject$.next(this.data.products_gifts_total);
		this.products_gifts_spentSubject$.next(this.data.products_gifts_spent);
		this.products_gifts_balanceSubject$.next(this.data.products_gifts_balance);
		this.products_giftsSubject$.next(this.data.products_gifts);
	}
	private save() {
		var items = JSON.stringify(this.data.items);
		localStorage.setItem('cartItems', items);
		var products_gifts = JSON.stringify(this.data.products_gifts);
		localStorage.setItem('products_gifts', products_gifts);
		if (localStorage.currentUser) {
			// if (this.data.items.length > 0) {
			// 	this.saveEvent.emit(items);
			// }
			// if (products_gifts.length > 0) {
			// 	this.saveEventGift.emit(products_gifts);
			// }
			this.authService.saveCartItems({ items: items }).toPromise();
			this.authService.saveCartGiftItems({ items: products_gifts }).toPromise();
		}
		
	}
	private delete(): void {
		localStorage.removeItem('cartItems');
		localStorage.removeItem('products_gifts');
	}

	private async load() {
		let db_items = [];
		let local_items = JSON.parse(localStorage.getItem('cartItems')) ?? null;
		let local_products_gifts = JSON.parse(localStorage.getItem('products_gifts')) ?? null;
		if (localStorage.currentUser) {
			try {
				await this.getWalletBalance();
			}
			catch (err) { }
			//this never runs when getwalletbalance was in the same try and catch.. go figure 🤷‍♂️
			try {
				let data = (await this.authService.getCartItems().toPromise()).data ?? null;
				if (data) {
					db_items = JSON.parse(data);
				}
			}
			catch (err) { }
			try {
				await this.getLoyalityBalance();
			}
			catch (err) { }
		}

		if (local_products_gifts) {
			this.data.products_gifts = local_products_gifts;
		}
		if (local_items && db_items && localStorage.justLogin === '1') {
			localStorage.setItem('justLogin', '0');
			//https://stackoverflow.com/questions/48494808/merge-the-json-arrays-without-duplicate
			//if two cart items are identical then item from db will be used
			var ids = new Set(db_items.map(d => d.product.id));
			var merged = [...db_items, ...local_items.filter(d => !ids.has(d.product.id))];
			this.data.items = merged;
		}
		else if (localStorage.currentUser && db_items && db_items.length > 0) {
			this.data.items = db_items;
		}
		else if (local_items) {
			this.data.items = local_items;
		}
	}
}