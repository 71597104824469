<div class="sidebar-shop" [ngClass]="toggle ? 'sidebar-filter' : 'sidebar'">
    <div [class.sidebar-filter-wrapper]="toggle">
        <div class="widget widget-clean">
            <label>{{'filters' | translate}}</label>
            <a [routerLink]="root.shop()" class="sidebar-filter-clear">{{'clearAll' | translate}}</a>
        </div>

        <div class="widget widget-collapsible" *ngIf="categories">
            <h3 class="widget-title mb-2">
                <a data-toggle="collapse" href="#widget-1" role="button" aria-expanded="false" aria-controls="widget-1"
                    class="collapsed">
                    {{'category'|translate}}
                </a>
            </h3>

            <div class="collapse" id="widget-1">
                <div class="widget-body pt-0">
                    <div class="filter-items filter-items-count">
                        <ng-container *ngIf="params.category || categoryLinks === 'links'">
                            <div class="filter-item" *ngIf="categoryFilterParent">
                                <a href="javascript:;" (click)="onAttrClick('category', categoryFilterParent.slug)"
                                    routerLinkActive="active">
                                    <div>
                                        <i class="icon-angle-left"></i> {{ categoryFilterParent['title_'+selected] }}
                                        <!-- <span class="item-count">{{ categoryFilterParent.products }}</span> -->
                                    </div>
                                </a>
                            </div>
                            <div class="filter-item"
                                *ngIf="!categoryFilterParent && !categoryFilterRoot && categoryLinks !== 'links'">
                                <a href="javascript:;" (click)="onAttrClick('category', categoryFilterParent.slug)"
                                    routerLinkActive="active">
                                    <div>
                                        <i class="icon-angle-left"></i> {{'allCategories'|translate}}
                                        <!-- <span class="item-count">{{ categoryFilterParent.products }}</span> -->
                                    </div>
                                </a>
                            </div>
                        </ng-container>

                        <div class="filter-item" *ngFor="let item of categories">
                            <a href="javascript:;" (click)="onAttrClick('category', item.slug)"
                                routerLinkActive="active">
                                <div>
                                    {{ item['title_'+selected] }}
                                    <span class="item-count">{{ item.products }}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget widget-collapsible" *ngIf="brands">
            <h3 class="widget-title mb-2">
                <a data-toggle="collapse" href="#widget-4" role="button" aria-expanded="false" aria-controls="widget-4"
                    class="collapsed">
                    {{'Brand'|translate}}
                </a>
            </h3>

            <div class="collapse" id="widget-4">
                <div class="widget-body pt-0">
                    <div class="filter-items filter-items-count">
                        <div class="filter-item" *ngFor="let item of brands; let id = index;">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [id]="'brand-' + id"
                                    (click)="onAttrClick('brand', item.slug)"
                                    [checked]="containsAttrInUrl('brand', item.slug)">
                                <label class="custom-control-label" [for]="'brand-' + id">{{ item['title_'+selected]
                                    }}</label>
                            </div>
                            <span class="item-count">{{ item.count }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget widget-collapsible">
            <h3 class="widget-title mb-2">
                <a data-toggle="collapse" href="#widget-5" role="button" aria-expanded="false" aria-controls="widget-5"
                    class="collapsed">
                    {{'Price'|translate}}
                </a>
            </h3>

            <div class="collapse" id="widget-5">
                <div class="widget-body pt-0">
                    <div class="filter-price">
                        <div class="filter-price-text d-flex justify-content-between">
                            <span>
                                <span class="filter-price-range">
                                    <input type="text" name="min" [(ngModel)]="priceFilter.min">
                                    -
                                    <input type="text" name="max" [(ngModel)]="priceFilter.max">
                                </span>
                            </span>
                            <button class="pr-2" (click)="priceChanged()">{{'filters'|translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-body pt-0">
            <div class="filter-items filter-items-count">
                <div class="filter-item">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" (change)="ShowOutOfStock($event)" class="custom-control-input" id="showOutOfStock">
                        <label class="custom-control-label" for="showOutOfStock">{{'showOutOfStock'|translate}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>