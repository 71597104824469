import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubscribeFormData } from 'src/app/shared/interfaces/static-forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
	selector: 'subscribe-form-page',
	templateUrl: './subscribe-form.component.html',
	styleUrls: ['./subscribe-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SubscribeFormComponent implements OnInit {
	@ViewChild('myForm', { static: false }) myForm: NgForm;
	loading = false;
	data = new SubscribeFormData();
	selected: string;
	errors = new SubscribeFormData();
	constructor(
		private alert: AlertService,
		private auth: AuthService,
		private lang: LanguageService
	) { }

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.data.lang = this.selected;
	}
	submit() {
		if (this.loading) {
			return;
		}
		this.auth.subscribeFormData(this.data).subscribe((result: any) => {
			this.loading = false;
			if (result.data) {
				this.alert.showSuccess(result['msg_' + this.selected]);
				this.myForm.resetForm();
			} else {
				this.alert.showError(result['msg_' + this.selected]);
			}
		});

	}
}
