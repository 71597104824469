<div class="main">
    <molla-page-header title="{{'shippingPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'shippingPolicy'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <p>
                <b>Thank you for visiting and shopping at https://www.aptools.sa. Following are the terms and
                    conditions that constitute our Shipping Policy.

                </b>
            </p>


            <h4>DOMESTIC SHIPPING POLICY
            </h4>



            <h6>SHIPMENT PROCESSING TIME

            </h6>
            <p>
                All orders are processed in business days. Orders are not shipped or delivered on weekends or
                holidays. If we are experiencing a high volume of orders, shipments may be delayed by a few days.
                Please allow additional days in transit for delivery. If there will be a significant delay in
                shipment of your order, we will contact you via email or telephone.


            </p>


            <h6>SHIPMENT CONFIRMATION & ORDER TRACKING

            </h6>
            <p>
                You will receive a Shipment Confirmation email once your order has shipped containing your tracking
                number(s). The tracking number will be active within 24 hours.


            </p>
            <div class="card-table">
                <div class="table-responsive-sm">
                    <table border="1" width="100%" cellpadding="2">
                        <thead>
                            <tr>
                                <th><b> Shipment method </b></th>
                                <th><b> Estimated delivery time </b></th>
                                <th><b> Shipment cost
                                    </b></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td> Arab Pro Standard </td>
                                <td> 3-7 business days </td>
                                <td> SAR 25
                                </td>
                            </tr>
                            <tr>
                                <td> SMSA Express </td>
                                <td> 3-7 business days </td>
                                <td> SAR 25
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <p>*Delivery delays can occasionally occur.


            </p>


            <h6>Delivery policy

            </h6>
            <ul>
                <li>
                    Targets the minimum possible delivery period for its products. Fridays and Saturdays are not
                    included in the delivery period

                </li>
                <li>
                    In the event that the order is not received within 10 working days, you should contact customer
                    service

                </li>
                <li>
                    The principle of goodwill is adopted at the times and dates specified for the delivery of the
                    products. Arab Pro for Industrial Equipment shall assume no liability for any losses, costs,
                    damages, or expenses that directly or indirectly cause delays in the delivery of the products

                </li>
            </ul>

            <h6>SHIPPING RATES & DELIVERY ESTIMATES

            </h6>
            <p>
                Shipping policy charges for your order will be calculated and displayed at checkout.

            </p>

            <h6>CUSTOMS, DUTIES AND TAXES

            </h6>
            <p>
                Arab Pro Tools is not responsible for any customs and taxes applied to your order. All fees imposed
                during or after shipping are the responsibility of the customer (tariffs, taxes, etc.).


            </p>

        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">

            <p>
                <b>شكرا لزيارتك والتسوق في https://www.aptools.sa. فيما يلي الشروط والأحكام التي تشكل سياسة
                    الشحن الخاصة بنا.

                </b>
            </p>


            <h4>سياسة الشحن المحلي
            </h4>



            <h6>وقت معالجة الشحن

            </h6>
            <p>
                تتم معالجة جميع الطلبات في أيام العمل. لا يتم شحن الطلبات أو تسليمها في عطلات نهاية الأسبوع أو
                العطلات. إذا كنا نواجه عددًا كبيرًا من الطلبات ، فقد تتأخر الشحنات لبضعة أيام. يرجى السماح بأيام
                إضافية في العبور للتسليم. إذا كان هناك تأخير كبير في شحن طلبك ، فسوف نتصل بك عبر البريد الإلكتروني
                أو الهاتف.


            </p>


            <h6>تأكيد الشحن وتتبع الطلب

            </h6>
            <p>
                ستتلقى بريدًا إلكترونيًا لتأكيد الشحن بمجرد شحن طلبك الذي يحتوي على رقم (أرقام) التتبع الخاص بك.
                سيكون رقم التتبع نشطًا في غضون 24 ساعة.


            </p>

            <div class="card-table">
                <div class="table-responsive-sm">
                    <table border="1" width="100%" cellpadding="2">
                        <thead>
                            <tr>
                                <td><b> طريقة الشحن </b></td>
                                <td><b> وقت التسليم المقدر </b></td>
                                <td><b> تكلفة الشحن
                                    </b></td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td> شركة المحترفون العرب </td>
                                <td> 3-7 أيام عمل </td>
                                <td> 25 ريال سعودي
                                </td>
                            </tr>
                            <tr>
                                <td>سمسا اكسبريس </td>
                                <td> 3-7 أيام عمل </td>
                                <td> 25 ريال سعودي
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <p>
                *التأخير في التسليم يمكن أن يحدث في بعض الأحيان.


            </p>


            <h6>سياسة التوريد والشحن

            </h6>
            <ul>
                <li>
                    تستهدف سياسة شركة المحترفون العرب للتجهيزات الصناعية اقل فترة ممكنة لتوصيل المنتجات، ولا تدخل ايام الجمعة
                    والسبت ضمن مدة التوريد.

                </li>
                <li>
                    في حالة عدم استلام الطلب في خلال 10 ايام عمل يجب التواصل مع خدمة العملاء.

                </li>
                <li>
                    يعتمد مبدأ حسن النية في الأوقات والتواريخ المحددة لتوريد البضائع، ولا تتحمل شركة المحترفون العرب أي مسؤولية
                    عن أي خسائر أو تكاليف أو اضرار أو نفقات تتسبب بشكل مباشر أو غير مباشر في تأخر في تسليم البضائع.

                </li>
            </ul>

            <h6>أسعار الشحن وتقديرات التسليم

            </h6>
            <p>
                سيتم احتساب رسوم الشحن لطلبك وعرضها عند مرحلة الدفع.


            </p>

            <h6>الجمارك والرسوم والضرائب

            </h6>
            <p>
                Arab Pro Tools غير مسئول عن أي رسوم جمركية او الضرائب المطبقة على طلبك. يتحمل العميل مسؤولية جميع
                الرسوم المفروضة أثناء الشحن أو بعده (التعريفات والضرائب وما إلى ذلك).


            </p>
        </div>
        <div class="mb-2"></div>
    </div>
</div>