<div class="main">
    <molla-page-header title="{{'termsAndCond'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'termsAndCond'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            <h6>General terms and conditions                </h6>
            <p>
                Dear customer, once you use the website for the purpose of purchase, you will be obligated to follow the terms and conditions. Please read the list of terms and conditions carefully before using the electronic services. In the event that you do not agree (or cannot comply) to any law or condition from the list, we advise you not to use the electronic services provided by Arab Pro Tools for Industrial Equipment.


            </p>

            <h6>Terms of use:

            </h6>
            <p>
                The website services are only available for those who can legally represent themselves according to the law of the Kingdom of Saudi Arabia. Persons who are unable to legally represent themselves under the local contracts law may not use the website’s services.
                <br><br>
If you are a minor (less than 18 years old), you can use the website services under the supervision of a parent or legal guardian, provided that he agrees to adhere to the terms of use. The user shall be responsible for using the website services outside the Kingdom of Saudi Arabia, and he shall comply fully with the local laws in such region.
<br><br>
Arab Pro Tools for Industrial Equipment has the absolute right at any time to amend the terms and conditions of the website without notification or prior notice. You may access the most recent version of the terms and conditions at any time on the website. If you do not accept the amendments to the terms and conditions, you must stop using this service. In the event that you continue to use this service, you implicitly agree to adhere to the amended terms of the terms and conditions for this site.
            </p>                

            <h6>Personal account registration:

            </h6>
            <p>
                The information provided by you within the registration process shall be considered protected according to the terms of protecting privacy, and your personal information shall be part of your private record of your dealings with our services. In the event that you use our website, you will be responsible for maintaining the confidentiality of your personal account and password, in addition to preventing access to your laptop/ computer, and therefore you are responsible for all operations performed through your personal account using your password.
                <br><br>
The website shall bear no responsibility to anyone for any loss or damage that may arise because of your failure to protect your password or any other information related to your personal account. In the event of suspecting suspicious transactions in your personal account, please contact us immediately through the approved communication channels.
            </p>                

            <h6>Electronic communication

            </h6>
            <p>By using our website and online services, you implicitly agree that all electronic transactions sent to you by us are legally binding and are treated as written transactions.
                <br><br>
                We have the right to monitor, record and save any phone call, e-mail or other form of electronic communication with you for training purposes in order to verify the advice and feedback addressed to us and to improve and develop the quality of services provided to our customers.
                
                </p>
            <h6>Prices and terms of sale

            </h6>
            <ul>
                <li>
                    Arab Pro Tools Company has the right to update the products prices whenever it is necessary and this will affect the products displayed in the site, the product in the shopping cart and all unconfirmed products.

                </li>
                <li>
                    Existing prices include VAT

                </li>
            </ul>
<h6>Updating data:</h6>
            <p>
                The customer is directly responsible for updating his information, including his receiving address, email and mobile number, as soon as there is any change to such information.
            </p>    
            <h6>Imperfection or difference in order                </h6>
            <ul>
                <li>
                    In the event of a difference in the type or quantity of the order at the time of delivery, the customer must file a complaint with the customer service department of Arab Pro Tools for Industrial Equipment, specifying the difference on the delivery note or bill of lading
                </li>
                <li>
                    The request shall be submitted to Arab Pro Tools for Industrial Equipment on the same day of receiving the order, and any requests submitted after that may not be valid. Communication shall be through approved communication methods
                </li>
            </ul>
<h6>Return policy</h6>  
            
            <ul>
                <li> Products may be returned within 7 days from the date of receipt</li>
               <li> Products must have been supplied by Arab Pro Tools for Industrial Equipment directly and it must be unused, undamaged, and in its original and non-separated boxes and it will be inspected by Arab Pro Tools for Industrial Equipment</li>
                <li> Chemical products (with an expiration date) may not be returned</li>
            <li> Products purchased as part of an order including a free product sample or a promotion product may not be returned</li>
           <li>  The customer may return the entire order or only part of it</li>
            <li>Arab Pro Tools for Industrial Equipment has the right to refuse returning any order that does not comply with its internal policies</li>
              <li> In case of returning the product because of a defect in the product, defect from the manufacturer, damage during delivery of the product or because the delivered product is not right/wrong, no additional charges will be charged for shipping service or bank transfer fees. In case of other reasons for returning, ( customer need to replace the product or the customer’s desire has changed in general), shipping fees or bank transfer fees shall be imposed on the customer</li>
                <li> In case the customer refuses to receive the product from the shipping company, the amount paid shall be returned to the customer after deducting the cost of shipping to the warehouses and the bank transfer fee</li>
            </ul>
            <h6>Warranty and Repair Policies:

            </h6>
            <p>
                Arab Pro Tools for Industrial Equipment fully guarantees its “Agents in Saudi Arabia” products against defects from the manufacturer. The guarantee shall not include misuse, accidents, depreciation or non-compliance with instructions for use, sabotage or maintenance work that does not conform to the manufacturer’s recommendations or failure to perform maintenance, for different periods depending on the nature of the products sold as follows:
            </p>                

            <ul>
                <li>24 months from the date of purchase (Makita electric equipment, Fimer electrically operated welding equipment, Tyrolit electric equipment, keto electric lift units, helical air compressors, and all electrical equipment)</li>
                <li>12 months from the date of purchase (Elemax generators, Ashok Leland diesel and gasoline generators, air-powered equipment)</li>
                <li>6 months from the date of purchase: construction equipment, hydraulic equipment, manual tools, air compressors, agricultural equipment and other gas-powered equipment of all brands that are not mentioned in the above items.</li>
                <li>Arab Pro Tools Company will repair all machines under the warranty conditions free of charge inside Arab Pro Tools Service Centers.</li>
                <li>The technical report issued from Arab Pro Tools service centers will be considered to judge on the machine warranty condition however the customer can inspect the machine in third party service centers (as per Saudi specifications and standers) and Arab Pro Tools company will pay the charge only if their a mistake proved from our service centers.</li>
                <li>The products should be used only to the Application that it designed for. (Like a wood machine shouldn’t e used to cut steel)</li>
                <li>The customer must adhere to the periodic maintenance of the machine according to the manufacturer’s instructions inside Arab Pro Tools maintenance centers or other Arab Pro Tools approved centers as per Saudi specifications and standers.</li>
                <li> The Customer should use genuine (Or Approved) spare parts and accessories from Arab Pro Tools Company or by Saudi organization for specification and standers, as the warranty will be void in case of using duplicate or not approved spare parts or accessories, in addition which are threating the life of the worker.</li>
                <li>The warranty only covers the products sold by Arab Pro Tools Company and its authorized dealers and don’t cover the imported products by other companies or from other countries.</li>
                <li>The warranty will be valid only if the serial no. of the machine matches the the invoice or warranty card.</li>
                <li> The warranty will be voided if the serial no. removed or used in multiple machines.</li>
                <li> Warranty does not cover anyway (electric motors – Switches – Electric wires – Air filters and all consumables parts such as Bearing, O-rings, etc.)</li>
                <li>The warranty does not cover repair of failures resulting from:</li>
                <li> Using non-stable electric source or nonstandard electrical connections</li>
                <li> Operating the machine by generator power (unless the machine is equipped for it).</li>
                <li>Operating the machine in abnormal environmental conditions (such as very high temperatures – rain – unventilated rooms etc.)</li>
                <li> Operating the machine in the desert within the sand storm without using protections rooms.</li>
                <li> Arab Pro Tools Company may sell products purchased from the local market, as it will be a distributer for these products; these Products will be subject to the terms and conditions from their agent and cannot be covered by Arab Pro Tools warranty service without a contract with the customer.</li>
                <li> To insure maximum satisfaction of the customer he should inspect and test the machine sold to him and make sure of the availability of complete accessories before leaving the Showroom and we will be glade to explain the operation procedure to him.</li>
                <li>Customer should read the operation manual and adhere to the instruction in it.</li>

            </ul>

            <h6>Delivery policy</h6>
            <ul>
                <li>Targets the minimum possible delivery period for its products. Fridays and Saturdays are not included in the delivery period
                </li>
                <li>In the event that the order is not received within 10 working days, you should contact customer service
                </li>
                <li>The principle of goodwill is adopted at the times and dates specified for the delivery of the products. Arab Pro Tools for Industrial Equipment shall assume no liability for any losses, costs, damages, or expenses that directly or indirectly cause delays in the delivery of the products
                </li>
            </ul>
            
            <h6>Policy for mistakes in pricing and technical specifications of products:

            </h6>
            <p>In the event of a product pricing mistake or technical specifications, Arab Pro Tools for Industrial Equipment alone has the right to reject or cancel the order and the customer shall be notified in case the order is cancelled.

            </p>
            <h6>Property Rights Policy

            </h6>
            <p>This website and its content is copyright of [Arab Pro Tools for Industrial Equipment] – © [Arab Pro Tools for Industrial Equipment] [2020]. All rights reserved.
                <br><br>
                Any redistribution or reproduction of part or all of the contents in any form is prohibited.
                <br><br>
                You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            
            <h6>الشروط والأحكام العامة

            </h6>
            <p>
                عزيزي العميل بمجرد استخدامك للموقع الإلكتروني بغرض الشراء ستكون ملزماً باتباع الشروط والأحكام. الرجاء مراجعة قائمة الشروط والأحكام بدقة قبل البدء باستخدام الخدمات الإلكترونية. في حال عدم الموافقة (أو لا تستطيع الالتزام) بأي قانون أو شرط من القائمة ننصحك بعدم استخدام الخدمات الإلكترونية المقدمة من شركة المحترفون العرب للتجهيزات الصناعية.


            </p>

            <h6>شروط الاستخدام:

            </h6>
            <p>
                إن استخدام خدمات الموقع الإلكتروني متاحة فقط للأشخاص القادرين على تمثيل أنفسهم قانونياً وفقا لنظام المملكة العربية السعودية. لا يمكن للأشخاص غير القادرين على تمثيل أنفسهم قانونيا وفق نظام العقود المحلية استخدام خدمات الموقع الإلكتروني.


                <br><br>
                إن كنت قاصراً (كأن يكون عمرك أقل من 18 سنة) فبإمكانك استخدام خدمات الموقع الإلكتروني تحت إشراف أحد الوالدين أو الوصي القانوني شريطة موافقته بالالتزام ببنود شروط الاستخدام. سيكون المستخدم مسؤولاً عن استخدامه خدمات الموقع من خارج المملكة العربية السعودية وذلك بالتزامه التام بالقوانين المحلية المتبعة في تلك المنطقة.


                <br><br>
                شركة المحترفون العرب للتجهيزات الصناعية لها مطلق الحق بأي وقت بتعديل الاحكام والشروط للموقع الإلكتروني بدون إشعار أو تنبيه مسبق.


                <br><br>
                يمكنك الدخول إلى أحدث نسخة من الأحكام والشروط في أي وقت على الموقع الإلكتروني. في حالة عدم قبولك للتعديلات على للشروط والأحكام فيجب عليك التوقف عن استخدام هذه الخدمة. في حال استمرارك باستخدام هذه الخدمة فإنك توافق ضمنيا على الالتزام بالبنود المعدلة على الشروط والاحكام في استخدام هذا الموقع.


            </p>
            <h6>تسجيل الحساب الشخصي:

            </h6>
            <p>
                المعلومات الي تقدمها خلال عملية التسجيل هي بيانات محمية وفقاً لبنود حماية الخصوصية وتشكل معلوماتك الشخصية جزءا من سجلك الخاص لتعاملاتك مع خدماتنا. في حالة استخدامك لموقعنا الإلكتروني ستكون مسؤولاً عن المحافظة على سرية حسابك الشخصي وكلمة المرور بالإضافة إلى منع الوصول الى جهازك الشخصي/ جهاز الكمبيوتر وأنت بذلك تتحمل المسؤولية عن جميع العمليات التي يتم تنفيذها من خلال حسابك الشخصي باستخدام كلمة المرور.


                <br><br>
                لا يتحمل الموقع الإلكتروني أي مسؤولية تجاه أي شخص عن أي خسارة أو أضرار قد تنشأ بسبب فشلك في حماية كلمة المرور الخاصة بك أو أي معلومات أخرى متعلقة بحسابك الشخصي.


                <br><br>
                في حال الشك بوجود عمليات مشبوهة في حسابك الشخصي يرجى إخطارنا بالاتصال بنا فورا عن طريق قنوات التواصل المعتمدة


            </p>
            <h6>التواصل الالكتروني

            </h6>
            <p>
                موافقتك على استخدام الموقع وخدماتنا الإلكترونية فأنت توافق ضمنياً على أن جميع المعاملات الإلكترونية المرسلة لك من قبلنا هي ملزمة قانونيا وتعامل معاملة المعاملات الخطية.


                <br><br>
                يحق لنا مراقبة وتسجيل وحفظ أي مكالمة هاتفية أو بريد الكتروني أو أي شكل آخر من اشكال التواصل الإلكتروني معك وذلك لأغراض تدريبية بهدف التحقق من النصائح والملاحظات الموجهة إلينا ولتحسين وتطوير نوعية الخدمة المقدمة لعملائنا.


            </p>
            <h6>تحديث البيانات

            </h6>
            <p>
                يكون العميل مسؤولا بشكل مباشر بتحديث بياناته، الخاصة به ومنها عنوان الاستلام، والبريد الإلكتروني ورقم الجوال، وذلك فور حدوث أي تغيير لتلك البيانات.


                <br><br>
                سياسة التوريد


            </p>
            <h6>الاسعار وشروط البيع

            </h6>
            <p>
                تقوم الشركة من وقت لآخر بمراجعة اسعار المنتجات وتحديثها على الموقع الإلكتروني وذلك دون إشعار مسبق. ويتأثر بتغير الأسعار كلا من المنتجات على المنصه ,المنتجات بعربه التسوق والطلبيات الغير مؤكده.


                <br><br>
                الأسعار المعروضه تشمل ضريبة القيمة المضافة


            </p>
            <h6>النقص أو الاختلاف في الطلبية

            </h6>
            <p>
                –        في حال وجود اختلاف في نوع أو كمية الطلبية وقت التسليم يجب على العميل تقديم شكوى لقسم خدمة العملاء في شركة المحترفون العرب مع تحديد الفرق والاختلاف على مذكرة التسليم او بوليصة الشحن.


                <br><br>
                –        يجب رفع الطلب الى شركة المحترفون العرب بنفس يوم استلام الطلبية ولا يعتد بأي طلب بعد ذلك ويتم التواصل عن طريق طرق التواصل المعتمدة.


            </p>


            <h6>سياسة الإرجاع

            </h6>
            <ul>
                <li>
                     ارجاع البضاعة يسمح به خلال سبعة أيام من تاريخ استلام المنتج.

                </li>
                <li>
                     يجب أن تكون البضاعة قد تم توريدها من شركة المحترفون العرب مباشرة ويشترط أن تكون غير مستعملة، غير تالفة، وفي صناديقها الاصلية وغير مفرقة وسيتم الكشف عليها من قبل شركة المحترفون العرب.

                </li>
                <li>
                     المنتجات الكيميائية) التي لها تاريخ انتهاء صلاحية (لا يمكن قبول ارجاعها.

                </li>
                <li>
                    لا يمكن ارجاع البضائع التي قد تم شراؤها كجزء من طلبية تم فيها اعطاء منتج مجانا أو منتج بغرض الشرح او كعرض ترويجي.

                </li>
                <li>
                    يمكن ارجاع جزء من البضاعة أو كامل الطلبية المباعة.
                </li>
                <li>
                     من حق شركة المحترفون العرب رفض قبول ارجاع أي طلبية لا تتوافق مع سياساتها الداخلية.
                </li>
                <li>
                         في حالة إرجاع المنتج بسبب خلل في المنتج أو عيب من الشركة المصنعة أو نتيجة ضرر خلال توصيل المنتج أو بسبب توصيل المنتج غير الصحيح / الخاطئ، فلن يتم احتساب رسوم إضافية لخدمة الشحن أو رسوم التحويل البنكي أما في حالة الارجاع لأسباب أخرى، (حاجة الزبون إلى تبديل المنتج أو تغيرت رغبة الزبون بشكل عام) فسيتم احتساب رسوم الشحن و رسوم التحويل البنكي على الزبون.


                </li>
                <li>
                          في حال رفض العميل استلام المنتج من شركة الشحن يتم ارجاع المبلغ المدفوع الى العميل بعد خصم تكلفة شحن المنتج الى المستودعات ورسوم التحويل البنكي.

                </li>
            </ul>
            <h6>سياسة التوريد والشحن

            </h6>
            <ul>
                <li>
                    تستهدف سياسة شركة المحترفون العرب للتجهيزات الصناعية اقل فترة ممكنة لتوصيل المنتجات، ولا تدخل ايام الجمعة والسبت ضمن مدة التوريد.
                </li>
                <li>
                    في حالة عدم استلام الطلب في خلال 10 ايام عمل يجب التواصل مع خدمة العملاء.
                </li>
                <li>
                       يعتمد مبدأ حسن النية في الأوقات والتواريخ المحددة لتوريد البضائع، ولا تتحمل شركة المحترفون العرب أي مسؤولية عن أي خسائر أو تكاليف أو اضرار أو نفقات تتسبب بشكل مباشر أو غير مباشر في تأخر في تسليم البضائع.
                </li>
            </ul>

            <h6>سياسة اخطاء التسعير والوصف الفني للمنتج

            </h6>
            <p>
                في حالة اكتشاف خطأ في تسعير المنتج او الوصف الفني له، فانه يحق لشركة المحترفون العرب للتجهيزات الصناعية منفردة برفض الطلب او إلغائه وسيتم ابلاغ العميل في حالة إلغاء الطلب


            </p>
            <h6>سياسات الضمان والاصلاح:

            </h6>
            <p>
                تضمن شركة المحترفون العرب منتجاتها “الوكيلة لها بالسعودية”، ضمانا شاملا ضد عيوب الصناعة ولا يشمل الضمان سوء الاستخدام او الحوادث أو الإهلاك أو سوء استعمال المنتج أو عدم الالتزام بتعليمات الاستعمال أو الأعمال التخريبية أو أعمال الصيانة الغير مطابقة لتوصيات الشركة المصنعة أو الإهمال بإجراء الصيانة، وذلك لمدد مختلفة حسب طبيعة المنتجات المباعة على النحو التالي: –

            </p>
            <ul>
                <li>يتم إصلاح المعدة المستوفية لشروط وأحكام الضمان مجانا داخل مراكز الصيانة التابعة لشركة المحترفون العرب للتجهيزات الصناعية أو المعتمدة منها.</li>
                <li>يعتبر التقرير الفني الصادر من مركز صيانة شركة المحترفون العرب هو المعتمد للحكم على حالة الضمان الخاصة بالماكينة، وفي حال اعتراض العميل يتم اعتماد مؤسسة الإبراج التكنيكية التجارية كجهة فنية معتمدة لإصدار التقرير النهائي بحالة المعدة ويعتبر تقريرها نهائي ومعتمد ولا يحق للعميل الاعتراض عليه.</li>
                <li>يجب استخدام الماكينة للعمل المصمم لها، مثلا ماكينة قص خشب يجب ألا تستخدم لقص الحديد.</li>
                <li>يجب أن يلتزم العميل بالصيانة الدورية للمعدة حسب تعليمات الشركة المصنعة لها داخل مراكز الصيانة التابعة لشركة المحترفون العرب أو المعتمدة منها أو المطابقة للمواصفات المحلية حسب قوانين المملكة العربية السعودية.</li>
                <li>ضرورة الالتزام باستخدام قطع الغيار والملحقات (الإكسسوارات) الأصلية أو المعتمدة من الشركة المصنعة للمعدة أو هيئة المواصفات والمقاييس السعودية، ويعتبر الضمان لاغي في حال استخدام قطع غيار أو وإكسسوارات غير أصلية أو غير مطابقة لمواصفات الجودة السعودية والتي تهدد حياة أو عمل المستخدم.</li>
                <li>يشمل الضمان فقط المعدات المباعة من قبل شركة المحترفون العرب وموزعيها المعتمدين ولا يشمل المعدات المستوردة من قبل شركات أخرى من دول أخرى.</li>
                <li>وجود الرقم المسلسل على المعدة شرط أساسي للحصول على الضمان ولا يجوز نزع الرقم المسلسل واستخدامه لأكثر من ماكينة.</li>
                <li>للحصول على الضمان يجب أن يتطابق الرقم المسلسل على كل من المعدة والفاتورة أو بطاقة الضمان.</li>
                <li>لا يشمل الضمان القطع الاستهلاكية (مفاتيح التشغيل – الأسلاك – الرمان بلي والحلقات الجلدية –الفحمات-الفلاتر -الزيوت – الشحوم غيرها)</li>
                <li>لا يشمل الضمان الأعطال الناتجة عن إستخدام توصيلات كهربائية غير معتمدة أو تشغيل المعدة على مولدات كهربائية (إلا إذا كانت المعدة مجهزة لذلك) – تشغيل المعدة في ظروف بيئية غير طبيعية مثل (الحرارة العالية جدا – تحت الأمطار – الغرف عديمة التهوية – تشغيل الماكينة بالصحراء بين العواصف الرملية بدون غرفة حماية. الخ)</li>
                <li>قد تقوم شركة المحترفون العرب ببيع بعض المنتجات المشتراة من السوق المحلي حيث تكون موزعا لها، هذه المنتجات تكون خاضعه لشروط وضمان وكيلها المحلي ولايمكن ضمانها بشركة المحترفون العرب الا بوجود عقد مبرم مع العميل.</li>
                <li>يجيب توصيل المعدة بمصدر كهرباء مطابق لمواصفات المعدة.</li>
                <li>سعيا منا لزيادة رضاء العميل يجب على العميل فحص واختبار المعدة المباعة له والتأكد من وجود كامل ملحقاتها قبل مغادرته المعرض، ويسعدنا أن نشرح له التشغيل.</li>
                <li>يجب أن يقرأ العميل كتيب الاستعمال ويلتزم بالتعليمات الموجودة به.</li>
            </ul>

            <h6>سياسة حقوق الملكية

            </h6>
            <p>
                هذا الموقع ومحتواه يخضع لحقوق الطبع والنشر لـ [المحترفون العرب للمعدات الصناعية] -[المحترفون العرب للمعدات الصناعية] [2020]. كل الحقوق محفوظة.
                <br><br>
يحظر أي إعادة توزيع أو إعادة إنتاج جزء أو كل المحتويات بأي شكل من الأشكال.
<br><br>
لا يجوز لك، باستثناء إذن كتابي صريح، توزيع المحتوى أو استغلاله تجاريًا. ولا يجوز لك نقله أو تخزينه في أي موقع ويب آخر أو أي شكل آخر من أنظمة الاسترجاع الإلكتروني.

            </p>


                    

        </div>
        <div class="mb-2"></div>
    </div>
</div>