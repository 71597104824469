<div class="products mb-3">
    <p class="no-results" *ngIf="products.length == 0 && loaded else resultBlock">{{'emptyShop'|translate}}
    </p>

    <ng-template #resultBlock>
        <div *ngIf="type=='list' else gridBlock">
            <div *ngIf="!loaded else listBlock">
                <div class="skel-pro skel-pro-list" *ngFor="let item of fakeArray[type]"></div>
            </div>
            <ng-template #listBlock>
                <product-card-horizontal [product]="product" *ngFor="let product of products"></product-card-horizontal>
            </ng-template>
        </div>
        <ng-template #gridBlock>
            <div class="row" *ngIf="!loaded else elseBlock">
                <div [class]="'skel-pro ' + grid[type]" *ngFor="let item of fakeArray[type]"></div>
            </div>
            <ng-template #elseBlock>
                <div class="row">
                    <div [class]="grid[type]" class="justify-content-center" *ngFor="let product of products">
                        <product-card [product]="product" class="w-100"></product-card>
                        <!-- <product-card-shop [product]="product" class="w-100"></product-card-shop> -->
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </ng-template>
</div>