<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="form-box">
        <div class="form-tab nav-fill">
            <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                <li ngbNavItem>
                    <a ngbNavLink>{{'login' | translate}}</a>
                    <ng-template ngbNavContent>
                        <form *ngIf="loginView=='login'">
                            <div class="form-group">
                                <label for="singin-mobile"><i class="icon-mobile-alt"></i> {{'mobile' | translate}}</label>
                                <input type="tel" minlength="10" maxlength="10" type="text" [(ngModel)]="user.mobile" class="form-control" id="singin-mobile"
                                    name="singin-mobile" placeholder="05XXXXXXXX">
                            </div>

                            <div class="form-group">
                                <label for="singin-password"><i class="icon-unlock"></i> {{'password' | translate}}</label>
                                <input type="password" [(ngModel)]="user.password" class="form-control"
                                    id="singin-password" name="singin-password">
                            </div>

                            <div class="form-footer">
                                <button type="button" (click)="login()" class="btn btn-primary"
                                    [ngClass]="{'btn-loading': !loaded}">
                                    <span>{{'login' | translate}}</span>
                                    <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                </button>
                                <a href="javascript:;" (click)="gotoForgot()" class="forgot-link">{{'forgotQ' |
                                    translate}}</a>
                            </div>
                        </form>
                        <form *ngIf="loginView=='forgot'">
                            <div class="form-group">
                                <label for="singin-mobile">{{'mobile' | translate}}</label>
                                <input type="tel" [(ngModel)]="user.mobile" class="form-control" id="singin-mobile"
                                    name="singin-mobile" placeholder="05XXXXXXXX">
                            </div>
                            <div class="form-footer">
                                <button type="button" (click)="forgot()" class="btn btn-primary"
                                    [ngClass]="{'btn-loading': !loaded}">
                                    <span>{{'SendCode' | translate}}</span>
                                    <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                </button>
                                <a (click)="gotoLogin()" class="forgot-link">{{'login' | translate}}</a>
                            </div>
                        </form>
                        <div *ngIf="loginView=='verify'">
                            <form>
                                <div class="form-group">
                                    <label for="reset_token">{{'Enterverificationcode' | translate}}</label>
                                    <input type="text" [(ngModel)]="forgetPassword.reset_token" class="form-control" id="reset_token"
                                        name="reset_token" placeholder="{{'confirm_code' | translate}}">
                                </div>
    
                                <div class="form-footer">
                                    <button type="button" (click)="forgotVerify()" class="btn btn-primary"
                                        [ngClass]="{'btn-loading': !verifyLoaded}">
                                        <span>{{'VerifyUserMobile' | translate}}</span>
                                        <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                    </button>
                                </div>
                                <!-- <div class="form-footer">
                                    <div class="sms-msg" *ngIf="!smsShowResend">
                                        <div>{{'sms_time_left' | translate}} {{counter}}</div>
                                        <a href="https://web.whatsapp.com/send?phone=966557470030"
                                            target="_blank">{{'no_sms' | translate}}
                                            <span class="fab fa-whatsapp desktop" style="color:green;"> {{'whatsapp'
                                                |
                                                translate}}</span></a>
                                    </div>
                                </div> -->
                            </form>
                        </div>
                        <div *ngIf="loginView=='loginVerify'">
                            <form>
                                <div class="form-group">
                                    <label for="login_token">{{'Enterverificationcode' | translate}}</label>
                                    <input type="text" [(ngModel)]="login_token" class="form-control" id="login_token"
                                        name="login_token" placeholder="{{'confirm_code' | translate}}">
                                </div>
    
                                <div class="form-footer">
                                    <button type="button" (click)="loginVerify()" class="btn btn-primary"
                                        [ngClass]="{'btn-loading': !verifyLoaded}">
                                        <span>{{'VerifyUserMobile' | translate}}</span>
                                        <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                    </button>
                                </div>
                                <!-- <div class="form-footer">
                                    <div class="sms-msg" *ngIf="!smsShowResend">
                                        <div>{{'sms_time_left' | translate}} {{counter}}</div>
                                        <a href="https://web.whatsapp.com/send?phone=966557470030"
                                            target="_blank">{{'no_sms' | translate}}
                                            <span class="fab fa-whatsapp desktop" style="color:green;"> {{'whatsapp'
                                                |
                                                translate}}</span></a>
                                    </div>
                                </div> -->
                            </form>
                        </div>
                        <div *ngIf="loginView=='resetPassword'">
                            <form action="#">
                                <div class="form-group">
                                    <label for="password">{{'password' | translate}}</label>
                                    <input type="password" class="form-control" id="password" name="password"
                                        [(ngModel)]="resetPasswordObj.password">
                                </div>
                                <div class="form-group">
                                    <label for="repassword">{{'ConfirmPassword' | translate}}</label>
                                    <input type="password" class="form-control" id="repassword" name="repassword"
                                        [(ngModel)]="resetPasswordObj.repassword">
                                </div>
                                <div class="form-footer">
                                    <button type="button" (click)="resetPassword()" class="btn btn-primary"
                                        [ngClass]="{'btn-loading': !resetLoaded}">
                                        <span>{{'VerifyUserMobile' | translate}}</span>
                                        <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>{{'register'|translate}}</a>
                    <ng-template ngbNavContent>
                        <form action="#" *ngIf="registrationStep==1">
                            <div class="form-group">
                                <label for="firstname">{{'fname' | translate}}</label>
                                <input type="text" class="form-control" id="firstname" name="firstname"
                                    [(ngModel)]="user.firstname">
                            </div>
                            <div class="form-group">
                                <label for="lastname">{{'lname' | translate}}</label>
                                <input type="text" class="form-control" id="lastname" name="lastname"
                                    [(ngModel)]="user.lastname">
                            </div>
                            <div class="form-group">
                                <label for="email">{{'email' | translate}}</label>
                                <input type="email" class="form-control" id="email" name="email"
                                    [(ngModel)]="user.email">
                            </div>
                            <div class="form-group">
                                <label for="mobile">{{'mobile' | translate}}</label>
                                <input type="tel" class="form-control" id="mobile" name="mobile"
                                    [(ngModel)]="user.mobile" placeholder="05XXXXXXXX">
                            </div>
                            <div class="form-group">
                                <label for="password">{{'password' | translate}}</label>
                                <input type="password" class="form-control" id="password" name="password"
                                    [(ngModel)]="user.password">
                            </div>
                            <div class="form-group">
                                <label for="confirmPassword">{{'ConfirmPassword' | translate}}</label>
                                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                                    [(ngModel)]="user.confirmPassword">
                            </div>
                            <div class="form-footer">
                                <button type="submit" class="btn btn-primary" (click)="register()"
                                    [ngClass]="{'btn-loading': !loaded}">
                                    <span>{{'register' | translate}}</span>
                                    <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                </button>

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="register-policy"
                                        checked="checked">
                                    <label class="custom-control-label" for="register-policy">{{'agreeNewsletter' |
                                        translate}}</label>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="registrationStep==2">
                            <!-- <h4>{{'ActivationLinkSent' | translate}}</h4>
                            <p>{{'FollowLinkToActivate' | translate}}</p> -->
                                <form>
                                    <div class="form-group">
                                        <label for="register_token">{{'reg1Succ' | translate}}</label>
                                        <input type="text" [(ngModel)]="user.register_token" class="form-control" id="register_token"
                                            name="register_token" placeholder="{{'confirm_code' | translate}}">
                                    </div>
        
                                    <div class="form-footer">
                                        <button type="button" (click)="verify()" class="btn btn-primary"
                                            [ngClass]="{'btn-loading': !verifyLoaded}">
                                            <span>{{'VerifyUserMobile' | translate}}</span>
                                            <i [class]="'icon-long-arrow-'+arrowDir"></i>
                                        </button>
                                    </div>
                                    <!-- <div class="form-footer">
                                        <div class="sms-msg" *ngIf="!smsShowResend">
                                            <div>{{'sms_time_left' | translate}} {{counter}}</div>
                                            <a href="https://web.whatsapp.com/send?phone=966557470030"
                                                target="_blank">{{'no_sms' | translate}}
                                                <span class="fab fa-whatsapp desktop" style="color:green;"> {{'whatsapp'
                                                    |
                                                    translate}}</span></a>
                                        </div>
                                    </div> -->
                                </form>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>