import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

declare var $: any;

@Component({
	selector: 'molla-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobileMenuComponent implements OnInit, OnDestroy {
	items;
	private subscr: Subscription;
	selected;
	categories = [];
	otherLang;
	languages = [
		{ name: 'English', code: 'en', direction: 'ltr', image: 'language-1.png' },
		{ name: 'العربية', code: 'ar', direction: 'rtl', image: 'language-2.png' }
	];
	constructor(private router: Router, public root: RootService,
		private lang: LanguageService, private auth: AuthService
	) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.hideMobileMenu();
			}
		});
	}

	ngOnInit(): void {
		this.items = [
			{ label: 'home', url: this.root.home()},
			// { label: 'shop', url: this.root.shop()},
			{ label: 'topSelling', url: this.root.bunch('top-selling') },
			{label: 'newArrivals', url: this.root.bunch('new-arrival')},
			// {label: 'clearance', url: this.root.category('clearance')},
			{label: 'bestDeals', url: this.root.bunch('best-deals')},
			{label: 'salesDepartments', url: this.root.about()}
		];
		this.selected = this.lang.selected;
		this.auth.getCategories(0).subscribe(res => {
			this.categories = res;
		});
		this.otherLang = this.selected === 'en' ? this.languages[1] : this.languages[0];
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	submenuToggle(e) {
		const parent: HTMLElement = e.target.closest('li');
		const submenu: HTMLElement = parent.querySelector('ul');

		if (parent.classList.contains('open')) {
			$(submenu).slideUp(300, function () {
				parent.classList.remove('open');
			});
		}
		else {
			$(submenu).slideDown(300, function () {
				parent.classList.add('open');
			});
		}

		e.preventDefault();
		e.stopPropagation();
	}

	hideMobileMenu() {
		document.querySelector('body').classList.remove('mmenu-active');
		document.querySelector('html').removeAttribute('style');
	}

	changeLanguage(lng) {
		let oldLng = lng === 'en' ? 'ar' : 'en';
		let link = this.router.url.toString().split('?')[0];
		let parameters = this.router.url.toString().split('?')[1] ? '?' + this.router.url.toString().split('?')[1] : '';
		link = link === '/' ? '' : link;
		if (link.includes('/' + oldLng)) {
			this.router.navigateByUrl(link.replace('/' + oldLng, '/' + lng) + parameters);
		} else if (link.includes('/' + lng)) {
			// do nothing encodeURIComponent
		} else {
			this.router.navigateByUrl('/' + lng + link + parameters);
		}
	}
}
