<div class="main">
    <molla-page-header title="{{'loyalityProgram'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'loyalityProgram'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-3" *ngIf="selected==='en'">
        <div class="container">
            The Loyalty Points Program from the APTools store gives our customers a world of exclusive benefits that enable them to earn points with every purchase. These points can only be used during purchases on the website.
            <br>Collecting points is a very easy process. Where the customer gets points with every purchase he makes through the website
            <br>The program is subject to the terms and conditions of the Arab Professionals Company, and in the event that the account is closed permanently, it is not possible to claim the points balance or any consideration for it.
            <br>It is not possible to add any balance to the points in any way, except for complete and final purchases from the customer on the APTools store only.
            <br>
            <br>Points are calculated after each purchase, and the number of points that you will get varies according to the price of the products (example: each riyal = one point), and after collecting enough points, you can exchange them for a purchase voucher or reuse them in the purchase as follows:
            <br>
            <br>1- When exchanging it for a purchase voucher, every 100 points = 1 riyal (for example, the number of points is 10,000 points = 100 riyals)
            <br>2- When used as a balance in purchases, every 100 points = 1 riyal (for example, the number of points is 10,000 points = 100 riyals)
            <br>3- If the customer request for refund, the gained loyalty points will be deducted.
            <br>🔅 Voucher options include more than 500 popular brands in more than 25 categories such as malls, supermarkets, electronics, travel, fashion, restaurants, entertainment and other categories.
                
    </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content pb-3" *ngIf="selected==='ar'">
        <div class="container">
            برنامج نقاط الولاء من موقع المحترفون العرب  يمنح عملائنا عالم من المزايا الحصرية التي تخولهم لكسب النقاط مع كل عملية شرائية. يمكن بعدها استخدام هذه النقاط أثناء عمليات الشراء من الموقع الإلكتروني فقط.
            <br>جمع النقاط عملية في غاية السهولة . حيث يحصل العميل على نقاط  مع كل عملية شرائية يقوم بها من خلال الموقع الإلكتروني 
            <br>يخضع البرنامج لشروط و احكام شركة المحترفون العرب للمعدات الصناعية وفى حالة إغلاق الحساب نهائياً لا يمكن المطالبة برصيد النقاط أو أى مقابل لها .
            <br>لا يمكن اضافة اى رصيد للنقاط بأى طريقة ماعدا عمليات الشراء الكاملة  و النهائية من العميل على الموقع الإلكتروني فقط.
            <br>
            <br>يتم حساب النقاط بعد كل عملية شراء، وتختف عدد النقاط التي سوف تحصل عليها باختلاف سعر المنتجات (مثال: كل ريال =نقطة واحدة)، و بعد جمع النقاط الكافية يمكنك إستبدالها بقسيمة شراء او اعادة استخدامها فى الشراء كالتالى :
            <br>
            <br>1-  عند استبدالها بقسيمة شراء يتم حساب كل 100 نقطة = 1 ريال ( مثال عدد النقاط 10000 نقطة = 100 ريال )
            <br>2-  عند استخدامها كرصيد فى عمليات الشراء يتم حساب كل 100 نقطة = 1 ريال ( مثال عدد النقاط 10000 نقطة = 100 ريال ) 
            <br>3- في حال طلب العميل الاسترداد ، سيتم خصم نقاط الولاء المكتسبة
            <br>🔅تشمل خيارات القسائم الشرائية  أكثر من 500 ماركة رائجة في أكثر من ٢٥ فئة مثل المولات، والسوبرماركات، والإلكترونيات، والسفر، والموضة، والمطاعم، والترفيه وغيرها من الفئات.

        </div>
        <div class="mb-2"></div>
    </div>
</div>