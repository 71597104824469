import { Component } from '@angular/core';

@Component({
  selector: 'aptools-tabbymodal',
  templateUrl: './tabbymodal.component.html',
  styleUrls: ['./tabbymodal.component.scss']
})
export class TabbymodalComponent {
  closeModal() {
	let modal = document.querySelector('.vb-modal') as HTMLElement;
		if (modal)
			modal.click();
	}
	iframeLoaded(){
		// console.log('iframe loaded')
	}
}
