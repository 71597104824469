import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Params } from "src/app/shared/interfaces/params";
import { LanguageService } from "src/app/shared/services/language.service";
import { RootService } from "src/app/shared/services/root.service";

@Component({
  selector: "molla-shop-sidebar-one",
  templateUrl: "./shop-sidebar-one.component.html",
  styleUrls: ["./shop-sidebar-one.component.scss"],
})
export class ShopSidebarOneComponent implements OnInit, OnChanges {
  @Input() toggle = false;
  @Input() filters = [];
  @Input() categoryLinks = "params"; // 'params' or 'links'
  params: Params = new Params();
  categories = [];
  brands = [];
  priceFilter = { min: 0, max: 0 };
  selected;
  @ViewChild("priceSlider") priceSlider: any;
  categoryFilterParent;
  categoryFilterRoot;

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router,
    private lang: LanguageService,
    public root: RootService
  ) {
    activeRoute.queryParams.subscribe((params) => {
      this.params = params;
      // if (params['filter_price']) {
      // 	this.priceFilter.min = params['filter_price'].split('-',2)[0];
      // 	this.priceFilter.max = params['filter_price'].split('-',2)[1];
      // }else{
      // 	if(this.filters[1] && this.filters[1].value){
      // 		this.priceFilter.min = this.filters[1].value[0];
      // 		this.priceFilter.max = this.filters[1].value[1];
      // 	}
      // }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filters = changes.filters.currentValue;
    this.refreshFilters();
  }
  ngOnInit(): void {
    this.selected = this.lang.selected;
    this.refreshFilters();
  }

  refreshFilters() {
    this.categories = this.filters[0].items;
    this.categoryFilterParent = this.filters[0].parent ?? false;
    this.categoryFilterRoot = this.filters[0].root;
    this.brands = this.filters[2].items;
    this.priceFilter.min = this.filters[1].value[0];
    this.priceFilter.max = this.filters[1].value[1];
    // this.showOutOfStock = this.filters[4].value === 'all' ? true : false;
  }

  containsAttrInUrl(type: string, value: string) {
    const currentQueries = this.params[type]
      ? this.params[type].split(",")
      : [];
    return currentQueries && currentQueries.includes(value);
  }

  getUrlForAttrs(type: string, value: string) {
    let currentQueries = this.params[type] ? this.params[type].split(",") : [];
    currentQueries = this.containsAttrInUrl(type, value)
      ? currentQueries.filter((item) => item !== value)
      : [...currentQueries, value];
    return currentQueries.join(",");
  }

  ShowOutOfStock(e: any){
	const val = e.target.checked ? 'all':'instock';
	this.router.navigate([], { queryParams: { "stock": val  },queryParamsHandling: "merge"});
  }
  onAttrClick(attr: string, value: string) {
    if (attr === "category") {
      if (this.categoryLinks === "params") {
        this.router.navigate([], {
          queryParams: { [attr]: value },
          queryParamsHandling: "merge",
        });
      } else {
        this.router.navigate([this.root.category(value)]);
      }
    } else {
      if (this.getUrlForAttrs(attr, value)) {
        this.router.navigate([], {
          queryParams: { [attr]: this.getUrlForAttrs(attr, value), page: 1 },
          queryParamsHandling: "merge",
        });
      } else {
        this.router.navigate([], {
          queryParams: { [attr]: null },
          queryParamsHandling: "merge",
        });
      }
    }
  }

  priceChanged() {
    this.router.navigate([], {
      queryParams: {
        filter_price: this.priceFilter.min + "-" + this.priceFilter.max,
        page: 1,
      },
      queryParamsHandling: "merge",
    });
  }
}
