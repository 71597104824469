<main class="main">
    <div class="page-content">
        <a href="https://aptools.sa/bunch/national-day-offers?sort=discount&page=1">
            <img src="assets/images/nationalday/National-Day_Banner_Desktop.webp" class="mb-5 desktop"
                style="width:100%" alt="National Day">
            <img src="assets/images/nationalday/National-Day_Banner_Mobile.webp" class="mb-1 mobile" style="width:100%"
                alt="National Day">
        </a>
        <div class="container">

            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="title mb-2">{{'nationalDayText'|translate}}</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-lg-3">
                    <div class="contact-box text-center">
                        <h3>{{'Retail Stores'|translate}}</h3>
                        <div><a href="mailto:retail@arabprotools.com"><!--<i class="icon-envelope"></i>-->
                                retail@arabprotools.com</a></div>
                        <div><a href="tel:966553100062"><i class="icon-mobile-alt"></i> 0553100062</a></div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="contact-box text-center">
                        <h3>{{'Dealers'|translate}}</h3>
                        <div><a href="mailto:dealer@arabprotools.com"><!--<i class="icon-envelope"></i>-->
                                dealer@arabprotools.com</a></div>
                        <div><a href="tel:966553100062 "><i class="icon-mobile-alt"></i> 0553100062 </a></div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="contact-box text-center">
                        <h3>{{'Online Store'|translate}}</h3>
                        <div><a href="mailto:sales@aptools.sa"><!--<i class="icon-envelope"></i>--> sales@aptools.sa</a>
                        </div>
                        <div>
                            <a class="desktop" href="https://web.whatsapp.com/send?phone=966552822719"><i
                                    class="icon-whatsapp"></i> 0552822719</a>
                            <a class="mobile" href="whatsapp://send?phone=966552822719"><i class="icon-whatsapp"></i>
                                0552822719</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="contact-box text-center">
                        <h3>{{'Projects'|translate}}</h3>
                        <div><a href="mailto:projects@arabprotools.com"><!--<i class="icon-envelope"></i>-->
                                projects@arabprotools.com</a></div>
                        <div> <br> </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 mb-1">
                <div class="col-md-12">
                    <div class="social-icons justify-content-center mb-0">
                        <a class="social-icon" target="_blank" href="https://m.facebook.com/ArabProTools/">
                            <i class="icon-facebook-f"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://twitter.com/ArabProTools"><i
                                class="icon-twitter"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://www.instagram.com/arabprotools/"><i
                                class="icon-instagram"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://www.linkedin.com/company/arab-pro-tools"><i
                                class="icon-linkedin"></i>
                        </a>
                        <a class="social-icon" target="_blank" href="https://www.youtube.com/@arabprotools"><i
                                class="icon-youtube"></i>
                        </a>
                    </div>
                </div>
            </div>

            <hr class="mt-2 mb-2 mt-md-1">
            <div class="touch-container row justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div class="text-center">
                        <h2 class="title mb-1">{{'salesInquiries'|translate}}</h2>
                    </div>
                    <!-- <div class="container" *ngIf="cloading">
                        <div class="row">
                            <div class="col-12">
                                <div class="cart-empty-page text-center loading">
                                    <div class="bounce-loader">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <form action="#" class="contact-form mb-2">
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="cname" class="sr-only">{{'name'|translate}}</label>
                                <input type="text" class="form-control" id="cname" placeholder="{{'name'|translate}} *"
                                    required name="name" [(ngModel)]="contactUs.name">
                            </div>

                            <div class="col-sm-6">
                                <label for="cemail" class="sr-only">{{'email'|translate}}</label>
                                <input type="email" class="form-control" id="cemail"
                                    placeholder="{{'email'|translate}} *" required name="email"
                                    [(ngModel)]="contactUs.email">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="cphone" class="sr-only">{{'mobile'|translate}}</label>
                                <input type="tel" class="form-control" id="cphone" placeholder="{{'mobile'|translate}}"
                                    name="mobile" [(ngModel)]="contactUs.mobile">
                            </div>
                            <div class="col-sm-6">
                                <label for="dept" class="sr-only">{{'Department'|translate}}</label>
                                <select class="form-control" [(ngModel)]="contactUs.dept" id="dept" name="dept">
                                    <option [ngValue]="null" [disabled]="true">{{'chooseDepartment' | translate}}
                                    </option>
                                    <option value="sales@aptools.sa">{{'Online Store' | translate}}</option>
                                    <option value="dealer@arabprotools.com">{{'Dealers' | translate}}</option>
                                    <option value="projects@arabprotools.com">{{'Projects' | translate}}</option>
                                    <option value="retail@arabprotools.com">{{'Retail Stores' | translate}}</option>
                                </select>
                            </div>
                        </div>

                        <label for="csubject" class="sr-only">{{'subject'|translate}}</label>
                        <input type="text" class="form-control" id="csubject" placeholder="{{'subject'|translate}}"
                            name="subject" [(ngModel)]="contactUs.subject">

                        <label for="cmessage" class="sr-only">{{'message'|translate}}</label>
                        <textarea class="form-control" cols="30" rows="4" id="cmessage" required
                            placeholder="{{'message'|translate}} *" name="message"
                            [(ngModel)]="contactUs.message"></textarea>

                        <div class="text-center">
                            <button type="button" (click)="SendMessage()"
                                class="btn btn-outline-primary-2 btn-minwidth-sm">
                                <span>{{'Submit'|translate}}</span>
                                <i *ngIf="selected==='en'" class="icon-long-arrow-right"></i>
                                <i *ngIf="selected==='ar'" class="icon-long-arrow-left"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>