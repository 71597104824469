import { Component} from '@angular/core';

@Component({
  selector: 'aptools-tamaramodal',
  templateUrl: './tamaramodal.component.html',
  styleUrls: ['./tamaramodal.component.scss']
})
export class TamaramodalComponent {
	closeModal() {
		let modal = document.querySelector('.vb-modal') as HTMLElement;
		if (modal)
			modal.click();
	}
	iframeLoaded(){
		// console.log('iframe loaded')
	}
}
