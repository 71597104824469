import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-retail-page',
	templateUrl: './retail.component.html',
	styleUrls: ['./retail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class RetailPageComponent {
	selected;
	constructor(private lang : LanguageService,public root:RootService) {
		this.selected = this.lang.selected;
	}
}
