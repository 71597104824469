import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/shared/services/cart.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { LanguageService } from '../../services/language.service';
import { RootService } from '../../services/root.service';
import { User } from '../../interfaces/user';
import { ModalService } from '../../services/modal.service';


@Component({
    selector: 'app-stickymenu',
    templateUrl: './stickymenu.component.html',
    styleUrls: ['./stickymenu.component.scss']
})
export class StickymenuComponent implements OnInit {

    tabs = [];
    selected;
    user: User;
    constructor(
        public cart: CartService,
        private session: SessionService,
        private lang: LanguageService,
        private root: RootService,
        public modalService: ModalService
    ) { }

    ngOnInit(): void {
        let isUser = this.session.getUser() ? true : false;
        this.selected = this.lang.selected;
        this.tabs = [{
            'name': 'home',
            'icon': 'icon-home',
            'link': this.root.home(),
            'size': '26'
        },
        {
            'name': 'shop',
            'icon': 'icon-shopping-bag',
            'link': this.root.shop(),
            'size': '26',
        },
        {
            'name': 'categories',
            'icon': 'icon-list',
            'link': this.root.categories(),
            'size': '22',
        },
        {
            'name': isUser ? 'MyAccount' : 'login',
            'icon': isUser ? 'icon-user icon-user-green' : 'icon-user icon-user-red',
            'link': isUser ? this.root.account() : this.root.login(),
            'size': '23',
        },
        {
            'name': 'cart',
            'icon': 'icon-shopping-cart',
            'link': this.root.cart(),
            'size': '25',
            'quantity': true
        }]
    }
}
