export class WarrantyFormData {
    mobile?: string;
	invoice?: string;
    serial?: string;
    dealer?: string;
    name?: string;
    model?: string;
    purchaseDate?: string;
}
export class SubscribeFormData {
    name?: string;
    mobile?: string;
	email?: string;
	lang?: string;
}
export class ComplaintFormData {
    name?: string;
    mobile?: string;
	email?: string;
	msg?: string;
}