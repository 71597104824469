export class ContactUs{
    name:string;
    email:string;
    mobile:string;
    subject:string;
    message:string;
}
export class nationaldayForm{
    name:string;
    email:string;
    mobile:string;
    dept:string;
    subject:string;
    message:string;
}