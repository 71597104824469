import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';
import { ContactUs } from 'src/app/shared/interfaces/contact-us';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
	selector: 'pages-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactPageComponent implements OnInit {
	selected;
	contactUs: ContactUs = new ContactUs();
	loading;

	constructor(
		private lang: LanguageService,
		public root: RootService,
		private auth: AuthService,
		private alertService: AlertService
	) {}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
	SendMessage() {
		this.loading = true;
		this.auth.SendContactus(this.contactUs).subscribe((result: any) => {
			this.loading = false;
			if (result.data == true) {
				this.contactUs = {name:"",email:"",mobile:"",subject:"",message:""};
				this.alertService.showSuccess(result['msg_'+this.selected]);
				return;
			} else {
				this.alertService.showError(result['msg_'+this.selected]);
				return;
			}
		}
		,(error)=>{
			this.loading = false;
			return;
		},()=>{
			this.loading = false;
			return;
		});
	}
}