import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'shop-list-contract',
	templateUrl: './shop-list-contract.component.html',
	styleUrls: ['./shop-list-contract.component.scss']
})

export class ShopListContractComponent implements OnInit {

	@Input() type = '6cols';
	@Input() products = [];
	@Input() loaded = false;

	grid = {
		"2cols": "col-6",
		"3cols": "col-6 col-md-4 col-lg-4",
		"4cols": "col-6 col-md-4 col-lg-4 col-xl-3",
		"6cols": "col-6 col-md-4 col-lg-4 col-xl-2"
	};
	fakeArray = {
		"list": [1, 2, 3, 4, 5],
		"2cols": [1, 2, 3, 4, 5, 6],
		"3cols": [1, 2, 3, 4, 5, 6, 7, 8, 9],
		"4cols": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		"6cols": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,13,14,15,16,17,18,19,20,21,22,23,24]
	}

	constructor() {
	}

	ngOnInit(): void {
	}
}