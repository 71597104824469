import { Component, OnInit, ViewEncapsulation, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import imagesLoaded from 'imagesloaded';

import { Product } from 'src/app/shared/interfaces/product';
import { CartService } from 'src/app/shared/services/cart.service';
import { CompareService } from 'src/app/shared/services/compare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
	selector: 'molla-quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class QuickViewComponent implements OnInit {

	@Input() slug = '';
	product: Product;
	loaded = false;
	addingToCart = false;
	options: OwlOptions = {
		items: 1,
		margin: 0,
		navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
		smartSpeed: 400,
		autoplay: false,
		autoplayTimeout: 15000,
		dots: false,
		nav: false,
		loop: true,
		// onTranslate: (e: any) => this.itemChange(e, this)
	}
	variationGroup = [];
	selectableGroup = [];
	sizeArray = [];
	colorArray = [];
	selectedVariant = {
		color: null,
		colorName: null,
		price: null,
		size: "",
		disabled: false
	};
	maxPrice = 0;
	minPrice = 99999;
	paddingTop = '100%';
	currentIndex = 0;
	qty = 1;
	selected;
	saved;

	@ViewChild('singleSlider') singleSlider: any;

	constructor(
		public cartService: CartService,
		public wishlistService: WishlistService,
		public compareService: CompareService,
		public utilsService: UtilsService,
		public router: Router,
		public el: ElementRef,
		private auth: AuthService,
		private lang: LanguageService,
		private alert:AlertService,
		private translate:TranslateService
	) {
	}

	public trackByFn(index, item) {
		if (!item) return null;
		return item.id;
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.auth.getProduct({ 'slug': this.slug }).subscribe(result => {
			this.product = result;

			this.product.svat = Number(this.product.svat);
			this.product.vat = Number(this.product.vat);
			this.saved = Math.round(
				((this.product.price - this.product.sale) / this.product.price) * 100
			);

			// this.paddingTop = Math.floor((parseFloat(this.product.pictures[0].height.toString()) / parseFloat(this.product.pictures[0].width.toString()) * 1000)) / 10 + '%';
			this.paddingTop = '5%';
			let self = this;
			imagesLoaded(".quickView-modal").on("done", function () {
				self.loaded = true;
			});
		})
	}

	itemChange(e: any, self: any) {
		document.querySelector('#product-image-gallery').querySelector('.product-gallery-item.active').classList.remove('active');
		document.querySelector('#product-image-gallery').querySelectorAll('.product-gallery-item')[e.item.index].classList.add('active');

		self.currentIndex = e.item.index;
	}

	addCart(event: Event) {
		event.preventDefault();
		if ((event.currentTarget as HTMLElement).classList.contains('btn-disabled')) return;
		if (!this.addingToCart && this.product && this.qty > 0 && this.qty <= this.product.stock) {
			this.addingToCart = true;
			this.cartService.getStock(this.product, this.qty).subscribe(res => {
				this.product.stock = res.valueOf();
			});
			this.cartService.add(this.product, this.qty, []).subscribe({
				complete: () => {
					this.addingToCart = false;
				}
			});
		} else {
			this.alert.showError(this.translate.instant('qtyExceedStock'));
			this.addingToCart = false;
		}
	}

	addToWishlist(event: Event) {
		event.preventDefault();

		if (this.isInWishlist()) {
			this.router.navigate(['/shop/wishlist']);
		} else {
			this.wishlistService.addToWishList(this.product);
		}
	}

	addToCompare(event: Event) {
		event.preventDefault();
		if (this.isInCompare()) return;
		this.compareService.addToCompare(this.product);
	}

	isInCompare() {
		return this.compareService.isInCompare(this.product);
	}

	isInWishlist() {
		return this.wishlistService.isInWishlist(this.product);
	}


	onChangeQty(current: number) {
		this.qty = current;
	}


	closeQuickView() {
		let modal = document.querySelector('.quickView-modal') as HTMLElement;
		if (modal)
			modal.click();
	}

	changeImage($event: Event, i = 0) {
		this.currentIndex = i;
		this.singleSlider.to(i);
		$event.preventDefault();
	}
}