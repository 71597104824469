import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService { 
   
  constructor(@Inject(DOCUMENT) private doc) {}
  
  createCanonical(lang,url=this.doc.URL) {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.doc.head.appendChild(link);
    
     //  keep search qry in the canonical url
     let urlObj = new URL(url);
     if(urlObj.searchParams){
        let qry = urlObj.searchParams.get('qry');
        urlObj.search = '';
        if(qry){
            urlObj.searchParams.append('qry',qry);
        }
     }
     
     link.setAttribute('href', urlObj.toString());
   }
  createHreflang(path,lang) {
    var newLink1 = '';
    var newLink2 = '';
    if(path.includes("/ar/") ){ // ar
      path = path.replace('/ar/','/');
      newLink1 = environment.domain + 'ar' + path;
      newLink2 = environment.domain + 'en' + path;
    }else if(path.includes("/en/") ) {
      path = path.replaceAll('/en/','/');
      newLink1 = environment.domain + 'en' + path;
      newLink2 = environment.domain + 'ar' + path;
    }else{ //empty and en
      newLink1 = environment.domain + 'en' + path;
      newLink2 = environment.domain + 'ar' + path;
    }
     let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', 'en');
      link.setAttribute('href', newLink1);
      this.doc.head.appendChild(link);
 
      let link2: HTMLLinkElement = this.doc.createElement('link');
      link2.setAttribute('rel', 'alternate');
      link2.setAttribute('hreflang', 'ar');
      link2.setAttribute('href', newLink2);
      this.doc.head.appendChild(link2);
   }

  //  keep search qry in the canonical url
   keepParams(url){
    let urlObj = new URL(url);
     if(urlObj.searchParams){
        let qry = urlObj.searchParams.get('qry');
        urlObj.search = '';
        if(qry){
            urlObj.searchParams.append('qry',qry);
        }
     }
    return urlObj.toString();
   }
} 