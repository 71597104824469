<div class="product product-7 text-center">
    <span class="product-label label-sale" *ngIf="product.svat && product.svat < product.vat">-{{saved}}%</span>
    <!-- <span class="product-label label-new" *ngIf="product.new">New</span>
                <span class="product-label label-sale">Sale</span>
                <span class="product-label label-top" >Top</span> -->
    <!-- <span class="product-coupon" *ngIf="product.coupon && product.coupon['short_'+selected] && company_can_see_gifts">
        {{product.coupon['short_'+selected]}}
    </span> -->
    <figure class="product-media">
        <span class="product-label label-out" *ngIf="!product.stock || product.stock == 0">{{'outOfStock'|translate}}</span>
        <span class="product-label label-express" *ngIf="product.express && product.express > 0">{{'express'| translate}}</span>

        <a [href]="root.product(product.slug)">
            <molla-image [src]="product.img" alt="{{product.title_en}}" class="product-image"></molla-image>
        </a>
        <div class="product-action-vertical desktop-flex">
            <a href="javascript:;" class="btn-product-icon btn-wishlist btn-expandable"
                [class.added-to-wishlist]="isInWishlist()" (click)="addToWishlist($event)">
                <span>{{ isInWishlist() ? 'go' : 'add' }} to {{'Wishlist'|translate}}</span>
            </a>
            <a href="javascript:;" class="btn-product-icon btn-quickview" title="{{'quickview'|translate}}"
                (click)="quickView($event)"><span>{{'quickview'|translate}}</span></a>
        </div>
        <div class="product-action desktop-flex" *ngIf="product.stock && product.stock > 0">
            <button class="btn-product btn-cart" (click)="addToCart($event)">
                <span>{{'addToCart' | translate}}</span>
            </button>
        </div>
        <img class="warranty" *ngIf="product.freeGoldenWarrantyID && product.freeGoldenWarrantyID > 0"
            src="../../../../../assets/images/3_months_warranty_plus.png" alt="">
    </figure>

    <div class="product-body">
        <!-- <div class="product-cat">
            <span *ngFor="let cat of product.category; let i =index">
                <a [routerLink]="['/shop/sidebar/list']" [queryParams]="{category: cat.slug}">{{ cat.name }}</a>{{ i < product.category.length - 1 ? ',' : '' }}
            </span>
        </div> -->

        <h3 class="product-title">
            <a [href]="root.product(product.slug)">{{ product['brand_'+selected] }} {{ product['title_'+selected]
                }}</a>
        </h3>
        <div class="product-card-gift" *ngIf="product.bundle && product.bundle.id">
            <img [src]="product.bundle.img" [alt]="product.bundle['title_'+selected]">
            <div class="desc">
                <h4><i class="icon-gift-1"></i> {{'freeGift'|translate}}</h4>
                <p>{{product.bundle['title_'+selected]}}</p>
            </div>
        </div>

        <div class="product-price" *ngIf="!product.stock || product.stock == 0 else elseBlock">
            <span class="out-price"><br>{{ product.vat | currencyFormat }}</span>
        </div>

        <ng-template #elseBlock>
            <div class="product-price" *ngIf="!product.svat || product.svat >= product.vat"><br>{{ product.vat |
                currencyFormat }}<br></div>
            <div class="product-price" *ngIf="product.svat && product.svat < product.vat">
                <span class="old-price">{{ product.vat | currencyFormat }}</span><br>
                <span class="new-price">{{ product.svat | currencyFormat }}</span>
            </div>
        </ng-template>
        <div class="extra-tags">
            <!-- <div class="express" *ngIf="product.express && product.express > 0 else elseExpress">{{'express'| translate}}</div>
            <ng-template #elseExpress>
                <br>
            </ng-template> -->
            <div class="coupon" *ngIf="product.coupon && product.coupon['shorter_'+selected] && company_can_see_gifts">
                <div class="coupon-details">
                    {{product.coupon['short_'+selected]}}
                </div>
            </div>
            <!-- <span class="product-coupon" *ngIf="product.coupon && product.coupon['short_'+selected] && company_can_see_gifts">
                {{product.coupon['short_'+selected]}}
            </span> -->
            <!-- <div class="coupon" *ngIf="product.voucher">
                <div class="coupon-details">
                    <i class="icon-gift-1"></i> 
                    {{'voucher'|translate}} {{product.voucher | currencyFormat}}
                </div>
            </div> -->
        </div>
    </div>
    <div class="product-card-footer">
        <button class="btn-product btn-card-cart" title="{{'addToCart' | translate}}"
        *ngIf="product.stock && product.stock > 0"
            (click)="addToCart($event)"></button>
        <button class="btn-product btn-card-quickview" title="{{'quickview'|translate}}"
            (click)="quickView($event)"></button>
        <button class="btn-product btn-card-wishlist" title="{{'Wishlist'|translate}}"
            [class.added-to-wishlist]="isInWishlist()" (click)="addToWishlist($event)"></button>
    </div>
</div>