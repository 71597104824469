import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, BehaviorSubject } from 'rxjs';
import { wishlistSelector } from 'src/app/core/selectors/selectors';
import { AddToWishListAction, RemoveFromWishListAction } from 'src/app/core/actions/actions';
import { Product } from '../interfaces/product';
import { CartService } from './cart.service';
import { TranslateService } from '@ngx-translate/core';
import { Facebook } from '../interfaces/facebook';
import { CookieService } from './cookie.service';
import { SessionService } from './session.service';
import { AuthService } from './auth.service';

@Injectable( {
	providedIn: 'root'
} )

export class WishlistService {

	wishlist = [];
	wishlistStream: Subject<any> = new BehaviorSubject( [] );
	wishlistQty: Subject<number> = new BehaviorSubject( 0 );

	constructor ( private store: Store<any>,
		 private toastrService: ToastrService,
		 private cart:CartService,
		 private translate:TranslateService,
		 private cookie:CookieService,
		 private session:SessionService,
		 private authService:AuthService
		 ) {
		store.pipe( select( wishlistSelector ) ).subscribe( items => {
			this.wishlist = items;
			this.wishlistStream.next( items );
			this.wishlistQty.next( items.length );
		} );
	}

	// Product add to Wishlist
	addToWishList ( product ): void {
		if ( this.wishlist.findIndex( item => item.id === product.id ) === -1 ) {
			this.store.dispatch( new AddToWishListAction( { product } ) );
			this.toastrService.success( this.translate.instant('Product added to Wishlist') );
		}
				// add facebook Event Server Side
				let facebook: Facebook = {};
				facebook.fbc = this.cookie.getCookie('_fbc');
				facebook.fbp = this.cookie.getCookie('_fbp');
				facebook.user = this.session.getUser() ?? null;
				facebook.event_name = "AddToWishlist";
				facebook.product = product;
				this.authService.addFacebookEvent(facebook).subscribe(res => {
				  console.log(res);
				});
	}

	// Product removed from Wishlist
	removeFromWishList ( product ): void {
		this.store.dispatch( new RemoveFromWishListAction( { product } ) );
		this.toastrService.success( this.translate.instant('Product removed from Wishlist') );
	}

	// Product moved from Wishlist to Cart
	moveToCart ( product ): void {
		this.store.dispatch( new RemoveFromWishListAction( { product } ) );
		this.cart.add(product, 1, [], 0).subscribe({
			complete: () => {
				this.toastrService.success( this.translate.instant('productMovedtoCart') );
			}
		  });
	}

	// Check whether product is in Wishlist or not
	isInWishlist ( product: Product ): boolean {
		return this.wishlist.find( item => item.id == product.id ) ? true : false;
	}
}