import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-404-page',
	templateUrl: './404.component.html',
	styleUrls: ['./404.component.scss']
})

export class NotFoundComponent implements OnInit {
	selected;
	constructor(private lang : LanguageService,public root:RootService) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
}
