import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../shared/shared.module';

import { IndexComponent } from './index/index.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsBlockMultiComponent } from './products-block-multi/products-block-multi.component';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
	declarations: [
		IndexComponent,
		ProductsBlockMultiComponent
	],

	imports: [
		CommonModule,
		RouterModule,
		NgbModule,
		SharedModule,
		TranslateModule,
		FormsModule,
		NgbNavModule,
		CarouselModule
	],
	providers: [NgbNavModule]
})

export class HomeModule { }
