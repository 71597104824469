<div class="main shop">
    <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">{{'home'|translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{ pageTitle }}</li>
                <li class="breadcrumb-item" *ngIf="params.qry != ''">
                    <span>{{'search'|translate}} - {{ params.qry }}</span>
                </li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 skeleton-body sekl-shop-products" [class.loaded]="loaded">
                    <div class="toolbox">
                        <div class="toolbox-left">
                            <div class="toolbox-info">
                                {{'Showing'|translate}}
                                <span>{{ products.length }} {{'ofShowing'|translate}} {{ total }}</span> {{'Products' |translate}}
                            </div>
                        </div>

                        <div class="toolbox-right">
                            <div class="toolbox-sort">
                                <label for="sortby">{{'SortBy'|translate}}</label>
                                <div class="select-custom">
                                    <select name="sortby" id="sortby" class="form-control"
                                        (change)="changeOrderBy($event)" [value]="params.sort">
                                        <option value="relevance" *ngIf="params.qry">{{'Relevance' | translate}}</option>
                                        <option value="views">{{'sortPopularity' | translate}}</option>
                                        <option value="latest">{{'sortLatest' | translate}}</option>
                                        <option value="price_asc">{{'sortPriceAsc' | translate}} </option>
                                        <option value="price_desc"> {{'sortPriceDesc' | translate}}</option>
                                        <option value="discount">{{'Discount' | translate}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="toolbox-layout">
                                <a (click)="changeType('list')" queryParamsHandling="merge" class="btn-layout"
                                [class]= "{'active': type==='list'}">
                                    <svg width="16" height="10">
                                        <rect x="0" y="0" width="4" height="4" />
                                        <rect x="6" y="0" width="10" height="4" />
                                        <rect x="0" y="6" width="4" height="4" />
                                        <rect x="6" y="6" width="10" height="4" />
                                    </svg>
                                </a>

                                <a (click)="changeType('2cols')" queryParamsHandling="merge" class="btn-layout"
                                [class]= "{'active': type==='2cols'}">
                                    <svg width="10" height="10">
                                        <rect x="0" y="0" width="4" height="4" />
                                        <rect x="6" y="0" width="4" height="4" />
                                        <rect x="0" y="6" width="4" height="4" />
                                        <rect x="6" y="6" width="4" height="4" />
                                    </svg>
                                </a>

                                <a (click)="changeType('3cols')" queryParamsHandling="merge" class="btn-layout"
                                [class]= "{'active': type==='3cols'}">
                                    <svg width="16" height="10">
                                        <rect x="0" y="0" width="4" height="4" />
                                        <rect x="6" y="0" width="4" height="4" />
                                        <rect x="12" y="0" width="4" height="4" />
                                        <rect x="0" y="6" width="4" height="4" />
                                        <rect x="6" y="6" width="4" height="4" />
                                        <rect x="12" y="6" width="4" height="4" />
                                    </svg>
                                </a>

                                <a (click)="changeType('4cols')" queryParamsHandling="merge" class="btn-layout"
                                [class]= "{'active': type==='4cols'}">
                                    <svg width="22" height="10">
                                        <rect x="0" y="0" width="4" height="4" />
                                        <rect x="6" y="0" width="4" height="4" />
                                        <rect x="12" y="0" width="4" height="4" />
                                        <rect x="18" y="0" width="4" height="4" />
                                        <rect x="0" y="6" width="4" height="4" />
                                        <rect x="6" y="6" width="4" height="4" />
                                        <rect x="12" y="6" width="4" height="4" />
                                        <rect x="18" y="6" width="4" height="4" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <molla-shop-list-one [type]="type" [products]="products" [loaded]="loaded">
                    </molla-shop-list-one>

                    <molla-pagination [perPage]="limit" [total]="total" *ngIf="total > limit">
                    </molla-pagination>
                </div>

                <aside class="col-lg-3 skel-shop-sidebar order-lg-first skeleton-body" [class.loaded]="firstLoad">
                    <div *ngIf="!firstLoad else sidebarLoaded">
                        <div class="skel-widget"></div>
                        <div class="skel-widget"></div>
                        <div class="skel-widget"></div>
                        <div class="skel-widget"></div>
                    </div>
                    <ng-template #sidebarLoaded>
                        <molla-shop-sidebar-one [toggle]="toggle" [filters]="filters">
                        </molla-shop-sidebar-one>

                        <button class="sidebar-fixed-toggler" (click)="toggleSidebar()" *ngIf="toggle">
                            <i class="icon-cog"></i>
                        </button>

                        <div class="sidebar-filter-overlay" (click)="hideSidebar()"></div>
                    </ng-template>
                </aside>
            </div>
        </div>
    </div>
</div>