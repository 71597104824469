import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { 
	// Subscription,
	 timer } from 'rxjs';
import { referrerEvent } from 'src/app/shared/interfaces/referrer-event';
import { ChangePassword, ForgetPassword, ResetPasswordLogin, User } from 'src/app/shared/interfaces/user';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
	selector: 'molla-login-modal',
	templateUrl: './login-modal.component.html',
	styleUrls: ['./login-modal.component.scss']
})

export class LoginModalComponent implements OnInit {
	registrationStep = 1;
	loginStep = 1;
	loginView = 'login';
	// counter: number;
	// countDown: Subscription;
	showResend: boolean = false;

	loaded = true;
	user: User = new User();
	selected;
	arrowDir = 'right';


	smsLoading = false;
	smsShowResend = true;
	counter = 0;
	countDown;
	verifyLoaded = true;
	resetLoaded = true;
	forgetPassword:ForgetPassword = new ForgetPassword();
	changePassword: ChangePassword = new ChangePassword();
	resetPasswordObj: ResetPasswordLogin = new ResetPasswordLogin();
	login_token;
	constructor(
		private alert: AlertService,
		private translate: TranslateService,
		private lang: LanguageService,
		private userService: UserService,
		private sessionService: SessionService,
		private auth: AuthService
	) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
		this.arrowDir = this.selected === 'ar' ? 'left' : 'right';

	}
	login() {
		if (!this.loaded) {
			return;
		}
		this.loaded = false;
		if (!this.user.mobile || !this.user.password) {
			return this.alert.showError(this.translate.instant('emptyLogin'));
		}
		this.user.lang = this.selected;
		this.userService.Login(this.user).subscribe((result: any) => {
			this.loaded = true;
			if (result.token) {
				this.alert.showSuccess(result.msg_en);
				this.sessionService.setUser(result.user, result.token, result.orders, result.company);
				
				this.sessionService.getUserRedirect('modal');
				//login succeeded
				//  return  this.router.navigate(["/"]);
			} else if (result.need_verify != null) {
				this.loginView = 'loginVerify';
			} else if (result.data != null) {
				this.alert.showError(result.msg_en);
			} else {
				this.alert.showError(result.msg_en);
			}
		});
	}
	register() {
		if (!this.loaded) {
			return;
		}
		this.loaded = false;
		this.userService.Register(this.user).toPromise().then((result: any) => {
			this.loaded = true;
			if (result.data == null) {
				this.alert.showError(result['msg_' + this.selected]);
			}
			else if (result.data != null) {
				this.registrationStep = 2;
				this.alert.showSuccess(this.translate.instant('reg1Succ'));
				// this.StartCounter();
				if (localStorage.referrers_events) {
					let ev: referrerEvent = JSON.parse(localStorage.referrers_events);
					ev.event_name = 'register';
					ev.event_id = 0;
					this.auth.addReferrerEvent(ev).subscribe(res => { });
				}
			}
			else {
				this.alert.showError(result['msg_' + this.selected]);
			}
		});
	}
	verify(){
		this.verifyLoaded = false;
		this.userService.RegisterVerify({register_token:this.user.register_token,mobile:this.user.mobile}).toPromise().then((res:any)=>{
			this.verifyLoaded = true;
			console.log(res);
			if(res.data != null) {
				if(localStorage.referrers_events){
                    let ev:referrerEvent = JSON.parse(localStorage.referrers_events);
                    ev.event_name = 'user';
                    ev.event_id = res.user.id;
                    this.auth.addReferrerEvent(ev).subscribe(res=>{});
                }
				this.sessionService.setToken(res.token);
				this.sessionService.setUser(res.user, res.token, res.orders, res.company);
				this.alert.showSuccess(res['msg_'+this.selected]);
				this.sessionService.getUserRedirect('modal');
			}else{
				this.alert.showError(res['msg_'+this.selected]);
			}
			//login using res.token
			//done
		});
	}
	// StartCounter() {
	// 	this.counter = 10;
	// 	this.countDown = timer(0, 1000).subscribe((x) => {
	// 		if (this.counter > 0) {
	// 			this.counter--;
	// 			this.showResend = false;
	// 			//show resend =false
	// 		}
	// 		else {
	// 			//show resend=true;
	// 			this.showResend = true;
	// 		}
	// 	})
	// }
	StartCounter() {
		this.smsShowResend = false;
		this.counter = 120;
		this.countDown = timer(0, 1000).subscribe((x) => {
		  if (this.counter > 1) {
			this.counter--;
			this.smsShowResend = false;
		  } else {
			this.smsShowResend = true;
		  }
		});
	  }

	gotoForgot() {
		this.loginView = 'forgot';
	}
	gotoLogin() {
		this.loginView = 'login';
	}
	forgot() {
		if (!this.loaded) {
			return;
		}
		this.loaded = false;
		if (!this.user.mobile) {
			return this.alert.showError(this.translate.instant('wrongInfo'));
		}
		this.userService.Forgot(this.user).subscribe((result: any) => {
			if (result.data) {
				this.loginView = 'verify';
				this.alert.showSuccess(result.msg_en);
			}
			else {
				this.alert.showError(result.msg_en);

			}
		});
	}
	loginVerify(){
		this.verifyLoaded = false;
		this.forgetPassword.mobile = this.user.mobile;
		this.userService.verifyLogin({mobile:this.user.mobile,reset_code:this.login_token}).toPromise().then((result:any)=>{
			this.verifyLoaded = true;
			console.log(result);
				if (result.token) {
					this.alert.showSuccess(result['msg_'+this.selected]);
					this.sessionService.setUser(result.user, result.token, result.orders, result.company);
					this.sessionService.getUserRedirect('modal');
				} else if (result.data != null) {
					this.alert.showSuccess(result.msg_en);
				} else {
					this.alert.showError(result.msg_en);
				}
		});
	}
	forgotVerify(){
		this.verifyLoaded = false;
		this.forgetPassword.mobile = this.user.mobile;
		this.userService.verify(this.forgetPassword).toPromise().then((res:any)=>{
			this.verifyLoaded = true;
			console.log(res);
			if(res.data != null) {
				this.loginView = 'resetPassword';
			}else{
				this.alert.showError(res['msg_'+this.selected]);
			}
		});
	}
	resetPassword(){
		this.resetPasswordObj.mobile = this.user.mobile;
		if (this.resetPasswordObj.repassword != this.resetPasswordObj.password) {
			this.alert.showError(this.translate.instant('passDontMatch'));
			return;
		}
		this.userService.resetPasswordLogin(this.resetPasswordObj).subscribe((result: any) => {
			console.log(result);
			if (result.data == null) {
				this.alert.showError(result['msg_'+this.selected]);
			}
			if (result.data == true) {
				if (result.token) {
					this.alert.showSuccess(result['msg_'+this.selected]);
					this.sessionService.setUser(result.user, result.token, result.orders, result.company);
					this.sessionService.getUserRedirect('page');
				} else if (result.data != null) {
					this.alert.showSuccess(result.msg_en);
				} else {
					this.alert.showError(result.msg_en);
				}
			}
		});
	}
	closeModal() {
		let modal = document.querySelector('.login-modal') as HTMLElement;
		if (modal)
			modal.click();
	}
}
