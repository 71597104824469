<main class="main">
    <div class="page-header text-center " [style.background-image]="'url(' + image + ')'">
        <div class="container">
            <img src="assets/images/apt_logo.svg" alt="Arab Pro Tools" style="margin: 0px auto 18px;height:44px">
            <h1 class="page-title">Golden Warranty Registration<span>تسجيل الضمان الذهبي</span></h1>
        </div>
    </div>
    <div class="login-page  pt-4 pb-8 pt-md-4 pb-md-12 pt-lg-4 pb-lg-17">
        <div class="container">
            <div class="form-box">
                <form action="#" name="myForm" #myForm="ngForm">
                    <label for="name" class="left">Name *</label>
                    <label for="name" class="right">الاسم *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.name" name="name"
                        [ngClass]="{'error': errors.name}">
                    <small class="form-text form-error" *ngIf="errors.name">{{errors.name|translate}}</small>

                    <label for="name" class="left">Mobile *</label>
                    <label for="name" class="right">رقم الجوال *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.mobile" name="mobile"
                        [ngClass]="{'error': errors.mobile}">
                    <small class="form-text form-error" *ngIf="errors.mobile">{{errors.mobile|translate}}</small>

                    <label for="name" class="left">Model Number *</label>
                    <label for="name" class="right">رقم الموديل *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.model" name="model"
                        [ngClass]="{'error': errors.model}">
                    <small class="form-text form-error" *ngIf="errors.model">{{errors.model|translate}}</small>

                    <label for="name" class="left">Purchase Date *</label>
                    <label for="name" class="right">تاريخ الشراء *</label>
                    <input type="date" class="form-control" [(ngModel)]="data.purchaseDate" name="purchaseDate"
                        [ngClass]="{'error': errors.purchaseDate}">
                    <small class="form-text form-error"
                        *ngIf="errors.purchaseDate">{{errors.purchaseDate|translate}}</small>

                    <label for="name" class="left">Dealer Name / Branch name *</label>
                    <label for="name" class="right">اسم التاجر / الفرع *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.dealer" name="dealer"
                        [ngClass]="{'error': errors.dealer}">
                    <small class="form-text form-error" *ngIf="errors.dealer">{{errors.dealer|translate}}</small>

                    <label for="name" class="left">Serial Number *</label>
                    <label for="name" class="right">الرقم التسلسلي *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.serial" name="serial"
                        [ngClass]="{'error': errors.serial}">
                    <small class="form-text form-error" *ngIf="errors.serial">{{errors.serial|translate}}</small>

                    <label for="name" class="left">Invoice Number *</label>
                    <label for="name" class="right">رقم الفاتورة *</label>
                    <input type="text" class="form-control" [(ngModel)]="data.invoice" name="invoice"
                        [ngClass]="{'error': errors.invoice}">
                    <small class="form-text form-error" *ngIf="errors.invoice">{{errors.invoice|translate}}</small>

                    <button align="center" type="submit" class="btn btn-outline-primary-2" (click)="submit()">
                        <span>submit - تسجيل</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</main>