import { Injectable } from "@angular/core";
import { NewsLetter } from "../interfaces/news-letter";
import { ChangePassword, ForgetPassword, ResetPasswordLogin, User } from "../interfaces/user";
import { ChangeMobileVerification, ConfirmMobile, EmailVerification, ResetPassword, UserVerification } from "../interfaces/user-verification";
import { HttpService } from "./http.service";

@Injectable()
export class UserService{
    constructor(private httpService:HttpService){
		
    }
    Register(user:User)
    {
        return this.httpService.post("register",user)
    }
    getProfile(){
        return this.httpService.post("protected/getProfile",null,true);
    }
    updateProfile(user:User)
    {
        return this.httpService.post("protected/updateProfile",user,true)
    }
    updateMobile(user:User)
    {
        return this.httpService.post("protected/updateMobile",user,true)
    }
    Login(user:User)
    {
        return this.httpService.post("login",user)
    }
    updateUserData(id:any)
    {
        return this.httpService.post("updateUserData",id)
    }
    subscribe(email:NewsLetter)
    {
        return this.httpService.post("subscribe",email)
    }
    Forgot(user:User)
    {
        return this.httpService.post("forgot",user)
    }
    verify(forgetPassword:ForgetPassword)
    {
        return this.httpService.post("verify",forgetPassword)
    }
    verifyLogin(forgetPassword:any)
    {
        return this.httpService.post("verifyLogin",forgetPassword)
    }
    resetPasswordLogin(data:ResetPasswordLogin)
    {
        return this.httpService.post("resetPassword",data);
    }
    ChangePassword(user:ChangePassword)
    {
        return this.httpService.post("protected/changePassword",user,true);
    }
    EmailRegisterVerify(userVerification:EmailVerification)
    {
        return this.httpService.post("registerVerify",userVerification)
    }
    ResetPassword(reset_code:ResetPassword)
    {
        return this.httpService.post("reset", reset_code)
    }
    ValidateResetCode(reset_code)
    {
        return this.httpService.post("reset_validate", reset_code)
    }
    RegisterVerify(userVerification: UserVerification)
    {
        return this.httpService.post("registerVerify",userVerification)
    }
    ConfirmMobile(confirmMobile:ConfirmMobile)
    {
        return this.httpService.post("emptyPasswordVerify",confirmMobile)
    }
    ChangeMobileVerify(changeMobileVerification:ChangeMobileVerification)
    {
        return this.httpService.post("protected/verifyMobileChange",changeMobileVerification,true)
    }
    updateAddress(user:User)
    {
        return this.httpService.post("protected/updateAddress",user,true)
    }
}