import { Component, Input, OnInit } from "@angular/core";
import { LanguageService } from "src/app/shared/services/language.service";
import { RootService } from "src/app/shared/services/root.service";

@Component({
  selector: "category-menu-item",
  templateUrl: "./category-menu-item.component.html",
  styleUrls: ["./category-menu-item.component.scss"],
})
export class CategoryMenuItem implements OnInit{
  @Input() item;
  selected: string;
  constructor(
    private lang: LanguageService,
    public root: RootService
  ) {}
  ngOnInit(): void {
    this.selected = this.lang.selected;
  }

}
