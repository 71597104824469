<div class="loading-overlay" *ngIf="!loaded">
    <div class="bounce-loader">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>
<div class="page-wrapper" *ngIf="loaded">
    <molla-header [containerClass]="containerClass"></molla-header>

    <div>
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <molla-footer [containerClass]="containerClass" [isBottomSticky]="isBottomSticky"></molla-footer>
</div>