import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-follow-and-win',
	templateUrl: './follow-and-win.component.html',
	styleUrls: ['./follow-and-win.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class FollowAndWinPageComponent implements OnInit {
	selected;
	loading;
	constructor(
		private lang: LanguageService,
		public root: RootService
	) {}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
}