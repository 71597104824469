import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
	selector: 'pages-shipping-policy-page',
	templateUrl: './shipping-policy.component.html',
	styleUrls: ['./shipping-policy.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShippingPolicyPageComponent implements OnInit {
	selected;
	constructor(public sanitizer: DomSanitizer,private lang : LanguageService,public root:RootService) {
	}

	ngOnInit(): void {
		this.selected = this.lang.selected;
	}
}
