<nav>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage < 2">
            <a class="page-link page-link-prev" [routerLink]="[]" [queryParams]="{page: currentPage - 1}"
                queryParamsHandling="merge">
                <span aria-hidden="true">
                    <i class="icon-long-arrow-left" *ngIf="selected==='en'"></i>
                    <i class="icon-long-arrow-right" *ngIf="selected==='ar'"></i>
                </span>{{'prev'|translate}}
            </a>
        </li>

        <li class="page-item" *ngFor="let page of pagesToBeShown" [class.active]="currentPage == page">
            <a class="page-link" [routerLink]="[]" [queryParams]="{page: page}" queryParamsHandling="merge">{{ page
                }}</a>
        </li>

        <li class="page-item-total pl-3" *ngIf="lastPage > 3">{{'ofShowing'|translate}} {{ lastPage }}</li>

        <li class="page-item" [class.disabled]="currentPage === lastPage">
            <a class="page-link page-link-next" [routerLink]="[]" [queryParams]="{page: (currentPage + 1)}"
                queryParamsHandling="merge">
                {{'next'|translate}}
                <span aria-hidden="true">
                    <i class="icon-long-arrow-right" *ngIf="selected==='en'"></i>
                    <i class="icon-long-arrow-left" *ngIf="selected==='ar'"></i>
                </span>
            </a>
        </li>
    </ul>
</nav>