import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Params } from 'src/app/shared/interfaces/params';

import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'brand-page',
	templateUrl: './brand.component.html',
	styleUrls: ['./brand.component.scss'],
})

export class BrandComponent implements OnInit {
	products = [];
	pageTitle = '';
	toggle = false;
	loaded = false;
	firstLoad = false;
	params:Params = new Params();
	total:number;
	limit:number;
	type='4cols';
	filters = [];
	selected;

	constructor(
		public activeRoute: ActivatedRoute,
		public router: Router, 
		public utilsService: UtilsService, 
		public auth: AuthService,
		private meta: Meta,
        private title: Title,
		private lang: LanguageService,
		private translate:TranslateService
		) {
			this.selected = this.lang.selected;
			this.activeRoute.params.subscribe(p => {
				this.params.brand = p.slug;
				// SEO
				this.auth.getBrandBySlug({'slug':p.slug}).subscribe(res=>{
					this.pageTitle = res['brand']['title_'+this.selected];
					let store = this.translate.instant('appName');
					const seoTitle = res['seo']['title_' + this.selected];
					const seoDesc = res['seo']['desc_' + this.selected];

					this.title.setTitle(store + ' | ' + seoTitle);
					this.meta.updateTag({ name: 'description', content: seoDesc });
					this.meta.updateTag({ name: 'og:title', content: seoTitle});
					this.meta.updateTag({ name: 'og:description', content: seoDesc });
					this.meta.updateTag({ name: 'twitter:title', content: seoTitle});
					this.meta.updateTag({ name: 'twitter:description', content: seoDesc });
					this.meta.updateTag({ name: 'og:url', content: environment.domain + 'brand/' +res.brand.slug });
					if(res.brand.img){
						this.meta.updateTag({ name: 'og:image', content: 'https://admin.aptools.sa/uploads/images/brands/' +res.brand.img });
						this.meta.updateTag({ name: 'twitter:image', content: 'https://admin.aptools.sa/uploads/images/brands/' +res.brand.img });
					}
				});
				
				this.activeRoute.queryParams.subscribe(parameters => {
					if (parameters['sort']) {
						this.params.sort = parameters['sort'];
					} else {
						this.params.sort = 'views';
					}
					if (parameters['type']) {
						this.type = parameters['type'];
					}
					if (parameters['category']) {
						this.params.slug = parameters['category'];
					}
					if (parameters['brand']) {
						this.params.filter_brand = parameters['brand'];
					}else{
						this.params.filter_brand = null;
					}
					if (parameters['filter_price']) {
						this.params.filter_price = parameters['filter_price'];
					}else{
						this.params.filter_price = null;
					}
					if (parameters['page']) {
						this.params.page = parameters['page'];
					}
					if (parameters['stock']) {
						this.params.filter_stock = parameters['stock'];
					}
					this.loaded = false;
					console.log(this.params);
					this.auth.getBrand(this.params).subscribe(result => {
						this.products = result.items;
						this.limit = result.limit;
						this.total = result.total;
						this.filters = result.filters;
						this.loaded = true;
						if (!this.firstLoad) {
							this.firstLoad = true;
						}
						this.utilsService.scrollToPageContent();
					})
				})
			});
	}

	ngOnInit(): void {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}
	changeType(type){
		this.type = type;
	}
	@HostListener('window: resize', ['$event'])
	onResize(event: Event) {
		if (window.innerWidth > 991) this.toggle = false;
		else this.toggle = true;
	}

	changeOrderBy(event: any) {
		this.router.navigate([], { queryParams: { sort: event.currentTarget.value, page: 1 }, queryParamsHandling: 'merge' });
	}

	toggleSidebar() {
		if (document.querySelector('body').classList.contains('sidebar-filter-active'))
			document.querySelector('body').classList.remove('sidebar-filter-active');
		else
			document.querySelector('body').classList.add('sidebar-filter-active');
	}

	hideSidebar() {
		document.querySelector('body').classList.remove('sidebar-filter-active');
	}
}