<div *ngIf="!checkinterent" class="top-warning top-warning-error">
    <p *ngIf="lang==='en'" >No Internet Connection</p>
    <p *ngIf="lang==='ar'"  >لا يوجد اتصال بالانترنت</p>
</div>
<div *ngIf="newUpdate" class="top-warning top-warning-reload">
   <p>{{'newVersion' | translate}} &nbsp;&nbsp;<button (click)="reloadPage()" class="btn-warning"> {{'reloadWebsite' | translate}} </button></p> 
</div>
<!-- <app-alert *ngIf="newUpdate" class="mb-3" type="warning" [dismissible]="true" (close)="newUpdate = false">
    
</app-alert> -->

<router-outlet></router-outlet>

<div class="mobile-menu-overlay" (click)="hideMobileMenu()"></div>

<molla-mobile-menu></molla-mobile-menu>

<button id="scroll-top" title="Back to Top" [ngClass]="{show: utilsService.isSticky}"
    (click)="utilsService.scrollTop($event)"><i class="icon-arrow-up"></i></button>