<div class="mobile-menu-container mobile-menu-light">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" (click)="hideMobileMenu()"><i class="icon-close"></i></span>
        <ul ngbNav #nav="ngbNav" class="nav nav-pills-mobile nav-border-anim">
            <li ngbNavItem>
                <a ngbNavLink>{{'Menu'|translate}}</a>
                <ng-template ngbNavContent>
                    <nav class="mobile-nav">
                        <ul class="mobile-menu">
                            <li *ngFor="let item of items">
                                <a [href]="item.url">{{item.label | translate}}</a>
                            </li>
                            <li>
                                <a href="javascript:;" [class]="otherLang.code"
                                    (click)="changeLanguage(otherLang.code)">
                                    <img [src]="'assets/images/'+otherLang.image">
                                    {{ otherLang.name }}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </ng-template>
            </li>

            <li ngbNavItem>
                <a ngbNavLink>{{'category'|translate}}</a>
                <ng-template ngbNavContent>
                    <div class="mobile-nav">
                        <ul class="mobile-menu">
                            <li *ngFor="let category of categories">
                                <category-menu-mobile-item [item]="category"></category-menu-mobile-item>
                                <!-- <a [href]="root.category(category.slug)">{{category['title_'+selected]}}</a> -->
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <div class="social-icons">
            <a href="https://m.facebook.com/ArabProTools/" class="social-icon" title="Facebook" target="_blank"><i
                    class="icon-facebook-f"></i></a>
            <a href="https://twitter.com/ArabProTools" target="_blank" class="social-icon" title="Twitter"><i
                    class="icon-twitter"></i></a>
            <a href="https://www.instagram.com/arabprotools/" target="_blank" class="social-icon" title="Instagram"><i
                    class="icon-instagram"></i></a>
            <a href="https://www.youtube.com/@arabprotools" target="_blank" class="social-icon" title="Youtube"><i
                    class="icon-youtube"></i></a>
        </div>
    </div>
</div>